import {
  billingApi,
  companyApi,
  companySettingsApi,
  employeeApi,
  leaveSettingsApi,
  scheduleSettingsApi,
  summariesApi,
} from '@/api';
import { GetParameterType } from '@/lib/realtime/realtimeTypes';
import { SocketService } from '@/plugins/Websockets';
import {
  BaseAPI,
  BillingDetails,
  BillingDetailsFromJSON,
  BillingSubscription,
  BillingSubscriptionFromJSON,
  CompanyOnboarding,
  CompanyOnboardingFromJSON,
  CompanySetting,
  CompanySettingFromJSON,
  DaySummary,
  EmployeeOnboarding,
  EmployeeOnboardingFromJSON,
  FeaturesEnabled,
  FeaturesEnabledFromJSON,
  LeaveSettings,
  LeaveSettingsFromJSON,
  ListEventEntitiesResponseDataEnum,
  ScheduleOverviewSummary,
  ScheduleOverviewSummaryFromJSON,
  ScheduleSettings,
  ScheduleSettingsFromJSON,
  ShowScheduleOverviewRequest,
} from '../../../api/v1';

export const Entity = ListEventEntitiesResponseDataEnum;

export type SingleEntityInterfaceMap = {
  [Entity.BillingDetail]: BillingDetails;
  [Entity.BillingSubscription]: BillingSubscription;
  [Entity.CompanySetting]: CompanySetting;
  [Entity.DaySummary]: DaySummary;
  [Entity.LeaveSetting]: LeaveSettings;
  [Entity.CompanyOnboarding]: CompanyOnboarding;
  [Entity.EmployeeOnboarding]: EmployeeOnboarding;
  [Entity.FeaturesEnabled]: FeaturesEnabled;
  [Entity.ScheduleSetting]: ScheduleSettings;
  [Entity.ScheduleOverviewSummary]: ScheduleOverviewSummary;
};

export type SingleEntityRequestMap = {
  [Entity.ScheduleOverviewSummary]: ShowScheduleOverviewRequest;
};

export type SingleEntityName = string & keyof SingleEntityInterfaceMap;

export type GetSingleEntityParameterType<T> =
  T extends keyof SingleEntityRequestMap & SingleEntityName
    ? SingleEntityRequestMap[T]
    : never;

const showActionFetcher =
  (api: BaseAPI, showFunction, _with?: string[]) =>
  async <T extends SingleEntityName>(params: GetParameterType<T>) =>
    (
      await showFunction.bind(api)({
        ...params,
        ...(_with && { _with }),
      })
    ).data;

const genericHandler =
  (entityMaker: Function) =>
  (
    socketService: SocketService,
    entityName: string,
    eventName: string,
    data: object,
  ) => {
    // After deleting an entity if the websocket message order isn't correct we get the entity id on an 'updated' event
    // E.g. deleting an employee
    if (
      typeof data === 'object' &&
      (eventName.endsWith('Created') || eventName.endsWith('Updated'))
    ) {
      const entity = entityMaker(data);
      socketService.store.commit('entities/UPSERT', { entityName, entity });
    } else if (eventName.endsWith('Deleted')) {
      socketService.store.commit('entities/REMOVE', { entityName, id: data });
    }
  };

/**
 * Rather than put all the entity-specific API-fetching code in the main entities store, we add it here in a
 * structured format.
 */
export const realtimeEntities: {
  [key: string]: { fetcher; handler; deleter? };
} = {
  [Entity.BillingDetail]: {
    fetcher: showActionFetcher(billingApi, billingApi.showBillingDetails),
    handler: genericHandler(BillingDetailsFromJSON),
  },
  [Entity.BillingSubscription]: {
    fetcher: showActionFetcher(billingApi, billingApi.showCurrentSubscription),
    handler: genericHandler(BillingSubscriptionFromJSON),
  },
  [Entity.CompanySetting]: {
    fetcher: showActionFetcher(
      companySettingsApi,
      companySettingsApi.showCompanySettings,
    ),
    handler: genericHandler(CompanySettingFromJSON),
  },
  [Entity.LeaveSetting]: {
    fetcher: showActionFetcher(
      leaveSettingsApi,
      leaveSettingsApi.showLeaveSettings,
    ),
    handler: genericHandler(LeaveSettingsFromJSON),
  },
  [Entity.CompanyOnboarding]: {
    fetcher: showActionFetcher(companyApi, companyApi.showCompanyOnboarding),
    handler: genericHandler(CompanyOnboardingFromJSON),
  },
  [Entity.EmployeeOnboarding]: {
    fetcher: showActionFetcher(employeeApi, employeeApi.showEmployeeOnboarding),
    handler: genericHandler(EmployeeOnboardingFromJSON),
  },
  [Entity.FeaturesEnabled]: {
    fetcher: showActionFetcher(companyApi, companyApi.showEnabledFeatures),
    handler: genericHandler(FeaturesEnabledFromJSON),
  },
  [Entity.ScheduleSetting]: {
    fetcher: showActionFetcher(
      scheduleSettingsApi,
      scheduleSettingsApi.showScheduleSettings,
    ),
    handler: genericHandler(ScheduleSettingsFromJSON),
  },
  [Entity.ScheduleOverviewSummary]: {
    fetcher: showActionFetcher(summariesApi, summariesApi.showScheduleOverview),
    handler: genericHandler(ScheduleOverviewSummaryFromJSON),
  },
};
