
import {
  Employee,
  ScheduleSettingsClockInUnscheduledShiftsEnum,
  Shift,
  TimesheetEntry,
  TimesheetEntryStatusEnum,
} from '@/../api/v1';
import ClockInTimer from '@/components/clockIn/ClockInTimer.vue';
import ClockInWidget from '@/components/clockIn/ClockInWidget.vue';
import LoadingDot from '@/components/loaders/LoadingDot.vue';
import {
  employeeCanClockUnscheduledShift,
  filterShiftsForClocking,
  shiftsForClocking,
  timesheetsInProgress,
} from '@/lib/clock-in/clockInFunctions';
import { Icon } from '@/lib/enum/Icon';
import { Now } from '@/lib/helpers/now';
import { userCan } from '@/lib/permission/userCan';
import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import { sortBy } from '@/util/arrayFunctions';
import { viewport } from '@/util/windowFunctions';
import { PropType } from 'vue';

export default {
  name: 'QuickClockIn',

  components: {
    ClockInWidget,
    ClockInTimer,
    LoadingDot,
  },

  props: {
    employee: {
      type: Object as PropType<Employee>,
      required: true,
    },
  },

  data() {
    const { loggedInEmployee, timezone } = store.getters;
    return {
      TimesheetEntryStatusEnum,
      Icon,
      routes,
      viewport,
      userCan,
      hide: true,
      menuOpen: false,
      now: new Now(),
      shiftsForClocking: shiftsForClocking(loggedInEmployee.id, timezone),
      timesheetsInProgress: timesheetsInProgress(loggedInEmployee.id),
    };
  },

  computed: {
    earlyClockInThresholdInMinutes: (): number =>
      store.state.settings.clockingSettings.earlyClockInPeriod,
    timezone: (): string => store.getters.timezone,
    loggedInEmployee: (): Employee => store.getters.loggedInEmployee,
    clockInUnscheduledShifts:
      (): ScheduleSettingsClockInUnscheduledShiftsEnum =>
        store.state.settings.scheduleSettings.clockInUnscheduledShifts,
    locations: (): Location[] =>
      store.getters['locations/locationsWithClockInFromPersonalDevicesEnabled'],

    filteredShiftsForClocking(): Shift[] {
      return filterShiftsForClocking(
        this.shiftsForClocking.data,
        this.locations,
        this.now.time,
        this.timezone,
      );
    },

    allowUnscheduledShifts(): boolean {
      return employeeCanClockUnscheduledShift(
        this.loggedInEmployee.id,
        this.filteredShiftsForClocking,
        this.timezone,
        this.clockInUnscheduledShifts,
      );
    },

    activeTimesheet(): TimesheetEntry | null {
      return this.timesheetsInProgress.data[0] ?? null;
    },
    activeShift(): Shift | null {
      const clockableShifts: Shift[] = sortBy(
        this.filteredShiftsForClocking,
        'startsAt',
      );
      return clockableShifts?.[0] ?? null;
    },

    initialising(): boolean {
      return (
        this.shiftsForClocking.isLoading || this.timesheetsInProgress.isLoading
      );
    },

    isWithinClockInThreshold(): boolean {
      const earliestClockInMs =
        this.activeShift.startsAt.getTime() -
        this.earlyClockInThresholdInMinutes * 60 * 1000;
      return this.now.time.getTime() >= earliestClockInMs;
    },
  },

  watch: {
    activeTimesheet: {
      deep: true,
      handler() {
        this.checkVisibility();
      },
    },
    filteredShiftsForClocking: {
      deep: true,
      handler() {
        this.checkVisibility();
      },
    },
    allowUnscheduledShifts() {
      this.checkVisibility();
    },
  },

  async mounted() {
    this.now.init();
    this.checkVisibility();
  },

  beforeDestroy() {
    this.now.destroy();
  },

  methods: {
    redirect,

    closeMenu() {
      this.menuOpen = false;
    },
    maximize() {
      this.closeMenu();
      if (this.$route.name !== routes.timeClock.name) {
        this.redirect(routes.timeClock.route());
      }
    },

    checkVisibility() {
      // Show if there's a next shift within 12 hours
      this.hide =
        !this.activeShift &&
        !this.activeTimesheet &&
        !this.allowUnscheduledShifts;
    },
  },
};
