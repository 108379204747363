/**
 * ClONE OF: src/lib/realtime/realtimeTypes.ts
 *
 * DIFF:
 *   * Removed types related to filters, moved to realtimeFiltering.ts
 *   * Added support of single entity fetcher
 *   * Merged batch entity config with regular entity config
 *   * Removed SubscriptionResponse, since it is managed by WeakEntityRepository now
 */

import { GenericApiResponse, SingleEntityApiResponse } from '@/api';
import { Where } from '@/lib/realtime/weak/realtimeFiltering';
import {
  Absence,
  AbsenceCategory,
  AccessRole,
  BaseAPI,
  BillingPlan,
  BudgetSummary,
  ClockInPortal,
  Company,
  CompanyNotificationChannel,
  CompanySetting,
  DaySummary,
  DigiticketsConnection,
  DigiticketsScheduleMap,
  Document,
  DocumentRead,
  EmergencyContact,
  Employee,
  EmployeeAbsenceSummary,
  EmployeeAttribute,
  EmployeeClockingProfile,
  EmployeeGroup,
  EmployeeInvite,
  EmployeeJobRole,
  EmployeeLeaveSummary,
  EmployeeMessage,
  EmployeeMessageRecipient,
  EmployeeNotificationChannel,
  EmployeePay,
  EmployeePayPeriodSummary,
  EmployeePersonalDetails,
  FeaturesEnabled,
  History,
  IcalFeed,
  JobRole,
  LeaveAdjustment,
  LeavePeriod,
  LeavePolicy,
  LeaveRequest,
  LeaveType,
  ListEmployeePayPeriodSummariesRequest,
  ListEventEntitiesResponseDataEnum,
  ListTimesheetSummariesRequest,
  Location,
  NotificationSettings,
  Offer,
  OffersBehaviour,
  OffersQuota,
  OpenShiftResponse,
  PayCycle,
  PayPeriod,
  PayPeriodsSummary,
  PublicHoliday,
  Schedule,
  ScheduleEvent,
  ScheduleOverviewSummary,
  ScheduleTemplate,
  Shift,
  ShiftSwap,
  ShiftTemplate,
  ShowBudgetSummaryRequest,
  ShowDaySummaryRequest,
  ShowLeavePolicyRequest,
  ShowScheduleOverviewRequest,
  Tag,
  TimesheetEntry,
  TimesheetEntrySummary,
  TrekksoftClientCredential,
  Unavailability,
  Upload,
  User,
  WorkPattern,
} from '../../../../api/v1';

const Entity = ListEventEntitiesResponseDataEnum;

// For cases when an entity is not defined in ListEventEntitiesResponseDataEnum, but we want to use it in the app.
export enum CustomEntity {
  EmployeePayPeriodSummary = 'EmployeePayPeriodSummary',
  FeaturesEnabled = 'FeaturesEnabled',
  PayPeriodsSummary = 'PayPeriodsSummary',
}

/**
 * To allow Typescript to understand the return type of our store functions, we need to map string representations
 * of our entities to actual Typescript interfaces.
 */
export type EntityInterfaceMap = {
  [Entity.Absence]: Absence;
  [Entity.AbsenceCategory]: AbsenceCategory;
  [Entity.AccessRole]: AccessRole;
  [Entity.BillingPlan]: BillingPlan;
  [Entity.ClockInPortal]: ClockInPortal;
  [Entity.CompanyNotificationChannel]: CompanyNotificationChannel;
  [Entity.Company]: Company;
  [Entity.DigiticketsConnection]: DigiticketsConnection;
  [Entity.DigiticketsScheduleMap]: DigiticketsScheduleMap;
  [Entity.DocumentRead]: DocumentRead;
  [Entity.Document]: Document;
  [Entity.EmergencyContact]: EmergencyContact;
  [Entity.Employee]: Employee;
  [Entity.EmployeeAbsenceSummary]: EmployeeAbsenceSummary;
  [Entity.EmployeeAttribute]: EmployeeAttribute;
  [Entity.EmployeeClockingProfile]: EmployeeClockingProfile;
  [Entity.EmployeeGroup]: EmployeeGroup;
  [Entity.EmployeeInvite]: EmployeeInvite;
  [Entity.EmployeeJobRole]: EmployeeJobRole;
  [Entity.EmployeeLeaveSummary]: EmployeeLeaveSummary;
  [Entity.EmployeeMessage]: EmployeeMessage;
  [Entity.EmployeeMessageRecipient]: EmployeeMessageRecipient;
  [Entity.EmployeeNotificationChannel]: EmployeeNotificationChannel;
  [Entity.EmployeePay]: EmployeePay;
  [Entity.EmployeePersonalDetail]: EmployeePersonalDetails;
  [Entity.IcalFeed]: IcalFeed;
  [Entity.JobRole]: JobRole;
  [Entity.LeaveAdjustment]: LeaveAdjustment;
  [Entity.LeavePeriod]: LeavePeriod;
  [Entity.LeavePolicy]: LeavePolicy;
  [Entity.LeaveRequest]: LeaveRequest;
  [Entity.LeaveType]: LeaveType;
  [Entity.Location]: Location;
  [Entity.Offer]: Offer;
  [Entity.OffersBehaviour]: OffersBehaviour;
  [Entity.OffersQuota]: OffersQuota;
  [Entity.OpenShiftResponse]: OpenShiftResponse;
  [Entity.PayCycle]: PayCycle;
  [Entity.PayPeriod]: PayPeriod;
  [Entity.PublicHoliday]: PublicHoliday;
  [Entity.Schedule]: Schedule;
  [Entity.ScheduleEvent]: ScheduleEvent;
  [Entity.ScheduleTemplate]: ScheduleTemplate;
  [Entity.Shift]: Shift;
  [Entity.ShiftSwap]: ShiftSwap;
  [Entity.ShiftTemplate]: ShiftTemplate;
  [Entity.Tag]: Tag;
  [Entity.TimesheetEntry]: TimesheetEntry;
  [Entity.TimesheetEntrySummary]: TimesheetEntrySummary;
  [Entity.TrekksoftClientCredential]: TrekksoftClientCredential;
  [Entity.Unavailability]: Unavailability;
  [Entity.Upload]: Upload;
  [Entity.User]: User;
  [Entity.WorkPattern]: WorkPattern;
  [Entity.DaySummary]: DaySummary;
  [Entity.BudgetSummary]: BudgetSummary;
  [Entity.ScheduleOverviewSummary]: ScheduleOverviewSummary;
  [Entity.NotificationSetting]: NotificationSettings;
  [Entity.History]: History;
  [Entity.CompanySetting]: CompanySetting;
  [Entity.LeavePolicy]: LeavePolicy;

  [CustomEntity.EmployeePayPeriodSummary]: EmployeePayPeriodSummary;
  [CustomEntity.FeaturesEnabled]: FeaturesEnabled;
  [CustomEntity.EmployeePayPeriodSummary]: EmployeePayPeriodSummary;
  [CustomEntity.PayPeriodsSummary]: PayPeriodsSummary;
};

export type EntityFetcherRequestMap = {
  [CustomEntity.EmployeePayPeriodSummary]: ListEmployeePayPeriodSummariesRequest;
  [Entity.BudgetSummary]: ShowBudgetSummaryRequest;
  [Entity.DaySummary]: ShowDaySummaryRequest;
  [Entity.ScheduleOverviewSummary]: ShowScheduleOverviewRequest;
  [Entity.TimesheetEntrySummary]: ListTimesheetSummariesRequest;
  [Entity.LeavePolicy]: ShowLeavePolicyRequest;
};

export type EntityName = string & keyof EntityInterfaceMap;
export type WSEntityName = EntityName & ListEventEntitiesResponseDataEnum;

export type OrderBy<T extends object> = (
  | (keyof T & string)
  | `-${keyof T & string}`
)[];

export type GetParameterType<T> = T extends keyof EntityFetcherRequestMap &
  EntityName
  ? EntityFetcherRequestMap[T]
  : never;

export type EntityInstance<T extends EntityName> = EntityInterfaceMap[T];

export type ListFunction =
  | (<T extends EntityName>(
      requestParameters?: GetParameterType<T>,
      initOverrides?,
    ) => Promise<GenericApiResponse<EntityInstance<T> | unknown>>)
  | (<T extends EntityName>(
      initOverrides?,
    ) => Promise<GenericApiResponse<EntityInstance<T> | unknown>>);

export type ShowFunction =
  | (<T extends EntityName>(
      requestParameters?: GetParameterType<T>,
      initOverrides?,
    ) => Promise<SingleEntityApiResponse<EntityInstance<T> | unknown>>)
  | (<T extends EntityName>(
      initOverrides?,
    ) => Promise<SingleEntityApiResponse<EntityInstance<T> | unknown>>);

export type Fetcher = <T extends EntityName>(
  params: GetParameterType<T>,
  where: Where<T>,
  orderBy: OrderBy<EntityInstance<T>>,
) => Promise<EntityInstance<T>[]>;

export type BatchFetcher = <T extends WSEntityName>(
  ids: EntityInstance<T>['id'][],
) => Promise<EntityInstance<T>[]>;

export type FetcherBuilder = (
  api: BaseAPI,
  listFunction: ListFunction,
  options?: {
    _with?: string[];
    perPage?: number;
  },
) => Fetcher;

export type SingleEntityFetcherBuilder = (
  api: BaseAPI,
  showFunction: ShowFunction,
  requiredParams?: string[],
) => Fetcher;

export type BatchFetcherBuilder = (
  api: BaseAPI,
  listFunction: ListFunction,
) => BatchFetcher;

export type EntityConfigRecord<T extends EntityName> = {
  fetcher: Fetcher;
  batchFetcher?: BatchFetcher;
  entityMaker: (data: unknown) => EntityInstance<T>;
  triggers?: ListEventEntitiesResponseDataEnum[];
  orderBy?: OrderBy<EntityInstance<T>>;
} & (T extends WSEntityName
  ? { useWebsocket?: boolean }
  : // Force disabling the use of websocket for non-list entities
    { useWebsocket: false });

export type EntityConfig = {
  [K in EntityName]: EntityConfigRecord<K>;
};

export type EntityRefetchTrigger = {
  entity: EntityName;
  triggers: ListEventEntitiesResponseDataEnum[];
};
