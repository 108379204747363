/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingPlan } from './BillingPlan';
import {
    BillingPlanFromJSON,
    BillingPlanFromJSONTyped,
    BillingPlanToJSON,
} from './BillingPlan';
import type { BillingSubscriptionQuota } from './BillingSubscriptionQuota';
import {
    BillingSubscriptionQuotaFromJSON,
    BillingSubscriptionQuotaFromJSONTyped,
    BillingSubscriptionQuotaToJSON,
} from './BillingSubscriptionQuota';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
} from './ShiftiePlainDate';

/**
 * Represents a companies subscription to a billing plan.
 * @export
 * @interface BillingSubscription
 */
export interface BillingSubscription {
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    billingPlanId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BillingSubscription
     */
    startsOn: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BillingSubscription
     */
    endsOn: ShiftiePlainDate | null;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    status: BillingSubscriptionStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    type: BillingSubscriptionTypeEnum;
    /**
     * 
     * @type {Date}
     * @memberof BillingSubscription
     */
    terminatedAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof BillingSubscription
     */
    terminationReason: BillingSubscriptionTerminationReasonEnum;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof BillingSubscription
     */
    nextRenewalAt: ShiftiePlainDate;
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    price: number;
    /**
     * 
     * @type {number}
     * @memberof BillingSubscription
     */
    additionalEmployeePrice: number;
    /**
     * The number of employees included in the price of the plan.
     * @type {number}
     * @memberof BillingSubscription
     */
    employeeSoftLimit: number;
    /**
     * The maximum number of employees allowed on the plan.
     * @type {number}
     * @memberof BillingSubscription
     */
    employeeHardLimit: number;
    /**
     * 
     * @type {BillingPlan}
     * @memberof BillingSubscription
     */
    billingPlan: BillingPlan;
    /**
     * 
     * @type {BillingSubscriptionQuota}
     * @memberof BillingSubscription
     */
    billingSubscriptionQuota?: BillingSubscriptionQuota;
}

/**
* @export
* @enum {string}
*/
export enum BillingSubscriptionStatusEnum {
    Active = 'Active',
    Terminated = 'Terminated'
}
/**
* @export
* @enum {string}
*/
export enum BillingSubscriptionTypeEnum {
    Paid = 'Paid',
    Trial = 'Trial',
    Onboarding = 'Onboarding'
}
/**
* @export
* @enum {string}
*/
export enum BillingSubscriptionTerminationReasonEnum {
    AccountCancelled = 'Account Cancelled',
    BillingFailed = 'Billing Failed',
    NewPlan = 'New Plan',
    SubscriptionExpired = 'Subscription Expired',
    CompanyAnonymised = 'Company Anonymised'
}


/**
 * Check if a given object implements the BillingSubscription interface.
 */
export function instanceOfBillingSubscription(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('companyId' in value)) return false;
    if (!('billingPlanId' in value)) return false;
    if (!('startsOn' in value)) return false;
    if (!('endsOn' in value)) return false;
    if (!('status' in value)) return false;
    if (!('type' in value)) return false;
    if (!('terminatedAt' in value)) return false;
    if (!('terminationReason' in value)) return false;
    if (!('nextRenewalAt' in value)) return false;
    if (!('price' in value)) return false;
    if (!('additionalEmployeePrice' in value)) return false;
    if (!('employeeSoftLimit' in value)) return false;
    if (!('employeeHardLimit' in value)) return false;
    if (!('billingPlan' in value)) return false;
    return true;
}

export function BillingSubscriptionFromJSON(json: any): BillingSubscription {
    return BillingSubscriptionFromJSONTyped(json, false);
}

export function BillingSubscriptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): BillingSubscription {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'billingPlanId': json['billingPlanId'],
        'startsOn': ShiftiePlainDateFromJSON(json['startsOn']),
        'endsOn': ShiftiePlainDateFromJSON(json['endsOn']),
        'status': json['status'],
        'type': json['type'],
        'terminatedAt': (json['terminatedAt'] == null ? null : new Date(json['terminatedAt'])),
        'terminationReason': json['terminationReason'],
        'nextRenewalAt': ShiftiePlainDateFromJSON(json['nextRenewalAt']),
        'price': json['price'],
        'additionalEmployeePrice': json['additionalEmployeePrice'],
        'employeeSoftLimit': json['employeeSoftLimit'],
        'employeeHardLimit': json['employeeHardLimit'],
        'billingPlan': BillingPlanFromJSON(json['billingPlan']),
        'billingSubscriptionQuota': json['billingSubscriptionQuota'] == null ? undefined : BillingSubscriptionQuotaFromJSON(json['billingSubscriptionQuota']),
    };
}

export function BillingSubscriptionToJSON(value?: BillingSubscription | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'billingPlanId': value['billingPlanId'],
        'startsOn': ShiftiePlainDateToJSON(value['startsOn']),
        'endsOn': ShiftiePlainDateToJSON(value['endsOn']),
        'status': value['status'],
        'type': value['type'],
        'terminatedAt': (value['terminatedAt'] == null ? null : (value['terminatedAt'] as any).toISOString()),
        'terminationReason': value['terminationReason'],
        'nextRenewalAt': ShiftiePlainDateToJSON(value['nextRenewalAt']),
        'price': value['price'],
        'additionalEmployeePrice': value['additionalEmployeePrice'],
        'employeeSoftLimit': value['employeeSoftLimit'],
        'employeeHardLimit': value['employeeHardLimit'],
        'billingPlan': BillingPlanToJSON(value['billingPlan']),
        'billingSubscriptionQuota': BillingSubscriptionQuotaToJSON(value['billingSubscriptionQuota']),
    };
}

