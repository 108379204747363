type FloatParts = [mantissa: number, exponent: number];

const floatParts = (value): FloatParts => {
  const [mantissa, exponent] = Number(value)
    .toExponential()
    .split('e')
    .map((s) => +s);
  return [mantissa, exponent];
};

/**
 * Based on https://www.npmjs.com/package/round-tofixed
 * @see https://github.com/terrymorse58/round-tofixed/blob/master/round-tofixed.js
 */
export const round = (
  value: number,
  decimalPlaces: number,
  highPrecision: boolean = false,
): number => {
  if (
    typeof value === 'number' &&
    typeof decimalPlaces === 'number' &&
    Number.isInteger(decimalPlaces) &&
    (decimalPlaces === 0 || Math.sign(decimalPlaces) === 1)
  ) {
    const [originalMantissa, originalExponent] = floatParts(value);
    const roundingMethod = highPrecision ? Math.floor : Math.round;
    const [newMantissa, newExponent] = floatParts(
      roundingMethod(
        +`${originalMantissa}e${originalExponent + decimalPlaces}`,
      ),
    );
    return +`${newMantissa}e${newExponent - decimalPlaces}`;
  }
  throw new Error(`"${value}" could not be rounded`);
};
