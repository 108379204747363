interface BaseEventMap {
  [key: string]: typeof key extends 'type' ? never : Record<string, unknown>;
}

type CustomEventListener<
  T extends BaseEventMap,
  K extends string = keyof T & string,
> = (event: T[K] & { type: K }) => void;

type CustomEventListenerRecord<
  T extends BaseEventMap,
  K extends string = keyof T & string,
> = Map<K, Set<CustomEventListener<T, K>>>;

export type Unsubscribe = () => void;

export class CustomEventEmitter<
  T extends BaseEventMap,
  K extends string = keyof T & string,
> {
  #listeners: CustomEventListenerRecord<T, K> = new Map();

  addEventListener<Type extends K>(
    type: Type,
    listener: CustomEventListener<T, Type>,
  ): Unsubscribe {
    if (!this.#listeners.has(type)) this.#listeners.set(type, new Set());
    this.#listeners.get(type).add(listener as CustomEventListener<T, K>);
    return () => this.removeEventListener(type, listener);
  }

  removeEventListener<Type extends K>(
    type: Type,
    listener: CustomEventListener<T, Type>,
  ) {
    if (!this.#listeners.has(type)) return;
    this.#listeners.get(type).delete(listener as CustomEventListener<T, K>);
  }

  protected _dispatchEvent<Type extends K, Event extends T[Type] = T[Type]>(
    type: Type,
    event: Event,
  ) {
    if (!this.#listeners.has(type)) return;
    this.#listeners.get(type).forEach((listener) =>
      listener({
        ...event,
        type,
      }),
    );
  }
}
