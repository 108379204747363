import {
  ClockingSettings,
  CompanySetting,
  LeaveSettings,
  NotificationSettings,
  ScheduleSettings,
} from '../../api/v1';

export default {
  namespaced: true,
  state: {
    clockingSettings: {} as ClockingSettings,
    companySettings: {} as CompanySetting,
    notificationSettings: {} as NotificationSettings,
    scheduleSettings: {} as ScheduleSettings,
    leaveSettings: {} as LeaveSettings,
  },

  getters: {
    currency(state) {
      return state.companySettings.currencySymbol;
    },

    timeFormat(state) {
      return state.companySettings.timeFormat;
    },
  },

  mutations: {
    SET_CLOCKING_SETTINGS(state, data) {
      state.clockingSettings = data;
    },

    SET_NOTIFICATION_SETTINGS(state, data) {
      state.notificationSettings = data;
    },

    SET_COMPANY_SETTINGS(state, data) {
      state.companySettings = data;
    },

    SET_SCHEDULE_SETTINGS(state, data) {
      state.scheduleSettings = data;
    },

    SET_LEAVE_SETTINGS(state, data) {
      state.leaveSettings = data;
    },
  },

  actions: {
    async reset({ commit }) {
      commit('SET_CLOCKING_SETTINGS', {});
      commit('SET_NOTIFICATION_SETTINGS', {});
      commit('SET_COMPANY_SETTINGS', {});
      commit('SET_SCHEDULE_SETTINGS', {});
      commit('SET_LEAVE_SETTINGS', {});
    },

    setClockingSettings({ commit }, data: ClockingSettings) {
      commit('SET_CLOCKING_SETTINGS', data);
    },

    setNotificationSettings({ commit }, data: NotificationSettings) {
      commit('SET_NOTIFICATION_SETTINGS', data);
    },

    setCompanySettings({ commit }, data: CompanySetting) {
      commit('SET_COMPANY_SETTINGS', data);
    },

    setScheduleSettings({ commit }, data: ScheduleSettings) {
      commit('SET_SCHEDULE_SETTINGS', data);
    },

    setLeaveSettings({ commit }, data: LeaveSettings) {
      commit('SET_LEAVE_SETTINGS', data);
    },
  },
};
