/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTimesheetEntryDataBreaksInner
 */
export interface CreateTimesheetEntryDataBreaksInner {
    /**
     * 
     * @type {Date}
     * @memberof CreateTimesheetEntryDataBreaksInner
     */
    startedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateTimesheetEntryDataBreaksInner
     */
    endedAt: Date;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTimesheetEntryDataBreaksInner
     */
    paid: boolean;
}

/**
 * Check if a given object implements the CreateTimesheetEntryDataBreaksInner interface.
 */
export function instanceOfCreateTimesheetEntryDataBreaksInner(value: object): boolean {
    if (!('startedAt' in value)) return false;
    if (!('endedAt' in value)) return false;
    if (!('paid' in value)) return false;
    return true;
}

export function CreateTimesheetEntryDataBreaksInnerFromJSON(json: any): CreateTimesheetEntryDataBreaksInner {
    return CreateTimesheetEntryDataBreaksInnerFromJSONTyped(json, false);
}

export function CreateTimesheetEntryDataBreaksInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTimesheetEntryDataBreaksInner {
    if (json == null) {
        return json;
    }
    return {
        
        'startedAt': (new Date(json['startedAt'])),
        'endedAt': (new Date(json['endedAt'])),
        'paid': json['paid'],
    };
}

export function CreateTimesheetEntryDataBreaksInnerToJSON(value?: CreateTimesheetEntryDataBreaksInner | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'startedAt': ((value['startedAt']).toISOString()),
        'endedAt': ((value['endedAt']).toISOString()),
        'paid': value['paid'],
    };
}

