/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClockOutData
 */
export interface ClockOutData {
    /**
     * Which employee should be clocked out. Leave blank to clock yourself out.
     * @type {number}
     * @memberof ClockOutData
     */
    employeeId?: number;
}

/**
 * Check if a given object implements the ClockOutData interface.
 */
export function instanceOfClockOutData(value: object): boolean {
    return true;
}

export function ClockOutDataFromJSON(json: any): ClockOutData {
    return ClockOutDataFromJSONTyped(json, false);
}

export function ClockOutDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockOutData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
    };
}

export function ClockOutDataToJSON(value?: ClockOutData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employeeId': value['employeeId'],
    };
}

