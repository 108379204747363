/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Settings for the clock in functionality
 * @export
 * @interface ClockingSettings
 */
export interface ClockingSettings {
    /**
     * 
     * @type {number}
     * @memberof ClockingSettings
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof ClockingSettings
     */
    earlyClockInPeriod: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClockingSettings
     */
    clockInGracePeriod: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClockingSettings
     */
    autoApprovalThresholdInMinutes: number;
    /**
     * 
     * @type {boolean}
     * @memberof ClockingSettings
     */
    employeesCanEditTimeEntries: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ClockingSettings
     */
    enableAutoClockOut: boolean;
    /**
     * The number of hours after which an employee will be automatically clocked out.
     * @type {number}
     * @memberof ClockingSettings
     */
    autoClockOutDelayPeriod: number;
}

/**
 * Check if a given object implements the ClockingSettings interface.
 */
export function instanceOfClockingSettings(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('earlyClockInPeriod' in value)) return false;
    if (!('clockInGracePeriod' in value)) return false;
    if (!('autoApprovalThresholdInMinutes' in value)) return false;
    if (!('employeesCanEditTimeEntries' in value)) return false;
    if (!('enableAutoClockOut' in value)) return false;
    if (!('autoClockOutDelayPeriod' in value)) return false;
    return true;
}

export function ClockingSettingsFromJSON(json: any): ClockingSettings {
    return ClockingSettingsFromJSONTyped(json, false);
}

export function ClockingSettingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockingSettings {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'earlyClockInPeriod': json['earlyClockInPeriod'],
        'clockInGracePeriod': json['clockInGracePeriod'],
        'autoApprovalThresholdInMinutes': json['autoApprovalThresholdInMinutes'],
        'employeesCanEditTimeEntries': json['employeesCanEditTimeEntries'],
        'enableAutoClockOut': json['enableAutoClockOut'],
        'autoClockOutDelayPeriod': json['autoClockOutDelayPeriod'],
    };
}

export function ClockingSettingsToJSON(value?: ClockingSettings | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'earlyClockInPeriod': value['earlyClockInPeriod'],
        'clockInGracePeriod': value['clockInGracePeriod'],
        'autoApprovalThresholdInMinutes': value['autoApprovalThresholdInMinutes'],
        'employeesCanEditTimeEntries': value['employeesCanEditTimeEntries'],
        'enableAutoClockOut': value['enableAutoClockOut'],
        'autoClockOutDelayPeriod': value['autoClockOutDelayPeriod'],
    };
}

