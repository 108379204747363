/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeePay } from './EmployeePay';
import {
    EmployeePayFromJSON,
    EmployeePayFromJSONTyped,
    EmployeePayToJSON,
} from './EmployeePay';

/**
 * 
 * @export
 * @interface UpdateEmployeePayResponse
 */
export interface UpdateEmployeePayResponse {
    /**
     * 
     * @type {EmployeePay}
     * @memberof UpdateEmployeePayResponse
     */
    data: EmployeePay;
}

/**
 * Check if a given object implements the UpdateEmployeePayResponse interface.
 */
export function instanceOfUpdateEmployeePayResponse(value: object): boolean {
    if (!('data' in value)) return false;
    return true;
}

export function UpdateEmployeePayResponseFromJSON(json: any): UpdateEmployeePayResponse {
    return UpdateEmployeePayResponseFromJSONTyped(json, false);
}

export function UpdateEmployeePayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateEmployeePayResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': EmployeePayFromJSON(json['data']),
    };
}

export function UpdateEmployeePayResponseToJSON(value?: UpdateEmployeePayResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': EmployeePayToJSON(value['data']),
    };
}

