var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Dialog',{attrs:{"fullscreen":false,"max-width":450},model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('div',{staticClass:"flex flex-column bg-white text-center pb-4"},[(_vm.bubbleIcon)?_c('BubbleIcon',{staticClass:"mb-4",attrs:{"background-class":_vm.bubbleIcon.colour,"foreground-class":_vm.bubbleIcon.colour,"icon":_vm.bubbleIcon.icon,"size":"xxlarge"}}):_vm._e(),_c('div',{staticClass:"mx-4",class:!!_vm.bodyText ? 'mb-2' : 'mb-4'},[_c('p',{staticClass:"text-24 mb-0"},[_vm._v(" "+_vm._s(_vm.header)+" ")]),(_vm.location && _vm.showLocationRestrictions)?_c('div',{staticClass:"flex gap-2 items-center text-center justify-center text-14 text-gray-600"},[_c('i',{class:_vm.Icon.LocationDot}),_c('span',[(
              [
                _vm.ClockInDialogTypeEnum.ClockIn,
                _vm.ClockInDialogTypeEnum.EndBreak,
              ].includes(_vm.type)
            )?_c('strong',[_vm._v(" "+_vm._s(_vm.$t('unitWithAmount.atLocation', { location: _vm.location.name }))+" ")]):_vm._e(),(_vm.type === _vm.ClockInDialogTypeEnum.ClockOut)?_c('strong',[_vm._v(" "+_vm._s(_vm.$t('unitWithAmount.fromLocation', { location: _vm.location.name }))+" ")]):_vm._e()])]):_vm._e()]),(_vm.bodyText)?_c('p',{staticClass:"mx-4 text-16 px-6 text-gray-700"},[_vm._v(" "+_vm._s(_vm.bodyText)+" ")]):_vm._e(),(_vm.bodyExtra)?_c('strong',{staticClass:"text-14 mb-4 mx-4 text-gray-600"},[_vm._v(" "+_vm._s(_vm.bodyExtra)+" ")]):_vm._e(),(_vm.employeesMustBeAtLocation && _vm.showLocationRestrictions)?_c('div',{staticClass:"all:flex flex-col gap-4"},[(_vm.fetchingGpsData)?_c('div',{staticClass:"all:flex flex-col items-center"},[_c('p',{staticClass:"text-16 text-gray-700"},[_vm._v(" "+_vm._s(_vm.$t('clockIn.fetchingGpsLocation'))+" ")]),_c('MapPlaceholder')],1):(!_vm.userCoordinates || !_vm.userIsWithinClockingDistance)?_c('InlineDialog',{staticClass:"text-14 mx-4",attrs:{"icon":_vm.Icon.Exclamation,"type":_vm.userCoordinates ? 'warning' : 'error'},scopedSlots:_vm._u([{key:"message",fn:function(){return [(_vm.userCoordinates)?_c('i18n',{attrs:{"tag":"p","path":[
                _vm.ClockInDialogTypeEnum.ClockIn,
                _vm.ClockInDialogTypeEnum.EndBreak,
              ].includes(_vm.type)
                ? 'clockIn.outsideClockInArea'
                : 'clockIn.outsideClockOutArea'},scopedSlots:_vm._u([{key:"meters",fn:function(){return [_c('strong',[(_vm.userDistanceFromLocation < 1000)?[_vm._v(" "+_vm._s(_vm.$tc( 'unitWithAmount.metersShort', _vm.userDistanceFromLocation, ))+" ")]:[_vm._v(" "+_vm._s(_vm.$tc( 'unitWithAmount.kilometersShort', _vm.round(_vm.userDistanceFromLocation / 1000, 2), ))+" ")]],2)]},proxy:true}],null,false,3516803269)}):_c('span',[_vm._v(_vm._s(_vm.$t('clockIn.noPositionDetection')))])]},proxy:true}],null,false,3224002765)}):_vm._e(),(_vm.location && _vm.userCoordinates)?_c('div',[_c('Map',{staticClass:"h-[300px]",attrs:{"marker":{
            coordinates: [_vm.location.longitude, _vm.location.latitude],
            options: { color: '#ec3f8c', usePin: true },
          },"container-id":"wrong-location-map","radius-in-meters":_vm.location?.clockInGeoFenceInMeters ?? 0,"user-coordinates":_vm.userCoordinates}})],1):_vm._e(),(_vm.userCoordinates && !_vm.userIsWithinClockingDistance)?_c('p',{staticClass:"text-16 px-6 text-gray-700 mb-0"},[(_vm.type === _vm.ClockInDialogTypeEnum.ClockIn)?[_vm._v(" "+_vm._s(_vm.$t('clockIn.moveCloserStartShift'))+" ")]:_vm._e(),(_vm.type === _vm.ClockInDialogTypeEnum.ClockOut)?[_vm._v(" "+_vm._s(_vm.$t('clockIn.moveCloserEndShift'))+" ")]:_vm._e()],2):_vm._e()],1):_vm._e(),(
        !(
          _vm.showLocationRestrictions &&
          _vm.employeesMustBeAtLocation &&
          !_vm.userCoordinates
        )
      )?_c('InputGroup',{staticClass:"px-4 mt-4"},[(_vm.showSelect)?_c('SelectInput',{staticClass:"all:pb-4 md:pb-0",attrs:{"items":_vm.filteredEmployeeLocations,"label-text":_vm.$t('label.locations'),"value":_vm.selectedLocationId,"required":""},on:{"input":function($event){_vm.selectedLocationId = $event}}}):_vm._e(),(_vm.type === _vm.ClockInDialogTypeEnum.ChooseBreak)?_c('InputGroup',[_c('RadioButtons',{attrs:{"list":_vm.chooseBreakList,"max-width":"420px","radio-group":"selectedBreakId"},scopedSlots:_vm._u([{key:"default",fn:function({ input: { disabled, scheduledBreak } }){return [_c('div',{staticClass:"flex items-center w-full"},[_c('div',{staticClass:"flex flex-col flex-1 text-start overflow-hidden"},[_c('span',{staticClass:"text-gray-900 mb-1 text-overflow-ellipsis"},[_vm._v(" "+_vm._s(_vm.getScheduledBreakTitle(scheduledBreak))+" ")]),_c('span',{staticClass:"text-12"},[_vm._v(" "+_vm._s(_vm.getScheduledBreakInfo(scheduledBreak))+" ")])]),(disabled)?_c('BubbleIcon',{attrs:{"size":_vm.IconSizes.Small,"icon":_vm.Icon.Check,"foreground-class":"white","background-class":"bright-green"}}):_vm._e()],1)]}}],null,false,2650161339),model:{value:(_vm.selectedBreakId),callback:function ($$v) {_vm.selectedBreakId=$$v},expression:"selectedBreakId"}})],1):_vm._e(),_c('InputGroup',{attrs:{"row-at":"all"}},[(_vm.showCancel)?_c('Button',{staticClass:"w-full",attrs:{"color":'transparent'},on:{"click":function($event){_vm.open = false}}},[_vm._v(" "+_vm._s(_vm.$t('button.cancel'))+" ")]):_vm._e(),_c('Button',{class:'w-full',attrs:{"color":_vm.button.colour,"size":_vm.showCancel ? 'md' : 'lg',"disabled":(_vm.type === _vm.ClockInDialogTypeEnum.ChooseLocation &&
              !_vm.selectedLocationId) ||
            (_vm.showLocationRestrictions && !_vm.userIsWithinClockingDistance)},on:{"click":_vm.button.action}},[_vm._v(" "+_vm._s(_vm.button.label)+" ")])],1)],1):_vm._e(),(
        _vm.showLocationRestrictions &&
        _vm.employeesMustBeAtLocation &&
        (!_vm.userCoordinates || !_vm.userIsWithinClockingDistance)
      )?[_c('hr',{staticClass:"border-gray-100 mt-5 mx-4"}),_c('IconMessageBlock',{staticClass:"text-12 mt-4 mx-4",attrs:{"icon":_vm.Icon.CircleExclamation,"type":"hint"}},[_c('i18n',{staticClass:"text-left",attrs:{"tag":"span","path":_vm.userCoordinates
              ? 'clockIn.definitelyInRightLocation'
              : 'clockIn.enablingLocationServices'},scopedSlots:_vm._u([{key:"link",fn:function(){return [_c('a',{staticClass:"text-12",attrs:{"href":_vm.links.clockInTroubleshooting,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t('link.troubleshootingTips'))+" ")])]},proxy:true}],null,false,2808371137)})],1)]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }