// https://vuelidate-next.netlify.app/advanced_usage.html#i18n-support
import i18n from '@/i18n';
import { blacklist } from '@/lib/passwords/PasswordBlacklist';
import { getNumericValueRepresentation } from '@/util/numberFunctions';
import {
  ErrorObject,
  ValidationRuleWithoutParams,
  ValidationRuleWithParams,
} from '@vuelidate/core';
import * as validators from '@vuelidate/validators';
import { isValidPhoneNumber as checkPhoneNumber } from 'libphonenumber-js/max';
import { Ref } from 'vue';
import { CompanySettingTimeFormatEnum } from '../../api/v1';

const { createI18nMessage } = validators;

const getErrorMessage = (error: ErrorObject) => error.$message;
export const getErrorMessages = (errors: ErrorObject[]) =>
  errors.map(getErrorMessage);

export const withI18nMessage = createI18nMessage({
  // @ts-ignore
  t: i18n.t.bind(i18n),
  messagePath: ({ $validator }) => `errorMessage.validation.${$validator}`,
});

export const required = withI18nMessage(validators.required);
export const requiredIf = withI18nMessage(
  validators.requiredIf as (
    prop:
      | boolean
      | Ref<boolean>
      | string
      | ((value: unknown, parent: unknown) => boolean | Promise<boolean>),
  ) => ValidationRuleWithoutParams,
  {
    withArguments: true,
    messagePath: () => 'errorMessage.validation.isRequired',
  },
);

export const numeric = withI18nMessage(validators.numeric);

export const integer = withI18nMessage(validators.integer);

export const decimal = withI18nMessage(validators.decimal);

export const halfFractionsOnly = withI18nMessage((val: string) => {
  if (!val) return true;
  return Number.isInteger(+val * 2);
});

export const maxValue = withI18nMessage(
  validators.maxValue as (
    max: number | Ref<number> | string | Ref<string> | Date | Ref<Date>,
  ) => ValidationRuleWithParams<{ max: number }>,
  {
    withArguments: true,
    messagePath: () => 'errorMessage.validation.isLessOrEqualThanValue',
    messageParams: (params) => ({
      ...params,
      value: getNumericValueRepresentation(params.max),
    }),
  },
);

export const minValue = withI18nMessage(
  validators.minValue as (
    min: number | Ref<number> | string | Ref<string> | Date | Ref<Date>,
  ) => ValidationRuleWithParams<{ min: number }>,
  {
    withArguments: true,
    messagePath: () => 'errorMessage.validation.isGreaterOrEqualThanValue',
    messageParams: (params) => ({
      ...params,
      value: getNumericValueRepresentation(params.min),
    }),
  },
);

export const maxLength = withI18nMessage(validators.maxLength, {
  withArguments: true,
  messagePath: () => 'errorMessage.validation.isShorterOrEqualThanValue',
  messageParams: (params) => ({
    ...params,
    value: getNumericValueRepresentation(params.max),
  }),
});

export const minLength = withI18nMessage(validators.minLength, {
  withArguments: true,
  messagePath: () => 'errorMessage.validation.isLongerOrEqualThanValue',
  messageParams: (params) => ({
    ...params,
    value: getNumericValueRepresentation(params.min),
  }),
});

export const minContents = withI18nMessage(validators.minLength, {
  withArguments: true,
  messagePath: () => 'errorMessage.validation.isArrayLongerOrEqualThanValue',
  messageParams: (params) => ({
    ...params,
    value: getNumericValueRepresentation(params.min),
  }),
});

export const timeFormat = withI18nMessage(
  (timeFormatValue: CompanySettingTimeFormatEnum) => (val: string) => {
    if (!val) return true;
    const format =
      timeFormatValue === CompanySettingTimeFormatEnum._12
        ? RegExp(/^(0?[1-9]|1[012])(:[0-5]\d) [APap][mM]$/)
        : RegExp('^([0-1][0-9]|2[0-3]):([0-5][0-9])$');
    return format.test(val);
  },
  { withArguments: true },
);

export const email = withI18nMessage(validators.email);

export const isString = withI18nMessage(
  (val: string) => typeof val === 'string',
);

export const dateFormat = withI18nMessage((dateString: string) => {
  if (!dateString) return true;
  if (typeof dateString !== 'string') return false;

  // handle DD-MM-YYYY (DD/MM/YYYY) without omitted leading zeros
  const formatOne = RegExp(
    /^(0[1-9]|[12][0-9]|3[01])([\\/\\-])(0[1-9]|1[012])\2((19|20)\d\d)$/,
  );
  // handle YYYY-MM-DD (YYYY/MM/DD) without omitted leading zeros
  const formatTwo = RegExp(
    /^((19|20)\d\d)([\\/\\-])(0[1-9]|1[012])\3(0[1-9]|[12][0-9]|3[01])$/,
  );
  return [formatOne, formatTwo].some((format) => format.test(dateString));
});

export const isValidPhoneNumber = withI18nMessage((phoneNumber: string) => {
  if (!phoneNumber) return true;
  return checkPhoneNumber(phoneNumber);
});

export const isTruthy = withI18nMessage((val: unknown) => !!val);

export const passwordNotBlacklisted = withI18nMessage(
  (password: string) => !blacklist.includes(password),
);

export const sameAs = withI18nMessage(validators.sameAs, {
  withArguments: true,
  messagePath: () => 'errorMessage.validation.isSameAs',
  messageParams: (params) => ({
    ...params,
    compareTo: params.otherName ?? params.equalTo,
  }),
});
