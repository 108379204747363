
import { accessRoleApi } from '@/api';
import ShiftieLoading from '@/assets/ShiftieLoading.vue';
import Auth from '@/Auth';
import AddToHome from '@/components/AddToHome.vue';
import Button from '@/components/buttons/Button.vue';
import ActionDialog from '@/components/dialog/ActionDialog.vue';
import Dialog from '@/components/dialog/Dialog.vue';
import SuccessDialog from '@/components/dialog/SuccessDialog.vue';
import Default from '@/layouts/Default.vue';
import { LogoutReason } from '@/lib/auth/logoutReason';
import { AppWarningEnum } from '@/lib/enum/AppWarningEnum';
import { EventsEnum } from '@/lib/enum/events';
import { Icon } from '@/lib/enum/Icon';
import { StorageEnum } from '@/lib/enum/StorageEnum';
import MobileHeader from '@/lib/mobileHeader';
import { initialiseClarity } from '@/plugins/clarity';
import { initialiseGoogleAnalytics } from '@/plugins/ga';
import { setAdditionalSentryTags, setSentryUser } from '@/plugins/sentry';
import { websocketService } from '@/plugins/Websockets';
import { back, redirect } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  validateLocalStorageAccess,
} from '@/util/storageFunctions';
import { detectSafariOrPwa, viewport } from '@/util/windowFunctions';
import CompanyStatus from '@/views/auth/components/CompanyStatus.vue';
import CookiePrompt from '@/views/global/CookiePrompt.vue';
import BillingPackageDialog from '@/views/sign-up/components/BillingPackageDialog.vue';
import { Employee, User } from '../api/v1';

const defaultLayout = 'Default';
const mobileLayout = 'MobileLayout';

export default {
  name: 'RootApp',

  components: {
    SuccessDialog,
    BillingPackageDialog,
    CookiePrompt,
    ActionDialog,
    CompanyStatus,
    Dialog,
    AddToHome,
    ShiftieLoading,
    Default,
    Button,
    MobileLayout: () => import('@/layouts/MobileLayout.vue'),
    Empty: () => import('@/layouts/Empty.vue'),
  },

  data() {
    return {
      Icon,
      updatedRoles: null as { new: string; old: string } | null,
      packageDialog: {
        show: false,
        isRestore: false,
        billingOnly: false,
      },
    };
  },

  computed: {
    // Store state
    isImpersonationSession: (): boolean => store.state.isImpersonationSession,
    showCompanyStatusModal(): boolean {
      return store.state.showCompanyStatusModal && !this.isImpersonationSession;
    },
    permissionsUpdated: (): { old: number; new: number } | null =>
      store.state.permissionsUpdated,
    showPlanUpdateDialog: (): boolean => store.state.planUpdated,

    // Store getters
    loggedInEmployee: (): Employee | null => store.getters.loggedInEmployee,
    user: (): User | null => store.getters.user,
    globalLoading: (): boolean => store.getters['app/globalLoading'],
    forceReload: (): number => store.getters['app/forceReload'],

    isDesktop(): boolean {
      return viewport.lg;
    },

    layout(): string {
      if (this.isDesktop) {
        return `${
          this.$route.meta.desktopLayout ??
          this.$route.meta.layout ??
          defaultLayout
        }`;
      }
      return `${
        this.$route.meta.mobileLayout ?? this.$route.meta.layout ?? mobileLayout
      }`;
    },
    layoutProps() {
      return (
        this.$route.meta[
          this.isDesktop ? 'desktopLayoutProps' : 'mobileLayoutProps'
        ] ?? {}
      );
    },

    guestAccess(): boolean {
      return !!this.$route.meta.allowsGuest;
    },

    isLoading(): boolean {
      return (
        this.globalLoading || (!this.loggedInEmployee && !this.guestAccess)
      );
    },
  },

  watch: {
    async permissionsUpdated(roles) {
      await this.getUpdatedRoles(roles);
    },

    user: {
      deep: true,
      immediate: true,
      async handler(newUser) {
        const isImpersonationSession = await Auth.isImpersonationSession();
        store
          .dispatch(
            'setImpersonationSession',
            newUser && isImpersonationSession,
          )
          .then();

        // Watch for any changes in the users cookie preferences and update analytics
        // accordingly. If this is an impersonation session, then don't enable any analytics.
        this.toggleAnalytics(
          (!isImpersonationSession && newUser?.cookiesAccepted) ?? false,
        );

        // Configure Sentry to attach the currently logged-in user to all events.
        if (newUser) {
          setSentryUser(newUser);
        }
      },
    },

    $route(to, from) {
      if (to.path !== from.path) {
        // If route doesn't allow guest hide dialogs
        if (to.meta.allowsGuest) {
          if (this.packageDialog.show) {
            this.togglePackageDialog();
          }
          store.dispatch('appWarnings/resetWarnings');
          this.updatedRoles = null;
        }
        if (this.$store.state.reloadRequired) {
          this.$store.dispatch('reloadRequired', false).then(this.reload);
        }
      }
    },
  },

  beforeCreate() {
    MobileHeader.setBack(back);
  },

  mounted() {
    // If browser storage changes the company id could change so check the company context
    window.addEventListener('storage', this.checkCompanyContext);

    if (!!this.user && !this.user.emailVerifiedAt && !this.isLoading) {
      this.$store.dispatch('appWarnings/setWarning', AppWarningEnum.Unverified);
    }

    window.addEventListener('focus', this.appRefocus, false);
    this.$root.$on(EventsEnum.TogglePackageDialog, this.togglePackageDialog);
    this.$root.$on(EventsEnum.ToggleRestoreDialog, this.toggleRestoreDialog);
    this.$root.$on(EventsEnum.ToggleBillingDialog, this.toggleBillingDialog);

    // Configure additional tags for Sentry
    setAdditionalSentryTags({
      'app.version': process.env.VUE_APP_RELEASE_VERSION ?? null,
    });

    // Adds 'is-pwa' or 'is-safari' classes to div#app
    detectSafariOrPwa();
  },

  beforeDestroy() {
    window.removeEventListener('storage', this.checkCompanyContext);
    window.removeEventListener('focus', this.appRefocus);
    this.$root.$off(EventsEnum.TogglePackageDialog, this.togglePackageDialog);
    this.$root.$off(EventsEnum.ToggleBillingDialog, this.toggleBillingDialog);
    this.$root.$off(EventsEnum.ToggleRestoreDialog, this.toggleRestoreDialog);

    websocketService.closeSafely();
  },

  methods: {
    checkCompanyContext(event: StorageEvent) {
      if (event.key === StorageEnum.CompanyId) {
        const context = Number(getLocalStorageItem(StorageEnum.CompanyId));
        if (
          context !== this.$companyId &&
          ![routes.login.name, routes.logout.name].includes(this.$route.name)
        ) {
          redirect(
            routes.logout.route({
              multiLogin: true,
              redirect: this.$route.fullPath,
              ...(!validateLocalStorageAccess() && {
                reason: LogoutReason.NoCookieAccess,
              }),
            }),
          );
        }
      }
    },

    appRefocus() {
      // Compare the stored version with the latest known version of Shiftie. If the client is outdated then trigger the app
      // to reload on the next navigation change. This check is purely a fallback for when a client fails to be notified via
      // the normal WebSocket message, e.g. they are temporarily offline.
      fetch('/version.json', { cache: 'no-store' })
        .then((response) => response.json())
        .then(({ version }) => {
          const storedVersion = getLocalStorageItem(StorageEnum.Version);

          if (storedVersion !== version) {
            setLocalStorageItem(StorageEnum.Version, version);
            this.$store.dispatch('reloadRequired', true);
          }
        });

      // Re-initialise the socket connection. If it's still open this won't do anything, but it if's been closed
      // for any reason, this will establish a new connection.
      websocketService.init(this.$store);
    },

    reload() {
      window.location.reload();
    },

    async getUpdatedRoles(roleIds: { old: number; new: number }) {
      const { data: accessRoles } = await accessRoleApi.listAccessRoles({});
      this.updatedRoles = {
        new: accessRoles.find((r) => r.id === roleIds.new).name,
        old: accessRoles.find((r) => r.id === roleIds.old).name,
      };
    },

    toggleAnalytics(enabled: boolean) {
      // Initialise any analytics plugins here. We handle unloading separately
      // in AccountDetails.vue, which is only ever triggered when a user previously
      // opted into analytics but is now changing their mind.
      if (enabled) {
        initialiseGoogleAnalytics();
        initialiseClarity();
      }
    },

    togglePackageDialog() {
      this.packageDialog = {
        show: !this.packageDialog.show,
        isRestore: false,
        billingOnly: false,
      };
    },
    toggleBillingDialog() {
      this.packageDialog = {
        show: !this.packageDialog.show,
        isRestore: false,
        billingOnly: true,
      };
    },
    toggleRestoreDialog() {
      this.packageDialog = {
        show: !this.packageDialog.show,
        isRestore: true,
        billingOnly: true,
      };
    },
  },
};
