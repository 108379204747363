/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DeletePushSubscriptionData
 */
export interface DeletePushSubscriptionData {
    /**
     * 
     * @type {string}
     * @memberof DeletePushSubscriptionData
     */
    endpoint: string;
}

/**
 * Check if a given object implements the DeletePushSubscriptionData interface.
 */
export function instanceOfDeletePushSubscriptionData(value: object): boolean {
    if (!('endpoint' in value)) return false;
    return true;
}

export function DeletePushSubscriptionDataFromJSON(json: any): DeletePushSubscriptionData {
    return DeletePushSubscriptionDataFromJSONTyped(json, false);
}

export function DeletePushSubscriptionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): DeletePushSubscriptionData {
    if (json == null) {
        return json;
    }
    return {
        
        'endpoint': json['endpoint'],
    };
}

export function DeletePushSubscriptionDataToJSON(value?: DeletePushSubscriptionData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'endpoint': value['endpoint'],
    };
}

