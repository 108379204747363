import spacetime from 'spacetime';
import { ScheduledBreak } from '../../../api/v1';

export const tooEarlyToStartScheduledBreak = (
  scheduledBreak: ScheduledBreak | null,
  now: Date,
  timezone: string,
): boolean =>
  scheduledBreak?.startsAt
    ? spacetime(now, timezone).isBefore(scheduledBreak.startsAt)
    : false;
