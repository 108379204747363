/**
 * CLONE OF: src/lib/realtime/realtimeFunctions.ts
 *
 * DIFF:
 *   * Removed deprecated stuff
 *   * Filtering functionality moved to realtimeFiltering.ts
 *   * Removed requirement to specify a Vue component, added `lifetime` support instead
 *   * Returns already reactive value (will work for both vue versions)
 *     to allow using realtimeQuery result without wrapping it in reactive hook,
 *     if it's wrapped anyway, it's not an error.
 */
import { ConditionBuilder } from '@/lib/realtime/weak/ConditionBuilder';
import { entityConfig } from '@/lib/realtime/weak/entityConfig';
import {
  EntityInstance,
  EntityInterfaceMap,
  EntityName,
  GetParameterType,
  WSEntityName,
} from '@/lib/realtime/weak/realtimeTypes';
import {
  ReactiveConstructor,
  WeakEntityRepository,
  WeakEntityRepositoryResponse,
  WeakEntityRepositoryUpdateKind,
  WindowExtensionTag,
} from '@/lib/realtime/weak/WeakEntityRepository';
import { websocketService } from '@/plugins/Websockets';
import { makeCancellable } from '@/util/promiseFunctions';
import { reactive, UnwrapRef, watch } from 'vue';
import { ListEventEntitiesResponseDataEnum } from '../../../../api/v1';

export type RealtimeQueryLifetime = {
  apply: (ref: unknown) => void;
  count: () => number;
  clear: () => void;
};

export type FetchOptions<T extends EntityName> = {
  lifetime?: RealtimeQueryLifetime;
  prevValue?: EntityInstance<T>[] | null; // Will be used as prefetched data, also isLoaded true will be set
  forceRefetch?: boolean; // Will trigger force data refetch
};

export const createRealtimeQueryLifetime = (): RealtimeQueryLifetime => {
  const refs = [];
  return {
    apply: (ref: unknown) => refs.push(ref),
    count: () => refs.length,
    clear: () => refs.splice(0, refs.length),
  };
};

const repositoryDebugTag = 'default';
export const weakEntityRepository = new WeakEntityRepository(
  entityConfig,
  websocketService,
  reactive as ReactiveConstructor,
  repositoryDebugTag,
);
declare global {
  interface Window
    extends WindowExtensionTag<
      typeof entityConfig,
      typeof repositoryDebugTag
    > {}
}

export class RealtimeQuery<T extends EntityName> extends ConditionBuilder<
  EntityInstance<T>
> {
  readonly #entity = undefined as T;

  readonly #params = {} as NonNullable<GetParameterType<T>>;

  constructor(entity: T, params: GetParameterType<T>) {
    super();
    this.#entity = entity;
    this.#params = params;
    // If params exist, then we need to attach them as a where filter
    Object.keys(params).forEach((key) => {
      this.where(key as keyof EntityInterfaceMap[T], 'eq', params[key]);
    });
  }

  fetch(options: FetchOptions<T> = {}): WeakEntityRepositoryResponse<T> {
    const result = weakEntityRepository.subscribe(
      this.#entity,
      this.conditions,
      this.#params,
    );

    if (options.lifetime) {
      options.lifetime.apply(result);
    }

    if (options.forceRefetch && !result.isLoading) {
      result.refetch();
    }

    if (options.prevValue && !result.isLoaded) {
      result.data = options.prevValue;
      result.isLoaded = true;
    }

    return result;
  }

  empty(loading: boolean = true): WeakEntityRepositoryResponse<T> {
    return reactive({
      entity: this.#entity,
      refetch: () => makeCancellable(Promise.resolve([])),
      data: [] as EntityInstance<T>[],
      promise: null,
      error: null,
      isLoading: loading,
      isLoaded: true,
      isFetching: false,
    }) as WeakEntityRepositoryResponse<T>;
  }
}

export const realtimeQuery = <T extends EntityName>(
  entityName: T,
  params: GetParameterType<T> = {} as GetParameterType<T>,
): RealtimeQuery<T> => {
  return new RealtimeQuery(entityName, params);
};

export interface SingleWeakEntityRepositoryResponse<T extends EntityName>
  extends Omit<WeakEntityRepositoryResponse<T>, 'data'> {
  data: EntityInstance<T> | null;
}

export const singleEntityRealtimeQuery = <T extends EntityName>(
  entityName: T,
  params: GetParameterType<T> = {} as GetParameterType<T>,
  options: FetchOptions<T> = {},
): SingleWeakEntityRepositoryResponse<T> => {
  const query: WeakEntityRepositoryResponse<T> = realtimeQuery(
    entityName,
    params,
  ).fetch(options);

  const value = reactive<SingleWeakEntityRepositoryResponse<T>>({
    entity: query.entity,
    data: query.data[0] || null,
    error: query.error,
    isLoading: query.isLoading,
    isLoaded: query.isLoaded,
    isFetching: query.isFetching,
    refetch: () => query.refetch(),
    promise: query.promise,
  });

  watch(
    query,
    () => {
      value.data = (query.data[0] ?? null) as UnwrapRef<
        EntityInstance<T>
      > | null;
      value.isLoading = query.isLoading;
      value.isLoaded = query.isLoaded;
      value.isFetching = query.isFetching;
      value.promise = query.promise;
      value.error = query.error;
    },
    {
      deep: true,
      immediate: true,
    },
  );

  return value as SingleWeakEntityRepositoryResponse<T>;
};

export const getEmptySingleEntityRealtimeQuery = <T extends EntityName>(
  entityName: T,
  loading: boolean = true,
) => {
  return reactive({
    entity: null,
    data: null,
    isLoading: loading,
    isLoaded: false,
    refetch: () => makeCancellable(Promise.resolve([])),
    promise: null,
  }) as SingleWeakEntityRepositoryResponse<T>;
};

export const realtimeFindEntity = async <T extends WSEntityName>(
  entityName: T,
  id: number,
): Promise<EntityInstance<T> | undefined> => {
  const query = realtimeQuery(entityName).where('id', 'eq', id).fetch();
  await query.promise;
  return query.data[0];
};

export const realtimeQueryInstantUpdate = <
  T extends EntityName & ListEventEntitiesResponseDataEnum,
>(
  entity: T,
  kind: WeakEntityRepositoryUpdateKind,
  data: EntityInstance<T>,
) => weakEntityRepository.dispatchUpdate(kind, entity, data);
