/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcceptShiftSwapResponse,
  ApproveShiftSwapResponse,
  CancelShiftSwapResponse,
  CreateShiftSwapData,
  CreateShiftSwapResponse,
  DeclineShiftSwapResponse,
  ListShiftSwapsResponse,
  RejectShiftSwapResponse,
  ShowShiftSwapResponse,
} from '../models/index';
import {
    AcceptShiftSwapResponseFromJSON,
    AcceptShiftSwapResponseToJSON,
    ApproveShiftSwapResponseFromJSON,
    ApproveShiftSwapResponseToJSON,
    CancelShiftSwapResponseFromJSON,
    CancelShiftSwapResponseToJSON,
    CreateShiftSwapDataFromJSON,
    CreateShiftSwapDataToJSON,
    CreateShiftSwapResponseFromJSON,
    CreateShiftSwapResponseToJSON,
    DeclineShiftSwapResponseFromJSON,
    DeclineShiftSwapResponseToJSON,
    ListShiftSwapsResponseFromJSON,
    ListShiftSwapsResponseToJSON,
    RejectShiftSwapResponseFromJSON,
    RejectShiftSwapResponseToJSON,
    ShowShiftSwapResponseFromJSON,
    ShowShiftSwapResponseToJSON,
} from '../models/index';

export interface AcceptShiftSwapRequest {
    id: number;
    _with?: Array<string>;
}

export interface ApproveShiftSwapRequest {
    id: number;
    _with?: Array<string>;
}

export interface CancelShiftSwapRequest {
    id: number;
}

export interface CreateShiftSwapRequest {
    _with?: Array<string>;
    createShiftSwapData?: CreateShiftSwapData;
}

export interface DeclineShiftSwapRequest {
    id: number;
    _with?: Array<string>;
}

export interface ListShiftSwapsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface RejectShiftSwapRequest {
    id: number;
    _with?: Array<string>;
}

export interface ShowShiftSwapRequest {
    id: number;
    _with?: Array<string>;
}

/**
 * 
 */
export class ShiftSwapApi extends runtime.BaseAPI {

    /**
     * Accept Shift Swap
     * Accept Shift Swap
     */
    async acceptShiftSwapRaw(requestParameters: AcceptShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<AcceptShiftSwapResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling acceptShiftSwap().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-swap.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-swaps/{id}/accept`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AcceptShiftSwapResponseFromJSON(jsonValue));
    }

    /**
     * Accept Shift Swap
     * Accept Shift Swap
     */
    async acceptShiftSwap(requestParameters: AcceptShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<AcceptShiftSwapResponse> {
        const response = await this.acceptShiftSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Approve Shift Swap
     * Approve Shift Swap
     */
    async approveShiftSwapRaw(requestParameters: ApproveShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApproveShiftSwapResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling approveShiftSwap().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-swap.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-swaps/{id}/approve`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApproveShiftSwapResponseFromJSON(jsonValue));
    }

    /**
     * Approve Shift Swap
     * Approve Shift Swap
     */
    async approveShiftSwap(requestParameters: ApproveShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApproveShiftSwapResponse> {
        const response = await this.approveShiftSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Cancel a single Shift Swap
     * Cancel Shift Swap
     */
    async cancelShiftSwapRaw(requestParameters: CancelShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CancelShiftSwapResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling cancelShiftSwap().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-swap.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-swaps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CancelShiftSwapResponseFromJSON(jsonValue));
    }

    /**
     * Cancel a single Shift Swap
     * Cancel Shift Swap
     */
    async cancelShiftSwap(requestParameters: CancelShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CancelShiftSwapResponse> {
        const response = await this.cancelShiftSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a new ShiftSwap
     * Create Shift Swap
     */
    async createShiftSwapRaw(requestParameters: CreateShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateShiftSwapResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-swap.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-swaps`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateShiftSwapDataToJSON(requestParameters['createShiftSwapData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateShiftSwapResponseFromJSON(jsonValue));
    }

    /**
     * Create a new ShiftSwap
     * Create Shift Swap
     */
    async createShiftSwap(requestParameters: CreateShiftSwapRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateShiftSwapResponse> {
        const response = await this.createShiftSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Decline Shift Swap
     * Decline Shift Swap
     */
    async declineShiftSwapRaw(requestParameters: DeclineShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<DeclineShiftSwapResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling declineShiftSwap().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-swap.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-swaps/{id}/decline`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DeclineShiftSwapResponseFromJSON(jsonValue));
    }

    /**
     * Decline Shift Swap
     * Decline Shift Swap
     */
    async declineShiftSwap(requestParameters: DeclineShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<DeclineShiftSwapResponse> {
        const response = await this.declineShiftSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List all Shift Swaps
     * List Shift Swaps
     */
    async listShiftSwapsRaw(requestParameters: ListShiftSwapsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftSwapsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-swap.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-swaps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftSwapsResponseFromJSON(jsonValue));
    }

    /**
     * List all Shift Swaps
     * List Shift Swaps
     */
    async listShiftSwaps(requestParameters: ListShiftSwapsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftSwapsResponse> {
        const response = await this.listShiftSwapsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Reject Shift Swap
     * Reject Shift Swap
     */
    async rejectShiftSwapRaw(requestParameters: RejectShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RejectShiftSwapResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling rejectShiftSwap().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-swap.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-swaps/{id}/reject`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RejectShiftSwapResponseFromJSON(jsonValue));
    }

    /**
     * Reject Shift Swap
     * Reject Shift Swap
     */
    async rejectShiftSwap(requestParameters: RejectShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RejectShiftSwapResponse> {
        const response = await this.rejectShiftSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show a single Shift Swap
     * Show Shift Swap
     */
    async showShiftSwapRaw(requestParameters: ShowShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowShiftSwapResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showShiftSwap().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift-swap.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shift-swaps/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowShiftSwapResponseFromJSON(jsonValue));
    }

    /**
     * Show a single Shift Swap
     * Show Shift Swap
     */
    async showShiftSwap(requestParameters: ShowShiftSwapRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowShiftSwapResponse> {
        const response = await this.showShiftSwapRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
