import { authApiFactory } from '@/lib/api/factory';
import { decodeJwtToken } from '@/util/authFunctions';
import { Mutex } from 'async-mutex';
import spacetime from 'spacetime';
import { Spacetime } from 'spacetime/types/types.d';
import {
  AuthenticationApi,
  AuthenticationData,
  AuthenticationDataMeta,
  AuthenticationDataMetaSessionTypeEnum,
} from '../api/auth';

class Auth {
  token: string = null;

  tokenExpiry: 0;

  scopes: string[] = [];

  meta: AuthenticationDataMeta = null;

  mutex: Mutex;

  constructor() {
    this.mutex = new Mutex();
  }

  resetCredentials() {
    this.token = null;
    this.tokenExpiry = 0;
    this.scopes = [];
    this.meta = null;
  }

  setCredentials(data: AuthenticationData) {
    const { token } = data;
    const tokenData = decodeJwtToken(token);
    this.token = token;
    this.tokenExpiry = tokenData.exp;
    this.scopes = tokenData.scopes;
    this.meta = data.meta;
  }

  async getToken(): Promise<string> {
    await this.mutex.waitForUnlock();

    return this.token;
  }

  async isAuthenticated(): Promise<boolean> {
    await this.mutex.waitForUnlock();

    return this.token !== null;
  }

  async isImpersonationSession(): Promise<boolean> {
    await this.mutex.waitForUnlock();

    return (
      this.meta?.sessionType ===
      AuthenticationDataMetaSessionTypeEnum.Impersonate
    );
  }

  async tokenExpiresAfter(after: Spacetime): Promise<boolean> {
    await this.mutex.waitForUnlock();

    return after.isAfter(spacetime(this.tokenExpiry * 1000));
  }

  async refreshCredentials(): Promise<void> {
    // If another process / event has already called this method, then we'll
    // block the called until the first call to this method has completed.
    if (this.mutex.isLocked()) {
      await this.mutex.waitForUnlock();
      return;
    }

    this.mutex
      .acquire()
      .then(async () => {
        const { data } = await authApiFactory
          .create(AuthenticationApi, { displayErrorsAsToasts: false })
          .refreshAccessToken({ refreshData: {} });
        this.setCredentials(data);
      })
      .finally(() => {
        this.mutex.release();
      });
  }
}

export default new Auth();
