import { employeeCurrent } from '@/lib/employee/employeeFunctions';
import { belongsToEmployee } from '@/lib/filters/employeeFilters';
import {
  companyCanAccess,
  companyHasFeatureFlag,
  Feature,
} from '@/lib/permission/companyAccessFeatures';
import store from '@/store';
import { hasMatchingId } from '@/util/entityFunctions';
import {
  isCurrentEmployee,
  isProtectedEmployee,
  Permission,
  userHasPermission,
} from '@/util/permissionFunctions';
import { CompanyFeatureFlagsEnum, Employee, Shift } from '../../../api/v1';
import { clockInFromPersonalDevicesAtLocationFilter } from '../location/locationFilters';

// grouped by entity ordered alphabetical
export const userCan = {
  // Billing
  manageBilling: (): boolean => userHasPermission(Permission.ManageBilling),
  // Time Clock
  clockInEmployee: (employee: Employee): boolean =>
    companyCanAccess(Feature.ClockInOut) &&
    store.getters['locations/clockInEnabled'] &&
    (userHasPermission(Permission.ManageTimesheets) ||
      userCan.manageEmployee(employee.id)),
  clockInSelf: (): boolean =>
    companyCanAccess(Feature.ClockInOut) &&
    store.getters['locations/clockInEnabled'] &&
    store.getters[
      'locations/locationsWithClockInFromPersonalDevicesEnabled'
    ].some(
      clockInFromPersonalDevicesAtLocationFilter(
        store.getters.loggedInEmployee,
      ),
    ),
  // Documents
  manageDocuments: (): boolean =>
    companyCanAccess(Feature.HrManagement) &&
    userHasPermission(Permission.ManageDocuments),
  viewDocuments: (): boolean => companyCanAccess(Feature.HrManagement),
  // Employee
  deleteEmployee: (employee: Employee): boolean => {
    return (
      !isCurrentEmployee(employee) &&
      !isProtectedEmployee(employee, store.state.settings.companySettings) &&
      userCan.manageEmployee(employee.id)
    );
  },
  manageEmployee: (employeeId: number): boolean =>
    // Everyone with ManageTeam can manage any team members.
    // Everyone can manage any team members that they are the line manager for.
    userHasPermission(Permission.ManageTeam) ||
    store.state.managedEmployeeIds.includes(employeeId),
  viewEmployeeClockingProfile: (employee: Employee): boolean =>
    employee.userId &&
    (isCurrentEmployee(employee) ||
      userHasPermission(Permission.ManageEmployeeClocking)),
  managePay: (): boolean =>
    companyCanAccess(Feature.HrManagement) &&
    userHasPermission(Permission.ManagePayPeriods),
  // Integrations
  viewPrebookedIntegration: (): boolean =>
    companyCanAccess(Feature.Integrations) && userCan.manageShifts(),
  // Job Roles
  manageJobRoles: (): boolean => userHasPermission(Permission.ManageJobRoles),
  // Leave
  approveLeave: (employee: Employee): boolean =>
    companyCanAccess(Feature.Leave) &&
    (userHasPermission(Permission.ManageLeave) ||
      employee.lineManagerId === store.getters.loggedInEmployee.id ||
      employee.leaveApproverId === store.getters.loggedInEmployee.id ||
      // Leave-takers without an approver mean they effectively can manage their own leave
      (!employee.leaveApproverId &&
        store.getters.loggedInEmployee.id === employee.id)),
  addLeave: (employee: Employee): boolean =>
    companyCanAccess(Feature.Leave) &&
    employeeCurrent(employee) &&
    !!employee.leavePolicyId &&
    store.state.settings.leaveSettings.enableLeaveRequests &&
    // is themselves and they can request leave
    ((isCurrentEmployee(employee) && employee.canRequestLeave) ||
      // or someone else where they have the necessary permissions
      (!isCurrentEmployee(employee) && userCan.manageEmployeeLeave(employee))),
  manageLeave: (): boolean =>
    companyCanAccess(Feature.Leave) &&
    store.state.settings.leaveSettings.enableLeaveRequests &&
    (userHasPermission(Permission.ManageLeave) ||
      !!store.state.managedEmployeeIds.length),
  manageEmployeeLeave: (employee: Employee): boolean =>
    companyCanAccess(Feature.Leave) &&
    (userHasPermission(Permission.ManageLeave) ||
      employee.lineManagerId === store.getters.loggedInEmployee.id ||
      employee.leaveApproverId === store.getters.loggedInEmployee.id),
  viewLeave: (employeeId: number): boolean =>
    companyCanAccess(Feature.Leave) &&
    store.state.settings.leaveSettings.enableLeaveRequests &&
    (hasMatchingId({ id: employeeId }, store.getters.loggedInEmployee.id) ||
      userHasPermission(Permission.ManageLeave) ||
      userCan.manageEmployee(employeeId)),
  // Locations
  manageLocations: (): boolean =>
    userHasPermission(Permission.ManageLocations) &&
    companyCanAccess(Feature.MultiVenue),
  // Messaging
  sendMessage: (): boolean =>
    userHasPermission(Permission.SendEmployeeMessage) &&
    companyCanAccess(Feature.TeamMessaging),
  // Profile
  viewProfile: (employeeId: number): boolean =>
    employeeId === store.getters.loggedInEmployee.id ||
    userHasPermission(Permission.ManageTeam) ||
    store.state.managedEmployeeIds.includes(employeeId),
  // Requests
  viewRequests: (): boolean =>
    // Keep up to date with tabs on requests page
    userCan.manageLeave() ||
    store.getters['requests/leaveApprovalEmployees'].length ||
    userCan.manageShifts() ||
    store.state.settings.scheduleSettings.shiftSwappingEnabled,
  // Settings
  manageDocumentSettings: (): boolean =>
    companyCanAccess(Feature.HrManagement) &&
    userHasPermission(Permission.ManageSettings),
  manageIntegrationSettings: (): boolean =>
    companyCanAccess(Feature.Integrations) &&
    userHasPermission(Permission.ManageIntegrations),
  manageLeaveSettings: (): boolean =>
    companyCanAccess(Feature.Leave) &&
    userHasPermission(Permission.ManageSettings) &&
    userHasPermission(Permission.ManageLeave),
  managePayrollSettings: (): boolean =>
    companyCanAccess(Feature.Timesheets) &&
    userHasPermission(Permission.ManageSettings),
  manageSettings: (): boolean => userHasPermission(Permission.ManageSettings),
  manageTeamSettings: (): boolean =>
    userHasPermission(Permission.ManageTeam) ||
    userHasPermission(Permission.ManageSettings),
  manageTimesheetSettings: (): boolean =>
    companyCanAccess(Feature.ClockInOut) &&
    userHasPermission(Permission.ManageSettings),
  viewSettings: (): boolean =>
    [
      Permission.ManageShifts,
      Permission.ManageLeave,
      Permission.ManageBilling,
      Permission.ManageSettings,
      Permission.ManageTimesheets,
      Permission.ManageTeam,
    ].some(userHasPermission),
  // Shifts
  manageShifts: (): boolean => userHasPermission(Permission.ManageShifts),
  viewShiftDetails: (shift: Shift): boolean =>
    userHasPermission(Permission.ManageShifts) ||
    belongsToEmployee(store.getters.loggedInEmployee.id)(shift),
  // Absence
  addOrEditAbsence: (targetEmployeeId: number = null): boolean => {
    // Check that the Absence feature is enabled for this company
    if (!companyCanAccess(Feature.Absence)) {
      return false;
    }
    // If they have manage leave permission, they always can
    if (userHasPermission(Permission.ManageLeave)) {
      return true;
    }
    // If they're adding absence for themselves
    if (targetEmployeeId === store.getters.loggedInEmployee.id) {
      return store.state.settings.companySettings.employeesCanLogOwnAbsences;
    }
    // If they're managing absence for someone else, check that they manage them
    return store.state.managedEmployeeIds.includes(targetEmployeeId);
  },
  deleteAbsence: (targetEmployeeId: number = null): boolean => {
    // Check that the Absence feature is enabled for this company
    if (!companyCanAccess(Feature.Absence)) {
      return false;
    }
    // If they have manage leave permission, they always can
    if (userHasPermission(Permission.ManageLeave)) {
      return true;
    }
    // Otherwise, they have to be in the management hierarchy for this individual
    return store.state.managedEmployeeIds.includes(targetEmployeeId);
  },
  viewAbsence: (): boolean => companyCanAccess(Feature.Absence),
  // Schedule
  manageSchedules: (): boolean => userHasPermission(Permission.ManageSchedules),
  // Team
  manageTeam: (): boolean => userHasPermission(Permission.ManageTeam),
  manageTeamPermissions: (): boolean =>
    userHasPermission(Permission.ManageTeamPermissions),
  hasATeam: (): boolean =>
    userHasPermission(Permission.ManageTeam) ||
    !!store.state.managedEmployeeIds.length,
  // Timesheets
  exportTimesheets: (): boolean =>
    userHasPermission(Permission.ManageTimesheets) ||
    userHasPermission(Permission.ManagePayPeriods),
  manageTimesheets: (): boolean =>
    userHasPermission(Permission.ManageTimesheets),
  editTimeEntries: (): boolean =>
    userHasPermission(Permission.ManageTimesheets) ||
    store.state.settings.clockingSettings.employeesCanEditTimeEntries,
  updateEmployeeTimesheets: (employeeId: number): boolean =>
    userHasPermission(Permission.ManageTimesheets) ||
    store.state.managedEmployeeIds.includes(employeeId) ||
    store.state.settings.clockingSettings.employeesCanEditTimeEntries,
  // Unavailability
  addOrEditUnavailability: (employeeId: number): boolean =>
    store.state.settings.leaveSettings.employeesCanLogOwnUnavailability ||
    userHasPermission(Permission.ManageLeave) ||
    store.state.managedEmployeeIds.includes(employeeId),
  viewUnavailability: (employeeId: number): boolean =>
    hasMatchingId({ id: employeeId }, store.getters.loggedInEmployee.id) ||
    userHasPermission(Permission.ManageLeave) ||
    store.state.managedEmployeeIds.includes(employeeId),
  manageProtectedSettings: (): boolean =>
    userHasPermission(Permission.ManageProtectedSettings),
  seeWhoElseIsWorking: (): boolean =>
    userHasPermission(Permission.ManageShifts) ||
    store.state.settings.scheduleSettings.employeesCanViewAllShifts,
  manageCompanyOnboarding: (): boolean =>
    userHasPermission(Permission.ManageCompanyOnboarding),
  // Clock In Portals
  manageClockInPortals: (): boolean =>
    companyCanAccess(Feature.ClockInOut) &&
    userHasPermission(Permission.ManageClockInPortals),
  viewShiftCosts: (): boolean => userHasPermission(Permission.ViewShiftCosts),
  // Reports
  viewMessageLogs: (): boolean =>
    userHasPermission(Permission.ManageTeam) &&
    companyCanAccess(Feature.Reports) &&
    companyCanAccess(Feature.TeamMessaging),
  viewShiftCostReports: (): boolean =>
    userCan.viewShiftCosts() &&
    companyHasFeatureFlag(
      store.getters.currentCompany,
      CompanyFeatureFlagsEnum.ShiftCostsReport,
    ) &&
    companyCanAccess(Feature.Reports),
  viewReports: (): boolean =>
    [userCan.viewShiftCostReports(), userCan.viewMessageLogs()].some(Boolean),
};
