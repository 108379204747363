import { entityConfig } from '@/lib/realtime/entityConfig';
import { entityConfig as weakEntityConfig } from '@/lib/realtime/weak/entityConfig';
import { hardCodedHandlers } from '@/lib/store/otherEntities';
import { realtimeEntities } from '@/lib/store/realtimeEntities';
import {
  SocketService,
  SocketServiceEventType,
  WebsocketControlEvent,
} from '@/plugins/Websockets';
import { Store } from 'vuex';

export const initialiseWebsocket = (
  store: Store<any>,
  socket: SocketService,
) => {
  socket.init(store);

  // Register all our entity handlers
  Object.keys(hardCodedHandlers).forEach((key) => {
    socket.registerHandler(key, `hardCoded-${key}`, hardCodedHandlers[key]);
  });

  Object.keys(realtimeEntities).forEach((key) => {
    socket.registerHandler(
      key,
      `realtime-${key}`,
      realtimeEntities[key].handler,
    );
  });

  socket.addEventListener(SocketServiceEventType.CONTROL, ({ event }) => {
    if (event === WebsocketControlEvent.NewDeployment) {
      store.dispatch('reloadRequired', true);
    } else if (event === WebsocketControlEvent.ClientAuthenticationSuccessful) {
      socket.subscribeToChannels([
        ...socket.getChannels(),
        ...Array.from(
          new Set([
            ...Object.keys(entityConfig),
            ...Object.keys(weakEntityConfig),
          ]),
        ),
      ]);
    }
  });
};
