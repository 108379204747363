/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BillingSubscription } from './BillingSubscription';
import {
    BillingSubscriptionFromJSON,
    BillingSubscriptionFromJSONTyped,
    BillingSubscriptionToJSON,
} from './BillingSubscription';
import type { CompanyOnboarding } from './CompanyOnboarding';
import {
    CompanyOnboardingFromJSON,
    CompanyOnboardingFromJSONTyped,
    CompanyOnboardingToJSON,
} from './CompanyOnboarding';
import type { CompanySetting } from './CompanySetting';
import {
    CompanySettingFromJSON,
    CompanySettingFromJSONTyped,
    CompanySettingToJSON,
} from './CompanySetting';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * Defines some basic information about a company
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    status: CompanyStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    ref: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    address: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    locale: string;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    timezone: string;
    /**
     * A series of national/public holidays that apply to this company
     * @type {number}
     * @memberof Company
     */
    holidayRegionId: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    latitude: number | null;
    /**
     * 
     * @type {number}
     * @memberof Company
     */
    longitude: number | null;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Company
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {Array<Location>}
     * @memberof Company
     */
    locations?: Array<Location>;
    /**
     * 
     * @type {CompanySetting}
     * @memberof Company
     */
    companySetting?: CompanySetting;
    /**
     * 
     * @type {CompanyOnboarding}
     * @memberof Company
     */
    companyOnboarding?: CompanyOnboarding;
    /**
     * 
     * @type {BillingSubscription}
     * @memberof Company
     */
    latestSubscription: BillingSubscription;
    /**
     * A list of feature flags enabled for the cmopany.
     * @type {Array<string>}
     * @memberof Company
     */
    featureFlags: Array<CompanyFeatureFlagsEnum>;
}

/**
* @export
* @enum {string}
*/
export enum CompanyStatusEnum {
    Active = 'Active',
    Locked = 'Locked',
    Suspended = 'Suspended',
    Closed = 'Closed'
}
/**
* @export
* @enum {string}
*/
export enum CompanyFeatureFlagsEnum {
    LockPayPeriods = 'Lock Pay Periods',
    ScheduleTimeOffView = 'Schedule Time Off View',
    ShiftCostsReport = 'Shift Costs Report',
    DigiTicketsIntegrationPhase3 = 'DigiTickets Integration Phase 3'
}


/**
 * Check if a given object implements the Company interface.
 */
export function instanceOfCompany(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('name' in value)) return false;
    if (!('status' in value)) return false;
    if (!('ref' in value)) return false;
    if (!('address' in value)) return false;
    if (!('locale' in value)) return false;
    if (!('timezone' in value)) return false;
    if (!('holidayRegionId' in value)) return false;
    if (!('latitude' in value)) return false;
    if (!('longitude' in value)) return false;
    if (!('createdAt' in value)) return false;
    if (!('updatedAt' in value)) return false;
    if (!('latestSubscription' in value)) return false;
    if (!('featureFlags' in value)) return false;
    return true;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'ref': json['ref'],
        'address': json['address'],
        'locale': json['locale'],
        'timezone': json['timezone'],
        'holidayRegionId': json['holidayRegionId'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'locations': json['locations'] == null ? undefined : ((json['locations'] as Array<any>).map(LocationFromJSON)),
        'companySetting': json['companySetting'] == null ? undefined : CompanySettingFromJSON(json['companySetting']),
        'companyOnboarding': json['companyOnboarding'] == null ? undefined : CompanyOnboardingFromJSON(json['companyOnboarding']),
        'latestSubscription': BillingSubscriptionFromJSON(json['latestSubscription']),
        'featureFlags': json['featureFlags'],
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'name': value['name'],
        'status': value['status'],
        'ref': value['ref'],
        'address': value['address'],
        'locale': value['locale'],
        'timezone': value['timezone'],
        'holidayRegionId': value['holidayRegionId'],
        'latitude': value['latitude'],
        'longitude': value['longitude'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'locations': value['locations'] == null ? undefined : ((value['locations'] as Array<any>).map(LocationToJSON)),
        'companySetting': CompanySettingToJSON(value['companySetting']),
        'companyOnboarding': CompanyOnboardingToJSON(value['companyOnboarding']),
        'latestSubscription': BillingSubscriptionToJSON(value['latestSubscription']),
        'featureFlags': value['featureFlags'],
    };
}

