import Auth from '@/Auth';
import { AppWarningEnum } from '@/lib/enum/AppWarningEnum';
import { CustomEventEmitter } from '@/lib/helpers/CustomEventEmitter';
import { getGlobal } from '@/util/globalFunctions';
import spacetime from 'spacetime';
import { Store } from 'vuex';
import { ListEventEntitiesResponseDataEnum } from '../../api/v1';

/**
 * Used by JSON.parse to turn date strings into native Date objects.
 */
const dateReviver = (key, value) => {
  const dateFormat = RegExp(
    '^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(\\.[0-9]+)?(Z)?$',
  );

  if (typeof value === 'string' && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
};

export const WebsocketChannel = ListEventEntitiesResponseDataEnum;

export enum WebsocketControlEvent {
  NewDeployment = 'CE_DEPLOYMENT',
  ClientAuthenticationSuccessful = 'CE_AUTH_SUCCESS',
}

export type WebsocketMessageHandler = (
  // eslint-disable-next-line no-use-before-define
  service: SocketService,
  entityName: ListEventEntitiesResponseDataEnum,
  eventName: string,
  data,
) => any;

export type SocketOperationKind = 'Created' | 'Deleted' | 'Updated';

export enum SocketServiceEventType {
  ENTITY = 'ENTITY',
  BATCH_ENTITY = 'BATCH_ENTITY',
  CONTROL = 'CONTROL',
}

export type EntityEventType<
  T extends ListEventEntitiesResponseDataEnum = ListEventEntitiesResponseDataEnum,
> = `ENTITY:${T}`;
export const entityEventType = <T extends ListEventEntitiesResponseDataEnum>(
  entity: T,
): EntityEventType<T> => `ENTITY:${entity}`;

export type BatchEntityEventType<
  T extends ListEventEntitiesResponseDataEnum = ListEventEntitiesResponseDataEnum,
> = `BATCH_ENTITY:${T}`;
export const batchEntityEventType = <
  T extends ListEventEntitiesResponseDataEnum,
>(
  entity: T,
): BatchEntityEventType<T> => `BATCH_ENTITY:${entity}`;

export type EntityEvent = {
  entity: ListEventEntitiesResponseDataEnum & string;
} & (
  | {
      kind: 'Deleted';
      data: number | string | unknown;
    }
  | {
      kind: 'Created' | 'Updated';
      data: unknown;
    }
);

export type BatchEntityEvent = {
  entity: ListEventEntitiesResponseDataEnum;
  kind: SocketOperationKind;
  data: number[];
};

export class SocketService extends CustomEventEmitter<
  {
    [SocketServiceEventType.ENTITY]: EntityEvent;
    [SocketServiceEventType.BATCH_ENTITY]: BatchEntityEvent;
    [SocketServiceEventType.CONTROL]: {
      event: WebsocketControlEvent;
    };
  } & { [k in EntityEventType]: EntityEvent } & {
    [k in BatchEntityEventType]: BatchEntityEvent;
  }
> {
  store: Store<any>;

  webSocket: WebSocket | null = null;

  sendAttempts: number = 0;

  sendTimeout: number = 50;

  reconnectAttempts: number = 0;

  isDisconnected: boolean = false;

  /**
   * A control property which is only ever set to 'true' when a reconnection timeout is in progress.
   */
  reconnecting: boolean = false;

  reconnectTimeout: number = 250;

  pongIntervals: number[] = [];

  reconnectTimeouts: number[] = [];

  // TODO: remove old handlers functionality in prior to CustomEventEmitter
  //  when EntityRepository will be deleted
  handlers: Record<string, Record<string, WebsocketMessageHandler>> = {};

  batchHandlers: Record<string, Record<string, WebsocketMessageHandler>> = {};

  /**
   * Init handles opening the WebSocket connection, and should only be called once upon an application load.
   */
  public init(store) {
    this.store = store;

    if (!this.webSocket) {
      this.openSocket();
    }
  }

  public registerHandler(
    entityName: string,
    key: string,
    handler: WebsocketMessageHandler,
  ) {
    this.handlers[entityName] = this.handlers[entityName] || {};
    if (!this.handlers[entityName][key]) {
      this.handlers[entityName][key] = handler;
    }
  }

  public registerBatchHandler(
    entityName: string,
    key: string,
    handler: WebsocketMessageHandler,
  ) {
    this.batchHandlers[entityName] = this.batchHandlers[entityName] || {};
    if (!this.batchHandlers[entityName][key]) {
      this.batchHandlers[entityName][key] = handler;
    }
  }

  public hasHandler(entityName: string, key: string) {
    return !!this.handlers[entityName]?.[key];
  }

  public hasBatchHandler(entityName: string, key: string) {
    return !!this.batchHandlers[entityName]?.[key];
  }

  public getChannels() {
    return Object.keys(this.handlers);
  }

  public subscribeToChannels(channels: Array<string>) {
    // Backend throws an error if a same channel mentioned more than once
    const uniqueChannels = [...new Set(channels)];

    this.send(
      JSON.stringify({
        action: 'subscribe',
        channels: uniqueChannels,
      }),
    );
  }

  /**
   * This is normally called by the WebSocket's onopen handler, although we can actually call it from
   * anywhere to re-authenticate the connection without going through a full connection cycle.
   *
   * @param t string Supply an optional token to use instead of the one currently stored. This should only
   *                 ever be used when the Auth mutex is currently locked, as `getToken` waits for the mutex
   *                 to unlock, however this method gets called while the mutex is locked - hence the
   *                 infinite wait.
   */
  public async authenticate() {
    if (
      (await Auth.isAuthenticated()) &&
      (await Auth.tokenExpiresAfter(spacetime.now().add(2, 'minute')))
    ) {
      await Auth.refreshCredentials();
    }

    const companyId = getGlobal('$companyId');
    const token = await Auth.getToken();

    if (token && companyId) {
      this.send(
        JSON.stringify({
          action: 'authenticate',
          accessToken: token,
          companyId: String(companyId),
        }),
      );
    }
  }

  public closeSafely() {
    if (this.webSocket) {
      this.disconnect();
    }
  }

  /**
   * A simple proxy used to send websocket messages safely, ensuring
   * the socket is in a suitable state to send messages. It uses an
   * exponential backoff when messages fail to send and will re-try
   * up to 5 times before failing.
   */
  private send(data: string) {
    if (this.sendAttempts >= 5) {
      throw new Error('WebSocket message failed to send after 5 attempts.');
    }

    if (this.webSocket?.readyState === WebSocket.OPEN) {
      this.resetSends();
      return this.webSocket.send(data);
    }

    setTimeout(() => {
      this.sendAttempts += 1;
      this.send(data);
    }, (this.sendTimeout += this.sendTimeout));
  }

  private openSocket(authenticate: boolean = false) {
    this.webSocket = new WebSocket(`wss://ws.${process.env.VUE_APP_BASE_URL}`);

    this.webSocket.onclose = () => this.reconnect();
    // Onerror is called when a websocket connection is unsuspended from sleep state
    this.webSocket.onerror = () => this.reconnect();

    this.webSocket.onopen = async () => {
      this.initialiseHeartbeat();
      if (authenticate) {
        await this.authenticate();
      }

      // If the websocket had lost connection and re-opened then
      // reset all the realtime entity stores and force reload
      // to cause components to re-render and be forced to fetch
      // any real-time data they need directly from the API.
      //
      // This is a temporary workaround to prevent clients having stale data until
      // we get the message replays in place.
      if (this.isDisconnected) {
        this.store.dispatch('resetEntities').then();
        this.store.dispatch('app/forceReload').then();
        this.isDisconnected = false;
      }
    };

    this.webSocket.onmessage = async (event) => {
      const {
        data,
        entity,
        event: eventName,
      } = JSON.parse(event.data, dateReviver);

      if (eventName === WebsocketControlEvent.ClientAuthenticationSuccessful) {
        this.resetReconnections();
        this.resetSends();

        this.store.dispatch(
          'appWarnings/removeWarning',
          AppWarningEnum.WebsocketDisconnected,
        );
        this.store.dispatch(
          'appWarnings/removeWarning',
          AppWarningEnum.WebsocketReconnecting,
        );
      }

      // Entity updates
      if (entity) {
        const payload = {
          entity,
          kind: eventName.split('_')[1],
          data,
        };

        // Bulk entity update messages
        if (Array.isArray(data)) {
          if (this.batchHandlers[entity]) {
            Object.values(this.batchHandlers[entity]).forEach((handler) => {
              handler(this, entity, eventName, data);
            });
          }
          this._dispatchEvent(SocketServiceEventType.BATCH_ENTITY, payload);
          this._dispatchEvent(
            batchEntityEventType(entity as ListEventEntitiesResponseDataEnum),
            payload,
          );
        }
        // Single entity update messages
        else {
          if (this.handlers[entity]) {
            Object.values(this.handlers[entity]).forEach((handler) =>
              handler(this, entity, eventName, data),
            );
          }
          this._dispatchEvent(SocketServiceEventType.ENTITY, payload);
          this._dispatchEvent(
            entityEventType(entity as ListEventEntitiesResponseDataEnum),
            payload,
          );
        }
      }

      // Control events
      else if (
        Object.values(WebsocketControlEvent).includes(
          eventName as WebsocketControlEvent,
        )
      ) {
        this._dispatchEvent(SocketServiceEventType.CONTROL, {
          event: eventName,
        });
      }
    };
  }

  private reconnect() {
    this.store.dispatch(
      'appWarnings/setWarning',
      AppWarningEnum.WebsocketReconnecting,
    );

    /**
     * After 5 attempts call the disconnect function to clear any
     * timeouts and then show the topbar warning.
     */
    if (this.reconnectAttempts >= 5) {
      this.disconnect();
      return this.store.dispatch(
        'appWarnings/setWarning',
        AppWarningEnum.WebsocketDisconnected,
      );
    }

    /* Prevent multiple calls to reconnect */
    if (this.reconnecting) {
      return;
    }

    /* Clear any outstanding heartbeat intervals */
    this.clearIntervals();
    this.clearTimeouts();

    this.reconnecting = true;
    this.reconnectAttempts += 1;

    this.reconnectTimeouts.push(
      setTimeout(() => {
        this.openSocket(true);
        this.reconnecting = false;
      }, (this.reconnectTimeout += this.reconnectTimeout)),
    );
  }

  private disconnect() {
    /**
     * Make sure the heartbeat interval is removed before closing the connection
     * to avoid any issues sending messages over a closed connection.
     */
    this.clearIntervals();
    this.clearTimeouts();

    /**
     * Clear the onclose and onerror handlers before closing the socket to
     * prevent any reconnection attempts.
     */
    this.webSocket.onerror = null;
    this.webSocket.onclose = null;
    this.webSocket.close();
    this.webSocket = null;
    this.isDisconnected = true;

    this.resetReconnections();
    this.resetSends();
  }

  private resetReconnections() {
    this.reconnectAttempts = 0;
    this.reconnectTimeout = 250;
    this.reconnecting = false;
  }

  private resetSends() {
    this.sendAttempts = 0;
    this.sendTimeout = 50;
  }

  private clearIntervals() {
    this.pongIntervals.forEach((id) => clearInterval(id));
  }

  private clearTimeouts() {
    this.reconnectTimeouts.forEach((id) => clearTimeout(id));
  }

  private initialiseHeartbeat() {
    this.pongIntervals.push(
      setInterval(() => {
        this.send('heartbeat');
      }, 3 * 60000),
    );
  }
}

export const websocketService = new SocketService();
