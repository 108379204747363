import { employeeApi, notificationApi } from '@/api';
import i18n from '@/i18n';
import { clockInViaPortalsAtLocationFilter } from '@/lib/location/locationFilters';
import { NotificationRouteMap } from '@/lib/notifications/NotificationRouteMap';
import {
  companyCanAccess,
  Feature,
} from '@/lib/permission/companyAccessFeatures';
import { userCan } from '@/lib/permission/userCan';
import { realtimeQuery } from '@/lib/realtime/weak/realtimeFunctions';
import { Entity } from '@/lib/store/realtimeEntities';
import { showWarning } from '@/plugins/ToastNotifications';
import { routes as originalRoutes } from '@/router/routes';
import store from '@/store';
import { setCompanyId } from '@/util/globalFunctions';
import { Permission, userHasPermission } from '@/util/permissionFunctions';

const redirectToDashboard = (routes, next) => {
  store.commit('app/START_REDIRECT_LOADING');
  // next() is not a promise, so we are unable to disable loading in .finally() statement.
  // If redirect() or router.replace() would be used then it is possible to disable loading here, but it would be tricky to
  // disable another loading happening in store.ts at the right time.
  // Loading is being disabled in the global hook
  next(routes.dashboardV2.route());
};

export const applyRouteHooks = (routes: typeof originalRoutes) => {
  routes.notification.beforeEnter = async (to, from, next) => {
    const {
      params: { id },
      query: { companyId, recipientId },
    } = to;

    const currentCompanyId = store.getters.currentCompany?.id;

    // Ensure the current logged in employee can access the notification companyId and recipientId
    const { data: employees } =
      await employeeApi.listEmployeesBelongingToUser();
    const userHasAccessToEmployee = (employees || []).find(
      (e) => e.id === Number(recipientId),
    );
    if (!userHasAccessToEmployee) {
      showWarning(i18n.tc('warningMessage.notificationAccess'));
      return next(routes.logout.route());
    }
    if (Number(companyId) !== currentCompanyId) {
      setCompanyId(Number(companyId));
      await store.dispatch('authorizeApp');
    }

    // Get fresh copy of notification from API
    await notificationApi
      .showNotification({ id })
      .then(async ({ data: notification }) => {
        const route = NotificationRouteMap()[notification.type];
        if (route) {
          store.commit('app/START_REDIRECT_LOADING');
          next(route(notification));
        } else {
          redirectToDashboard(routes, next);
        }
      })
      .catch(() => {
        redirectToDashboard(routes, next);
      });
  };

  // @ts-ignore
  routes.profile.beforeEnter = (to, from, next) => {
    const paramsId = Number(to.params.id);

    if (userCan.viewProfile(paramsId)) return next();

    redirectToDashboard(routes, next);
    showWarning(i18n.t('warningMessage.preventProfileAccess'));
  };

  routes.employmentDetails.beforeEnter = (to, from, next) => {
    const paramsId = Number(to.params.id);

    if (userCan.manageEmployee(paramsId)) {
      return next();
    }

    next(routes.profileSummary.route(paramsId));
  };

  routes.whereAndWhen.beforeEnter = (to, from, next) => {
    const paramsId = Number(to.params.id);

    if (userCan.manageEmployee(paramsId)) {
      return next();
    }

    next(routes.profileSummary.route(paramsId));
  };

  routes.wageAndSalary.beforeEnter = (to, from, next) => {
    const paramsId = Number(to.params.id);

    if (userCan.manageEmployee(paramsId)) {
      return next();
    }

    next(routes.profileSummary.route(paramsId));
  };

  routes.clockingProfile.beforeEnter = async (to, from, next) => {
    const { loggedInEmployee } = store.getters;
    const locationsWithClockingViaPortals =
      store.getters['locations/locationsWithClockInViaPortalEnabled'];

    // make other checks first to not do the request every time
    if (
      companyCanAccess(Feature.ClockInOut) &&
      userCan.viewEmployeeClockingProfile(loggedInEmployee) &&
      locationsWithClockingViaPortals.some(
        clockInViaPortalsAtLocationFilter(loggedInEmployee),
      )
    ) {
      const clockingProfilesQuery = realtimeQuery(
        Entity.EmployeeClockingProfile,
      )
        .where('id', 'eq', loggedInEmployee.id)
        .fetch();
      await clockingProfilesQuery.promise;
      const [profile] = clockingProfilesQuery.data;
      if (profile?.clockInPin) {
        return next();
      }
    }
    next(routes.pageNotFound.route());
  };

  routes.timeClock.beforeEnter = (to, from, next) => {
    if (userCan.seeWhoElseIsWorking() && companyCanAccess(Feature.ClockInOut)) {
      return next();
    }
    showWarning(i18n.t('warningMessage.preventTimeClockAccess'));
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.generalSettings.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageSettings)) {
      return next();
    }
    if (userHasPermission(Permission.ManageSchedules)) {
      return next(routes.scheduleSettings.route());
    }
    if (userHasPermission(Permission.ManageLeave)) {
      return next(routes.leaveSettings.route());
    }
    if (userHasPermission(Permission.ManageTeam)) {
      return next(routes.manageJobRoles.route());
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.manageWorkPatterns.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageSettings)) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.notificationSettings.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageSettings)) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.manageSchedules.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageSchedules)) {
      return next();
    }
    next(routes.generalSettings.route());
  };

  // @ts-ignore
  routes.manageShiftTemplates.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageShifts)) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.breakSettings.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageSchedules)) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.manageLocations.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageSettings)) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.manageScheduleEvents.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageSchedules)) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.manageTags.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageShifts)) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.manageJobRoles.beforeEnter = (to, from, next) => {
    if (userCan.manageTeamSettings()) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.manageEmployeeGroups.beforeEnter = (to, from, next) => {
    if (userCan.manageTeamSettings()) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.manageEmployeeAttributes.beforeEnter = (to, from, next) => {
    if (userCan.manageTeamSettings()) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.leaveSettings.beforeEnter = (to, from, next) => {
    if (userCan.manageLeaveSettings()) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.leaveTypes.beforeEnter = (to, from, next) => {
    if (userCan.manageLeaveSettings()) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.timesheetSettings.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageSettings)) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.manageClockInPortals.beforeEnter = (to, from, next) => {
    if (userCan.manageClockInPortals()) {
      return next();
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.accountSettings.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageBilling)) {
      return next();
    }
    if (userHasPermission(Permission.ManageSettings)) {
      return next(routes.generalSettings.route());
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.billingSettings.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageBilling)) {
      return next();
    }
    if (userHasPermission(Permission.ManageSettings)) {
      return next(routes.generalSettings.route());
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.offers.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageBilling)) {
      return next();
    }
    if (userHasPermission(Permission.ManageSettings)) {
      return next(routes.generalSettings.route());
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.invoices.beforeEnter = (to, from, next) => {
    if (userHasPermission(Permission.ManageBilling)) {
      return next();
    }
    if (userHasPermission(Permission.ManageSettings)) {
      return next(routes.generalSettings.route());
    }
    redirectToDashboard(routes, next);
  };

  routes.wageAndSalary.beforeEnter = (to, from, next) => {
    if (userCan.managePay()) {
      return next();
    }
    next(routes.profileSummary.route(Number(to.params.id)));
  };

  // @ts-ignore
  routes.digiticketsSettings.beforeEnter = (to, from, next) => {
    if (
      userHasPermission(Permission.ManageBilling) &&
      companyCanAccess(Feature.Integrations)
    ) {
      return next();
    }
    if (userHasPermission(Permission.ManageSettings)) {
      return next(routes.generalSettings.route());
    }
    redirectToDashboard(routes, next);
  };

  // @ts-ignore
  routes.trekksoftSettings.beforeEnter = (to, from, next) => {
    if (
      userHasPermission(Permission.ManageBilling) &&
      companyCanAccess(Feature.Integrations)
    ) {
      return next();
    }
    if (userHasPermission(Permission.ManageSettings)) {
      return next(routes.generalSettings.route());
    }
    redirectToDashboard(routes, next);
  };

  routes.shiftDailyTotals.beforeEnter = (to, from, next) => {
    if (userCan.viewShiftCostReports()) {
      return next();
    }
    // as this is the default reports page then check if we are able to visit other reports sections
    if (userCan.viewMessageLogs()) {
      return next(routes.messageHistory.route());
    }
    showWarning(i18n.tc('warningMessage.preventReportsAccess'));
    redirectToDashboard(routes, next);
  };
  routes.shiftTotalsByEmployee.beforeEnter = (to, from, next) => {
    if (userCan.viewShiftCostReports()) {
      return next();
    }
    showWarning(i18n.tc('warningMessage.preventShiftCostReportsAccess'));
    redirectToDashboard(routes, next);
  };
  routes.shiftTotalsByJobRole.beforeEnter = (to, from, next) => {
    if (userCan.viewShiftCostReports()) {
      return next();
    }
    showWarning(i18n.tc('warningMessage.preventShiftCostReportsAccess'));
    redirectToDashboard(routes, next);
  };
  routes.shiftTotalsByLocation.beforeEnter = (to, from, next) => {
    if (userCan.viewShiftCostReports()) {
      return next();
    }
    showWarning(i18n.tc('warningMessage.preventShiftCostReportsAccess'));
    redirectToDashboard(routes, next);
  };
  routes.shiftTotalsByGroup.beforeEnter = (to, from, next) => {
    if (userCan.viewShiftCostReports()) {
      return next();
    }
    showWarning(i18n.tc('warningMessage.preventShiftCostReportsAccess'));
    redirectToDashboard(routes, next);
  };
  routes.shiftTotalsByEmploymentType.beforeEnter = (to, from, next) => {
    if (userCan.viewShiftCostReports()) {
      return next();
    }
    showWarning(i18n.tc('warningMessage.preventShiftCostReportsAccess'));
    redirectToDashboard(routes, next);
  };

  routes.messageHistory.beforeEnter = (to, from, next) => {
    if (userCan.viewMessageLogs()) {
      return next();
    }
    showWarning(i18n.tc('warningMessage.preventReportsAccess'));
    redirectToDashboard(routes, next);
  };
};
