/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Employee Pay Information
 * @export
 * @interface EmployeePay
 */
export interface EmployeePay {
    /**
     * The ID of the Employee this relates to
     * @type {number}
     * @memberof EmployeePay
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeePay
     */
    baseRate: number;
    /**
     * 
     * @type {string}
     * @memberof EmployeePay
     */
    payrollId: string | null;
}

/**
 * Check if a given object implements the EmployeePay interface.
 */
export function instanceOfEmployeePay(value: object): boolean {
    if (!('id' in value)) return false;
    if (!('baseRate' in value)) return false;
    if (!('payrollId' in value)) return false;
    return true;
}

export function EmployeePayFromJSON(json: any): EmployeePay {
    return EmployeePayFromJSONTyped(json, false);
}

export function EmployeePayFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeePay {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'baseRate': json['baseRate'],
        'payrollId': json['payrollId'],
    };
}

export function EmployeePayToJSON(value?: EmployeePay | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'id': value['id'],
        'baseRate': value['baseRate'],
        'payrollId': value['payrollId'],
    };
}

