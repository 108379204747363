/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateOpenShiftResponseData,
  CreateOpenShiftResponseResponse,
  ListOpenShiftResponsesResponse,
} from '../models/index';
import {
    CreateOpenShiftResponseDataFromJSON,
    CreateOpenShiftResponseDataToJSON,
    CreateOpenShiftResponseResponseFromJSON,
    CreateOpenShiftResponseResponseToJSON,
    ListOpenShiftResponsesResponseFromJSON,
    ListOpenShiftResponsesResponseToJSON,
} from '../models/index';

export interface CreateOpenShiftResponseRequest {
    createOpenShiftResponseData?: CreateOpenShiftResponseData;
}

export interface DeleteOpenShiftResponseRequest {
    id: number;
}

export interface ListOpenShiftResponsesRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
}

/**
 * 
 */
export class OpenShiftResponseApi extends runtime.BaseAPI {

    /**
     * Allows an employee to register their interest in working a shift marked as \'open\' by a manager
     * Create Open Shift Response
     */
    async createOpenShiftResponseRaw(requestParameters: CreateOpenShiftResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateOpenShiftResponseResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.open-shift-response.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/open-shift-responses`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOpenShiftResponseDataToJSON(requestParameters['createOpenShiftResponseData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateOpenShiftResponseResponseFromJSON(jsonValue));
    }

    /**
     * Allows an employee to register their interest in working a shift marked as \'open\' by a manager
     * Create Open Shift Response
     */
    async createOpenShiftResponse(requestParameters: CreateOpenShiftResponseRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateOpenShiftResponseResponse> {
        const response = await this.createOpenShiftResponseRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Allows an employee to decide they don\'t actually want to work an open shift they\'d previously registered interest in
     * Delete Open Shift Response
     */
    async deleteOpenShiftResponseRaw(requestParameters: DeleteOpenShiftResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteOpenShiftResponse().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.open-shift-response.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/open-shift-responses/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Allows an employee to decide they don\'t actually want to work an open shift they\'d previously registered interest in
     * Delete Open Shift Response
     */
    async deleteOpenShiftResponse(requestParameters: DeleteOpenShiftResponseRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteOpenShiftResponseRaw(requestParameters, initOverrides);
    }

    /**
     * List all the employees who have registered interest in working a shift marked as \'open\' by a manager
     * List Open Shift Responses
     */
    async listOpenShiftResponsesRaw(requestParameters: ListOpenShiftResponsesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListOpenShiftResponsesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.open-shift-response.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/open-shift-responses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListOpenShiftResponsesResponseFromJSON(jsonValue));
    }

    /**
     * List all the employees who have registered interest in working a shift marked as \'open\' by a manager
     * List Open Shift Responses
     */
    async listOpenShiftResponses(requestParameters: ListOpenShiftResponsesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListOpenShiftResponsesResponse> {
        const response = await this.listOpenShiftResponsesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
