
import { BubbleIconColour } from '@/lib/enum/Colour';
import { BrandIconType, IconType } from '@/lib/enum/Icon';
import { IconSizes, IconSizesType } from '@/lib/enum/IconSizes';
import { PropType } from 'vue';

enum Position {
  Top = 'top',
  Bottom = 'bottom',
}

export default {
  name: 'BubbleIcon',

  props: {
    icon: {
      type: String as PropType<IconType | BrandIconType | string>,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    backgroundClass: {
      type: String as PropType<keyof typeof BubbleIconColour>,
      required: true,
    },
    foregroundClass: {
      type: String as PropType<keyof typeof BubbleIconColour>,
      required: true,
    },
    additionalClasses: {
      type: String,
      default: null,
    },
    size: {
      type: String as PropType<IconSizesType>,
      default: IconSizes.Medium,
    },
    interaction: {
      type: Boolean,
      default: false,
    },
    tooltipText: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    squareIcon: {
      type: Boolean,
      default: false,
    },
    position: {
      type: String as PropType<Position>,
      default: Position.Bottom,
      validator: (prop) => Object.values(Position).includes(prop),
    },
  },

  data() {
    return {
      Position,
    };
  },

  slots: ['default', 'tooltip'],

  computed: {
    classes() {
      const classes = [
        'bubble-icon',
        this.squareIcon ? 'rounded-lg' : 'rounded-full',
        `bg-colour-${this.backgroundClass}`,
        `fg-colour-${this.foregroundClass}`,
        this.size,
        this.additionalClasses,
      ];

      if (this.interaction) classes.push('hover');

      return classes;
    },
  },
};
