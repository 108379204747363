import {
  CustomEventEmitter,
  Unsubscribe,
} from '@/lib/helpers/CustomEventEmitter';
import {
  FinalizationWeakMap,
  FinalizationWeakMapEventType,
} from '@/lib/helpers/FinalizationWeakMap';
import {
  createFilterFromWhereObject,
  EntityFilter,
  Where,
} from '@/lib/realtime/weak/realtimeFiltering';
import { compareEntities } from '@/lib/realtime/weak/realtimeSorting';
import {
  EntityConfig,
  EntityConfigRecord,
  EntityInstance,
  EntityName,
  GetParameterType,
  OrderBy,
  WSEntityName,
} from '@/lib/realtime/weak/realtimeTypes';
import {
  BatchEntityEvent,
  batchEntityEventType,
  EntityEvent,
  entityEventType,
  SocketService,
  SocketServiceEventType,
} from '@/plugins/Websockets';
import { CancellablePromise, makeCancellable } from '@/util/promiseFunctions';
import debounce from 'lodash.debounce';

const KeySeparator = ':';
type WeakEntityRepositoryItemKey<T extends EntityName> =
  `${T}${typeof KeySeparator}${string}`;

export enum WeakEntityRepositoryUpdateKind {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export interface WeakEntityRepositoryResponse<T extends EntityName> {
  entity: T;
  isLoading: boolean;
  isLoaded: boolean;
  isFetching: boolean;
  data: EntityInstance<T>[];
  promise: CancellablePromise<EntityInstance<T>[]> | null;
  error: Error | null;
  refetch: () => CancellablePromise<EntityInstance<T>[]>;
}

interface WeakEntityRepositoryItem<T extends EntityName>
  extends WeakEntityRepositoryResponse<T> {
  _filter: EntityFilter<T>;
  _reset: () => void;
}

export type ReactiveConstructor = <T extends object>(data: T) => T;

export enum WeakEntityRepositoryEventType {
  RESET = 'RESET',
  UPDATE = 'UPDATE',
  BATCH_UPDATE = 'BATCH_UPDATE',

  QUERY_CREATE = 'QUERY_CREATE',
  QUERY_FETCH = 'QUERY_FETCH',
  QUERY_FETCH_SUCCESS = 'QUERY_FETCH_SUCCESS',
  QUERY_FETCH_ERROR = 'QUERY_FETCH_ERROR',
  QUERY_FETCH_FINISH = 'QUERY_FETCH_FINISH',
  QUERY_FINALIZE = 'QUERY_FINALIZE',
}

export type QueryEmptyEvent = {
  entityName: EntityName;
  where: Where<EntityName>;
};

export type QueryEvent = QueryEmptyEvent & {
  response: WeakEntityRepositoryResponse<EntityName>;
};

export type UpdateEvent = {
  entityName: EntityName;
} & (
  | {
      kind:
        | WeakEntityRepositoryUpdateKind.CREATE
        | WeakEntityRepositoryUpdateKind.UPDATE;
      payload: EntityInstance<EntityName>;
    }
  | {
      kind: WeakEntityRepositoryUpdateKind.DELETE;
      payload: number | string;
    }
);

export type BatchedUpdateEvent = {
  entityName: EntityName;
} & (
  | {
      kind:
        | WeakEntityRepositoryUpdateKind.CREATE
        | WeakEntityRepositoryUpdateKind.UPDATE;
      payload: EntityInstance<EntityName>[];
    }
  | {
      kind: WeakEntityRepositoryUpdateKind.DELETE;
      payload: (number | string)[];
    }
);

type WeakEntityRepositoryStore<T extends EntityConfig> = FinalizationWeakMap<
  WeakEntityRepositoryItemKey<keyof T & EntityName>,
  WeakEntityRepositoryItem<keyof T & EntityName>
>;
export type WindowExtensionTag<
  C extends EntityConfig,
  T extends string = '',
> = {
  [k in `__WEAK_CACHE_${T}`]: WeakEntityRepositoryStore<C>;
};

export class WeakEntityRepository<
  T extends EntityConfig = EntityConfig,
> extends CustomEventEmitter<{
  [WeakEntityRepositoryEventType.RESET]: {};
  [WeakEntityRepositoryEventType.UPDATE]: UpdateEvent;
  [WeakEntityRepositoryEventType.BATCH_UPDATE]: BatchedUpdateEvent;
  [WeakEntityRepositoryEventType.QUERY_CREATE]: QueryEvent;
  [WeakEntityRepositoryEventType.QUERY_FETCH]: QueryEvent;
  [WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS]: QueryEvent;
  [WeakEntityRepositoryEventType.QUERY_FETCH_ERROR]: QueryEvent;
  [WeakEntityRepositoryEventType.QUERY_FETCH_FINISH]: QueryEvent;
  [WeakEntityRepositoryEventType.QUERY_FINALIZE]: QueryEmptyEvent;
}> {
  readonly #store: WeakEntityRepositoryStore<T>;

  readonly #config: T;

  readonly #ws: SocketService;

  readonly #wsListeners: Map<
    keyof T & EntityName,
    {
      unsubscribe: Unsubscribe;
      batchUnsubscribe?: Unsubscribe;
    }
  >;

  readonly #entityTriggers: Map<keyof T & EntityName, Unsubscribe>;

  readonly reactiveConstructor: ReactiveConstructor = (obj) => obj;

  static #getEventTypeFromWSMessage(
    eventName: string,
  ): WeakEntityRepositoryUpdateKind {
    if (eventName.endsWith('Created')) {
      return WeakEntityRepositoryUpdateKind.CREATE;
    }
    if (eventName.endsWith('Updated')) {
      return WeakEntityRepositoryUpdateKind.UPDATE;
    }
    if (eventName.endsWith('Deleted')) {
      return WeakEntityRepositoryUpdateKind.DELETE;
    }
  }

  #getEntityConfig<K extends keyof T & EntityName>(
    entity: K,
  ): EntityConfigRecord<K> {
    return this.#config[entity] as EntityConfigRecord<K>;
  }

  // It doesn't use `this` directly but use its generic type
  // eslint-disable-next-line class-methods-use-this
  #getEntityKey<K extends keyof T & EntityName>(
    entity: K,
    where: Where<K>,
  ): WeakEntityRepositoryItemKey<keyof T & EntityName> {
    return `${entity as keyof T & EntityName}${KeySeparator}${JSON.stringify(
      where,
    )}`;
  }

  // It doesn't use `this` directly but use its generic type
  // eslint-disable-next-line class-methods-use-this
  #parseEntityKey<
    E extends keyof T & EntityName = keyof T & EntityName,
    K extends WeakEntityRepositoryItemKey<E> = WeakEntityRepositoryItemKey<E>,
  >(key: K): [E, Where<E>] {
    return [
      key.split(KeySeparator, 1)[0] as E,
      JSON.parse(key.slice(key.indexOf(KeySeparator) + KeySeparator.length)),
    ];
  }

  // It doesn't use `this` directly but use its generic type
  // eslint-disable-next-line class-methods-use-this
  #getEntityFilter<K extends keyof T & EntityName>(
    entity: K,
    filter: Where<K>,
  ): EntityFilter<K> {
    return createFilterFromWhereObject(entity, filter);
  }

  // eslint-disable-next-line class-methods-use-this
  #sync() {
    // Update Vuex or any other state management system
  }

  #createEntityCollection<K extends keyof T & EntityName>(
    entity: K,
    where: Where<K>,
    params: GetParameterType<K> = null,
  ) {
    const defaults = {
      entity,
      isLoading: true,
      isLoaded: false,
      isFetching: false,
      promise: null,
      error: null,
    };
    const newItem = this.reactiveConstructor<WeakEntityRepositoryItem<K>>({
      ...defaults,
      data: [],
      refetch: () => {
        newItem.isLoading = true;

        this._dispatchEvent(WeakEntityRepositoryEventType.QUERY_FETCH, {
          entityName: entity,
          where,
          response: newItem,
        });

        if (newItem.promise) {
          newItem.promise.cancel();
        }
        const config = this.#getEntityConfig(entity);
        newItem.promise = makeCancellable(
          config.fetcher(params, where, config.orderBy ?? []),
        );
        newItem.promise
          .then(
            (data: EntityInstance<K>) => {
              newItem.data = data;

              this._dispatchEvent(
                WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
                {
                  entityName: entity,
                  where,
                  response: newItem,
                },
              );

              return data;
            },
            (error: Error) => {
              newItem.error = error;

              this._dispatchEvent(
                WeakEntityRepositoryEventType.QUERY_FETCH_ERROR,
                {
                  entityName: entity,
                  where,
                  response: newItem,
                },
              );

              throw error;
            },
          )
          .finally(() => {
            newItem.isLoading = false;
            newItem.isLoaded = true;
            newItem.isFetching = false;

            this.#sync();
            this._dispatchEvent(
              WeakEntityRepositoryEventType.QUERY_FETCH_FINISH,
              {
                entityName: entity,
                where,
                response: newItem,
              },
            );
          });
        return newItem.promise;
      },
      _filter: this.#getEntityFilter(entity, where),
      _reset: () => {
        if (newItem.promise) newItem.promise.cancel();
        Object.assign(newItem, defaults, { data: [] });
        newItem.refetch();
      },
    });
    this.#store.set(this.#getEntityKey(entity, where), newItem);
    return newItem;
  }

  #getEntityCollection<K extends keyof T & EntityName>(
    entity: K,
    where: Where<K>,
  ): WeakEntityRepositoryItem<K> | undefined {
    // TS can't know that the key should match the type of the item, so we cast it (Downcast)
    return this.#store.get(this.#getEntityKey<K>(entity, where)) as unknown as
      | WeakEntityRepositoryItem<K>
      | undefined;
  }

  #getEntityCollections<K extends keyof T & EntityName>(
    entity: K,
  ): [K, Where<K>, WeakEntityRepositoryItem<K>][] {
    const entities = [...this.#store.entries()].filter(([key]) =>
      key.startsWith(`${entity}${KeySeparator}`),
    );
    return entities.map(([key, item]) => {
      const [entity, where] = this.#parseEntityKey<K>(
        key as WeakEntityRepositoryItemKey<K>,
      );
      return [entity, where, item as unknown as WeakEntityRepositoryItem<K>];
    });
  }

  static #mutateEntityArray<T extends WSEntityName>(
    entity: EntityInstance<T>,
    data: EntityInstance<T>[],
    filter: EntityFilter<T>,
    orderBy: OrderBy<EntityInstance<T>>,
  ) {
    // Because the values of an entity might have changed,
    // we need to check the filters to see if
    // the entity should still exist in this collection.
    const oldIndex = data.findIndex((e) => e.id === entity.id);
    const previouslyMatched = oldIndex > -1;
    const nowMatches = filter(entity);

    if (!previouslyMatched && nowMatches) {
      // Previously didn't match, but now does
      // Add the new then resort
      // Sort and splice affect the data array directly, no shallow copies
      data.splice(data.length - 1, 0, entity);
      data.sort((a, b) => compareEntities(a, b, orderBy));
    } else if (previouslyMatched && nowMatches) {
      // Previously matched, still matches

      // To avoid issues where websocket messages arrive out-of-order,
      // check that the old version's 'updatedAt' value of the entity
      // isn't newer than the incoming one.
      const oldEntity = data[oldIndex];
      if (
        'updatedAt' in oldEntity &&
        'updatedAt' in entity &&
        oldEntity.updatedAt >= entity.updatedAt
      ) {
        return;
      }
      // Remove the old, add the new then resort
      // Sort and splice affect the data array directly, no shallow copies
      data.splice(oldIndex, 1);
      data.splice(oldIndex, 0, entity);
      data.sort((a, b) => compareEntities(a, b, orderBy));
    } else if (previouslyMatched && !nowMatches) {
      // Previously matched, now doesn't
      data.splice(oldIndex, 1);
    } else {
      // Previously didn't match, still doesn't - do nothing
    }
  }

  #upsertEntity<T extends WSEntityName>(
    entityName: T,
    entity: EntityInstance<T>,
  ) {
    const { orderBy = [] } = this.#getEntityConfig(entityName);
    this.#getEntityCollections(entityName).forEach(([, , { data, _filter }]) =>
      WeakEntityRepository.#mutateEntityArray(entity, data, _filter, orderBy),
    );
    this.#sync();
  }

  #batchedUpsertEntities<T extends WSEntityName>(
    entityName: T,
    entities: EntityInstance<T>[],
  ) {
    const { orderBy = [] } = this.#getEntityConfig(entityName);
    this.#getEntityCollections(entityName).forEach(
      ([, , { data, _filter }]) => {
        entities.forEach((entity) => {
          WeakEntityRepository.#mutateEntityArray<T>(
            entity,
            data,
            _filter,
            orderBy,
          );
        });
      },
    );
    this.#sync();
  }

  #removeEntity(entityName: WSEntityName, id: number | string) {
    this.#getEntityCollections(entityName).forEach(([, , { data }]) => {
      const index = data.findIndex((e) => e.id === id);
      if (index !== -1) {
        data.splice(index, 1);
      }
    });

    this.#sync();
  }

  #batchedRemoveEntities(entityName: WSEntityName, ids: (number | string)[]) {
    this.#getEntityCollections(entityName).forEach(([, , response]) => {
      response.data = response.data.filter(
        ({ id }) => typeof id === 'number' && !ids.includes(id),
      );
    });

    this.#sync();
  }

  #registerWebsocketHandler<K extends keyof T & WSEntityName>(entity: K) {
    if (this.#wsListeners.has(entity)) return;

    const { batchFetcher } = this.#getEntityConfig(entity);
    this.#wsListeners.set(entity, {
      unsubscribe: this.#ws.addEventListener(
        entityEventType(entity),
        ({ kind: eventKind, data }) =>
          this.dispatchUpdate(
            WeakEntityRepository.#getEventTypeFromWSMessage(eventKind),
            entity,
            data,
          ),
      ),
      ...(!!batchFetcher && {
        batchUnsubscribe: this.#ws.addEventListener(
          batchEntityEventType(entity),
          ({ kind: eventKind, data }) =>
            this.dispatchBatchedUpdate(
              WeakEntityRepository.#getEventTypeFromWSMessage(eventKind),
              entity,
              data,
            ),
        ),
      }),
    });
  }

  #shouldApplyWebsocket<K extends keyof T & EntityName>(
    entity: K,
    // @ts-expect-error:TS2677
    // In realtimeTypes.ts we defined
    // that useWebsocket should be false for non-list entities,
    // so we sure that entity is T & ListEventEntitiesResponseDataEnum
  ): entity is T & WSEntityName {
    return this.#getEntityConfig(entity).useWebsocket !== false;
  }

  #registerTriggers<K extends keyof T & EntityName>(entity: K) {
    if (this.#entityTriggers.has(entity)) return;

    const { triggers } = this.#getEntityConfig(entity);
    if (!triggers) return;

    const debouncedRefetch = debounce(() => {
      this.#getEntityCollections(entity).forEach(([, , response]) =>
        response.refetch(),
      );
    }, 1.5 * 1000);
    const refetch = () => {
      this.#getEntityCollections(entity).forEach(([, , response]) => {
        response.isFetching = true;
      });
      debouncedRefetch();
    };

    const socketUpdateHandler = ({ entity }: EntityEvent) => {
      if (triggers.includes(entity)) {
        refetch();
      }
    };

    const socketBatchUpdateHandler = ({ entity }: BatchEntityEvent) => {
      if (triggers.includes(entity)) {
        refetch();
      }
    };

    const queryFinalizeHandler = ({ entityName }: QueryEmptyEvent) => {
      if (
        entityName !== entity ||
        this.#getEntityCollections(entity).length > 0
      ) {
        return;
      }

      this.#ws.removeEventListener(
        SocketServiceEventType.ENTITY,
        socketUpdateHandler,
      );
      this.#ws.removeEventListener(
        SocketServiceEventType.BATCH_ENTITY,
        socketBatchUpdateHandler,
      );

      this.removeEventListener(
        WeakEntityRepositoryEventType.QUERY_FINALIZE,
        queryFinalizeHandler,
      );
      this.addEventListener(
        WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
        // eslint-disable-next-line no-use-before-define
        queryFetchHandler,
      );
    };

    const queryFetchHandler = ({ entityName }: QueryEvent) => {
      if (entityName !== entity) return;

      this.#ws.addEventListener(
        SocketServiceEventType.ENTITY,
        socketUpdateHandler,
      );
      this.#ws.addEventListener(
        SocketServiceEventType.BATCH_ENTITY,
        socketBatchUpdateHandler,
      );

      this.addEventListener(
        WeakEntityRepositoryEventType.QUERY_FINALIZE,
        queryFinalizeHandler,
      );
      this.removeEventListener(
        WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
        queryFetchHandler,
      );
    };

    this.addEventListener(
      WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
      queryFetchHandler,
    );

    this.#entityTriggers.set(entity, () => {
      this.#ws.removeEventListener(
        SocketServiceEventType.ENTITY,
        socketUpdateHandler,
      );
      this.#ws.removeEventListener(
        SocketServiceEventType.BATCH_ENTITY,
        socketBatchUpdateHandler,
      );

      this.removeEventListener(
        WeakEntityRepositoryEventType.QUERY_FINALIZE,
        queryFinalizeHandler,
      );
      this.removeEventListener(
        WeakEntityRepositoryEventType.QUERY_FETCH_SUCCESS,
        queryFetchHandler,
      );
    });
  }

  #shouldApplyTriggers<K extends keyof T & EntityName>(entity: K): boolean {
    return this.#getEntityConfig(entity).triggers?.length > 0;
  }

  constructor(
    config: T,
    wsService: SocketService,
    reactiveConstructor?: ReactiveConstructor | null,
    tag?: string,
  ) {
    super();

    this.#config = config;
    this.#ws = wsService;
    if (reactiveConstructor) {
      this.reactiveConstructor = reactiveConstructor;
    }
    this.#wsListeners = new Map();
    this.#entityTriggers = new Map();

    this.#store = new FinalizationWeakMap();
    this.#store.addEventListener(
      FinalizationWeakMapEventType.FINALIZE,
      ({ key }) => {
        // eslint-disable-next-line no-console
        console.debug(`WeakEntityRepository(${tag}): Cache cleared for ${key}`);

        this.#sync();
        const [entityName, where] = this.#parseEntityKey(key);
        this._dispatchEvent(WeakEntityRepositoryEventType.QUERY_FINALIZE, {
          entityName,
          where,
        });
      },
    );
    window[`__WEAK_CACHE_${tag ?? ''}`] = this.#store;
  }

  softReset() {
    this.#store.forEach((c) => c._reset());

    this.#sync();
    this._dispatchEvent(WeakEntityRepositoryEventType.RESET, {});
  }

  detachedReset() {
    // Cancel all outstanding promises
    this.#store.forEach((c) => c.promise?.cancel());
    this.#store.clear();

    this.#wsListeners.forEach((l) => {
      l.unsubscribe();
      l.batchUnsubscribe?.();
    });
    this.#wsListeners.clear();

    this.#entityTriggers.forEach((t) => t());
    this.#entityTriggers.clear();

    this.#sync();
    this._dispatchEvent(WeakEntityRepositoryEventType.RESET, {});
  }

  dispatchUpdate<K extends keyof T & WSEntityName>(
    kind: WeakEntityRepositoryUpdateKind,
    entity: K,
    data: unknown,
  ): void {
    if (kind === WeakEntityRepositoryUpdateKind.DELETE) {
      const id =
        typeof data === 'object'
          ? (data as EntityInstance<K>).id
          : (data as number | string);
      this.#removeEntity(entity, id);
      this._dispatchEvent(WeakEntityRepositoryEventType.UPDATE, {
        entityName: entity,
        kind,
        payload: id,
      });
    } else if (
      // After deleting an entity if the websocket message order isn't correct,
      // we get the entity id on an 'updated' event
      // E.g., deleting an employee
      typeof data === 'object' &&
      [
        WeakEntityRepositoryUpdateKind.CREATE,
        WeakEntityRepositoryUpdateKind.UPDATE,
      ].includes(kind)
    ) {
      const entityInstance = this.#getEntityConfig(entity).entityMaker(data);
      this.#upsertEntity(entity, entityInstance);
      this._dispatchEvent(WeakEntityRepositoryEventType.UPDATE, {
        entityName: entity,
        kind,
        payload: entityInstance,
      });
    }
  }

  async dispatchBatchedUpdate<K extends keyof T & WSEntityName>(
    kind: WeakEntityRepositoryUpdateKind,
    entity: K,
    data: number[] | EntityInstance<K>[],
  ): Promise<void> {
    if (data.length === 0) return;

    const isEntityInstance = typeof data[0] === 'object';

    if (kind === WeakEntityRepositoryUpdateKind.DELETE) {
      const ids: (number | string)[] = isEntityInstance
        ? (data as EntityInstance<K>[]).map((e: EntityInstance<K>) => e.id)
        : (data as (number | string)[]);

      this.#batchedRemoveEntities(entity, ids);
      this._dispatchEvent(WeakEntityRepositoryEventType.BATCH_UPDATE, {
        entityName: entity,
        kind,
        payload: ids,
      });
    } else if (
      // After deleting an entity if the websocket message order isn't correct,
      // we get the entity id on an 'updated' event
      // E.g., deleting an employee
      typeof data === 'object' &&
      [
        WeakEntityRepositoryUpdateKind.CREATE,
        WeakEntityRepositoryUpdateKind.UPDATE,
      ].includes(kind)
    ) {
      const entities: EntityInstance<K>[] = isEntityInstance
        ? (data as EntityInstance<K>[])
        : await this.#getEntityConfig(entity).batchFetcher(
            data as (number | string)[],
          );
      this.#batchedUpsertEntities(entity, entities);
      this._dispatchEvent(WeakEntityRepositoryEventType.BATCH_UPDATE, {
        entityName: entity,
        kind,
        payload: entities,
      });
    }
  }

  subscribe<K extends keyof T & EntityName>(
    entity: K,
    where: Where<K> = {},
    params: GetParameterType<K> = null,
  ): WeakEntityRepositoryResponse<K> {
    if (this.#shouldApplyWebsocket(entity)) {
      this.#registerWebsocketHandler(entity);
    }
    if (this.#shouldApplyTriggers(entity)) {
      this.#registerTriggers(entity);
    }

    const collection = this.#getEntityCollection(entity, where ?? {});
    if (collection) return collection;

    let newItem: WeakEntityRepositoryItem<K> = null;
    // If it is not already a complete collection, we check for it
    if (Object.keys(where ?? {}).length) {
      const completeCollection = this.#getEntityCollection(entity, {});
      // If the complete collection exists, we filter and reuse its values
      if (completeCollection) {
        newItem = this.#createEntityCollection(entity, where, params);
        newItem.data = completeCollection.data.filter(newItem._filter);
        newItem.isLoading = completeCollection.isLoading;
        newItem.isLoaded = completeCollection.isLoaded;

        if (completeCollection.promise) {
          completeCollection.promise.then((data) => {
            newItem.data = data.filter(newItem._filter);
            newItem.isLoading = false;
            newItem.isLoaded = true;
            // Returning the initial input to avoid side effects
            return data;
          });
        }
      }
    }

    if (!newItem) {
      newItem = this.#createEntityCollection(entity, where, params);
      newItem.refetch();
    }

    this._dispatchEvent(WeakEntityRepositoryEventType.QUERY_CREATE, {
      entityName: entity,
      where,
      response: newItem,
    });
    return newItem;
  }
}
