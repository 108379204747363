
import CompanyToggle from '@/components/buttons/dedicated/CompanyToggle.vue';
import ActionDialog from '@/components/dialog/ActionDialog.vue';
import EmployeeAvatar from '@/components/employee/EmployeeAvatar.vue';
import Checkbox from '@/components/inputs/Checkbox.vue';
import SlidePanel from '@/components/interface/SlidePanel.vue';
import MenuItem from '@/components/menu/MenuItem.vue';
import { fullName } from '@/lib/employee/employeeFunctions';
import { Icon } from '@/lib/enum/Icon';
import { clockInViaPortalsAtLocationFilter } from '@/lib/location/locationFilters';
import { isVisibleRoute, NavItem, navItems } from '@/lib/navigation';
import { realtimeQuery } from '@/lib/realtime/weak/realtimeFunctions';
import { Entity } from '@/lib/store/realtimeEntities';
import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import { PropType } from 'vue';
import { Company, Employee, Location } from '../../../api/v1';

export default {
  name: 'UserMenu',
  components: {
    Checkbox,
    ActionDialog,
    MenuItem,
    EmployeeAvatar,
    CompanyToggle,
    SlidePanel,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    useBackgroundOverlay: {
      type: Boolean,
      default: false,
    },
    showEmployeeHeader: {
      type: Boolean,
      default: false,
    },
    showUnsectionedItems: {
      type: Boolean,
      default: false,
    },
    showTimeOffItems: {
      type: Boolean,
      default: false,
    },
    showProfileItems: {
      type: Boolean,
      default: false,
    },
    showDeveloperSettings: {
      type: Boolean,
      default: false,
    },
    extraItems: {
      type: Array as PropType<NavItem[]>,
      default: () => [],
    },
  },
  data() {
    return {
      navItems,
      Icon,
      routes,
      logoutPrompt: {
        show: false,
        everywhere: false,
      },
      clockingProfiles: realtimeQuery(Entity.EmployeeClockingProfile)
        .where('id', 'eq', store.getters.loggedInEmployee.id)
        .fetch(),
    };
  },
  computed: {
    currentCompany: (): Company => store.getters.currentCompany,
    loggedInEmployee: (): Employee | null => store.getters.loggedInEmployee,
    locationsWithClockingViaPortals: (): Location[] =>
      store.getters['locations/locationsWithClockInViaPortalEnabled'],

    unsectionedItems(): NavItem[] {
      return this.loggedInEmployee
        ? [
            navItems.employmentDetails(this.loggedInEmployee.id),
            navItems.whereAndWhen(this.loggedInEmployee.id),
            navItems.personalDetails(this.loggedInEmployee.id),
            navItems.myDocuments(this.loggedInEmployee.id),
            navItems.wageAndSalary(this.loggedInEmployee.id),
            navItems.emergencyDetails(this.loggedInEmployee.id),
          ].filter(isVisibleRoute(true, this.currentCompany))
        : [];
    },

    timeOffItems(): NavItem[] {
      return this.loggedInEmployee
        ? [
            navItems.unavailability(this.loggedInEmployee.id),
            navItems.leave(this.loggedInEmployee.id),
            navItems.absence(this.loggedInEmployee.id),
          ].filter(isVisibleRoute(true, this.currentCompany))
        : [];
    },

    profileItems(): NavItem[] {
      return this.loggedInEmployee
        ? [
            ...(!this.showUnsectionedItems
              ? [navItems.myProfile(this.loggedInEmployee.id)]
              : []),
            navItems.accountDetails(),
            navItems.updatePassword(this.loggedInEmployee.id),
            navItems.notificationPreferences(this.loggedInEmployee.id),
            ...(this.clockingProfiles.data[0]?.clockInPin
              ? [
                  navItems.clockingProfile(
                    this.loggedInEmployee,
                    this.locationsWithClockingViaPortals.some(
                      clockInViaPortalsAtLocationFilter(this.loggedInEmployee),
                    ),
                  ),
                ]
              : []),
            ...(this.showDeveloperSettings
              ? [navItems.developerSettings()]
              : []),
            navItems.referAFriend(),
            ...this.extraItems,
            navItems.logout(() => {
              this.logoutPrompt.show = true;
            }),
          ].filter(isVisibleRoute(true, this.currentCompany))
        : [];
    },
  },
  methods: {
    fullName,
    redirect,

    onClick(item: NavItem) {
      if (item.enabled) {
        if (item.to) {
          redirect(item.to);
        } else {
          item.action();
        }
        this.$emit('input', false);
      }
    },
  },
};
