/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClockInData
 */
export interface ClockInData {
    /**
     * Which employee should be clocked in. Leave blank to clock yourself in.
     * @type {number}
     * @memberof ClockInData
     */
    employeeId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClockInData
     */
    shiftId?: number;
    /**
     * 
     * @type {number}
     * @memberof ClockInData
     */
    locationId?: number;
}

/**
 * Check if a given object implements the ClockInData interface.
 */
export function instanceOfClockInData(value: object): boolean {
    return true;
}

export function ClockInDataFromJSON(json: any): ClockInData {
    return ClockInDataFromJSONTyped(json, false);
}

export function ClockInDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockInData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
        'shiftId': json['shiftId'] == null ? undefined : json['shiftId'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
    };
}

export function ClockInDataToJSON(value?: ClockInData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'employeeId': value['employeeId'],
        'shiftId': value['shiftId'],
        'locationId': value['locationId'],
    };
}

