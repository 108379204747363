const formatCurrency = (
  value: number,
  currency: string = 'GBP',
  options: object = {},
  locale: string = undefined, // Undefined means use the browser locale
): string => {
  const f = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    ...options,
  });

  return f.format(value);
};

export { formatCurrency };
