import i18n from '@/i18n';
import { dateTimeFormats } from '@/lang/dateTimeFormats';

export const getNullableNumber = (value: unknown): number | null => {
  if (value == null) return null;

  const valueAsNumber: number = +value;
  return Number.isNaN(valueAsNumber) ? null : valueAsNumber;
};

export const getNumericValueRepresentation = (value: number | Date): string =>
  typeof value === 'number'
    ? `${value}`
    : i18n.d(value, dateTimeFormats.dayMonthYearHourMinute);
