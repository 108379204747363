import { belongsToEmployee } from '@/lib/filters/employeeFilters';
import { isLocked } from '@/lib/schedule-v2/shiftV2Status';
import { sortBy } from '@/util/arrayFunctions';
import { isPresent } from '@/util/dateArithmetic';
import { getEntityName, minutesRemaining } from '@/util/entityFunctions';
import {
  EmployeeJobRole,
  EmployeePay,
  Shift,
  ShiftConflictTypeEnum,
  ShiftSwap,
  ShiftSwapStatusEnum,
  Tag,
  TimesheetEntry,
  TimesheetEntryStatusEnum,
} from '../../../api/v1';
import { userCan } from '../permission/userCan';

export const presentTotalDuration = (totalMinutes: number) => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes - hours * 60);
  return `${hours}h ${minutes}m`;
};

export const totalDurationOfShiftsInMinutes = (shifts: Shift[]): number =>
  shifts.reduce(
    (total, shift) => total + shift.expectedWorkingTimeInMinutes,
    0,
  );

export const totalDurationOfShiftsInHours = (shifts: Shift[]): number => {
  return totalDurationOfShiftsInMinutes(shifts) / 60;
};

// Used for assign dialogs to check if we need to create a replacement or just reassign
export const isReplacementShift = (shift: Shift, timezone: string) =>
  isLocked(shift) ||
  (isPresent(shift.startsAt, shift.endsAt) &&
    minutesRemaining(timezone, shift) > 15);

export const getShiftTagNames = ({ tagIds }: Shift, tags: Tag[]) =>
  tags
    .filter((t) => tagIds.includes(t.id))
    .map(({ id }) => getEntityName(id, tags));

export const isPendingShiftSwap = (s: ShiftSwap) =>
  [
    ShiftSwapStatusEnum.PendingAcceptance,
    ShiftSwapStatusEnum.PendingApproval,
  ].includes(s.status);

export const getEmployeeCost = (
  employeePay: EmployeePay,
  employeeJobRole: EmployeeJobRole,
  shift: Shift,
): string => {
  if (!employeePay || !employeeJobRole || !shift) {
    return '';
  }
  return (
    (employeeJobRole.rate ?? (employeePay.baseRate || 0)) *
    totalDurationOfShiftsInHours([shift])
  ).toFixed(2);
};

export const getEmployeesNextShift = (
  employeeId: number,
  shifts: Shift[],
  timesheets: TimesheetEntry[],
  now: Date,
): Shift | null => {
  const filteredShifts = sortBy(
    shifts.filter((s) => belongsToEmployee(employeeId)(s)),
    'startsAt',
  );
  return (
    filteredShifts.find((s) => {
      const timesheet = timesheets.find((t) => t.shiftId === s.id);
      return (
        // Shift with an in-progress timesheet
        (timesheet &&
          timesheet.status !== TimesheetEntryStatusEnum.Completed) ||
        // Shift which ends in future and doesn't have a timesheet
        (s.endsAt > now && !timesheet)
      );
    }) ?? null
  );
};

export const tagVisibilityFilter =
  (userCanManageShifts: boolean) =>
  (tag: Tag): boolean => {
    return userCanManageShifts || tag.visibleToEmployees;
  };

export const totalShiftBreakMinutes = (shift: Shift): number =>
  shift.unpaidBreakInMinutes + shift.paidBreakInMinutes;

export const shiftJobRoleIsVisible = (showJobRole: boolean): boolean =>
  showJobRole || userCan.manageShifts();

export const shiftHasConflict = (shift: Shift): boolean =>
  !!shift.shiftConflicts.length;

export const shiftHasConflictType = (
  shift: Shift,
  conflictType: ShiftConflictTypeEnum,
) => shift.shiftConflicts.some((conflict) => conflict.type === conflictType);
