
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import { BubbleIconColour, InlineDialogTheme } from '@/lib/enum/Colour';
import { Icon, IconType } from '@/lib/enum/Icon';
import { PropType } from 'vue';

export default {
  name: 'InlineDialog',
  components: { BubbleIcon },
  props: {
    header: {
      type: [String, null],
      default: null,
    },
    message: {
      type: [String, null],
      default: null,
    },
    icon: {
      type: String as PropType<IconType | null>,
      default: null,
    },
    type: {
      type: String as PropType<InlineDialogTheme>,
      default: InlineDialogTheme.Warning,
    },
  },

  computed: {
    iconBgColour(): BubbleIconColour {
      return {
        [InlineDialogTheme.Warning]: BubbleIconColour['bright-yellow'],
        [InlineDialogTheme.Error]: BubbleIconColour['bright-red'],
        [InlineDialogTheme.Info]: BubbleIconColour['bright-blue'],
        [InlineDialogTheme.Hint]: BubbleIconColour['pink-300'],
      }[this.type];
    },

    mainIcon(): IconType {
      return (
        this.icon ??
        {
          [InlineDialogTheme.Warning]: Icon.Exclamation,
          [InlineDialogTheme.Error]: Icon.Exclamation,
          [InlineDialogTheme.Info]: Icon.Info,
          [InlineDialogTheme.Hint]: Icon.LightBulbOn,
        }[this.type]
      );
    },
  },
};
