import { IconType } from '@/lib/enum/Icon';

export type FilterItemArgumentType = string | number | boolean;

export type FilterItem<T extends FilterItemArgumentType> = {
  value: T;
  label: string;
  field: string;
  icon?: IconType;
};

export const toFilterItem = <T extends FilterItemArgumentType>(
  value: T,
  label: string,
  field: string,
  icon?: IconType,
): FilterItem<T> => ({ value, label, field, icon });

export const getFilterValues = <T extends FilterItemArgumentType>(
  items: FilterItem<T>[],
): T[] => items.map((i) => i.value);

// When a filter input returns data it returns ids only so this needs to be converted to a FilterItem
export const getFilterResult = <T extends FilterItemArgumentType>(
  values: T[],
  items: { id: T; name?: string; fullName?: string }[],
  field: string,
  icon?: IconType,
): FilterItem<T>[] =>
  items
    .filter((i) => values.includes(i.id))
    .map((i) => toFilterItem(i.id, i.name ?? i.fullName ?? '', field, icon));
