
import CloseButton from '@/components/buttons/dedicated/CloseButton';
import MobileHeaderLayout from '@/components/mobile/MobileHeaderLayout';
import MobileHeaderTabs from '@/components/mobile/MobileHeaderTabs';
import { Icon } from '@/lib/enum/Icon';
import { IconSettings, MobileHeaderTab } from '@/lib/mobileHeader';
import { elementIsScrolledToBottom, viewport } from '@/util/windowFunctions';
import DialogHeader from '@/views/settings/modals/DialogHeader';
import { PropType } from 'vue';

export default {
  name: 'Dialog',

  components: {
    CloseButton,
    DialogHeader,
    MobileHeaderTabs,
    MobileHeaderLayout,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    fullscreen: {
      type: Boolean,
      default: null,
    },
    // On mobile fullscreen dialog header should still be visible
    dialogHeaderFullscreen: {
      type: Boolean,
      default: false,
    },
    headerTabs: {
      type: Array as PropType<MobileHeaderTab[]>,
      default: () => [],
    },
    headerRight: {
      type: Object as PropType<IconSettings> | null,
      default: null,
    },
    maxWidth: {
      type: [String, Number],
      default: 550,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    value: {
      // v-dialog will cope with truthy/falsey values, so allowing multiple types can yield some convenient shortcuts
      type: [Boolean, Object, String],
      default: false,
    },
    popUp: {
      type: Boolean,
      default: false,
    },
    transition: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      contentCanScroll: false,
      viewport,
      Icon,
      windowScroll: 0,
    };
  },

  computed: {
    displayFullscreen(): boolean {
      if (this.fullscreen !== null) {
        return this.fullscreen;
      }
      return !viewport.md;
    },
    isVisible: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.windowScroll = window.scrollY;
        document.body.style.overflow = v ? 'hidden' : '';
        document.body.style.height = v ? '100vh' : '';
      },
    },
  },

  mounted() {
    // When the dialog is resized vertically, the amount of space given to the content area will change,
    // so observe this, and update our contentCanScroll property
    const resizeObserver =
      typeof ResizeObserver === 'undefined'
        ? null
        : new ResizeObserver(() => {
            window.requestAnimationFrame(() => {
              if (this.$refs.content) {
                this.contentCanScroll = !elementIsScrolledToBottom(
                  this.$refs.content,
                );
              }
            });
          });

    this.$nextTick(() => {
      if (resizeObserver && this.$refs.content) {
        resizeObserver.observe(this.$refs.content);
      }
    });
  },

  beforeDestroy() {
    document.body.style.overflow = '';
    document.body.style.height = '';
    window.scrollTo(0, this.windowScroll);
  },

  methods: {
    close() {
      this.isVisible = false;
    },
    handleScroll({ target }) {
      this.contentCanScroll = !elementIsScrolledToBottom(target);
    },
  },
};
