/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Relationships } from './Relationships';
import {
    RelationshipsFromJSON,
    RelationshipsFromJSONTyped,
    RelationshipsToJSON,
} from './Relationships';

/**
 * 
 * @export
 * @interface BulkUpdateEmployeesData
 */
export interface BulkUpdateEmployeesData {
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateEmployeesData
     */
    lineManagerId?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateEmployeesData
     */
    leaveApproverId?: number;
    /**
     * A leave policy to define when and how the employee can take leave. Note: changing this value to an employee with an existing leave policy may result in leave periods being regenerated when the new policy results in a different start date.
     * @type {number}
     * @memberof BulkUpdateEmployeesData
     */
    leavePolicyId?: number;
    /**
     * 
     * @type {Relationships}
     * @memberof BulkUpdateEmployeesData
     */
    locationIds?: Relationships;
    /**
     * 
     * @type {Relationships}
     * @memberof BulkUpdateEmployeesData
     */
    jobRoleIds?: Relationships;
    /**
     * 
     * @type {Relationships}
     * @memberof BulkUpdateEmployeesData
     */
    scheduleIds?: Relationships;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateEmployeesData
     */
    employeeGroupId?: number;
    /**
     * The number of hours this employee would usually work in a week
     * @type {number}
     * @memberof BulkUpdateEmployeesData
     */
    workingHoursPerWeek?: number;
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateEmployeesData
     */
    getsPublicHolidays?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateEmployeesData
     */
    employmentType?: BulkUpdateEmployeesDataEmploymentTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum BulkUpdateEmployeesDataEmploymentTypeEnum {
    Agency = 'Agency',
    AnnualisedContract = 'Annualised Contract',
    ApprenticeTrainee = 'Apprentice / Trainee',
    Casual = 'Casual',
    FixedTerm = 'Fixed Term',
    FullTime = 'Full Time',
    PartTime = 'Part Time',
    SelfEmployed = 'Self-Employed',
    Temporary = 'Temporary',
    Volunteer = 'Volunteer'
}


/**
 * Check if a given object implements the BulkUpdateEmployeesData interface.
 */
export function instanceOfBulkUpdateEmployeesData(value: object): boolean {
    return true;
}

export function BulkUpdateEmployeesDataFromJSON(json: any): BulkUpdateEmployeesData {
    return BulkUpdateEmployeesDataFromJSONTyped(json, false);
}

export function BulkUpdateEmployeesDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateEmployeesData {
    if (json == null) {
        return json;
    }
    return {
        
        'lineManagerId': json['lineManagerId'] == null ? undefined : json['lineManagerId'],
        'leaveApproverId': json['leaveApproverId'] == null ? undefined : json['leaveApproverId'],
        'leavePolicyId': json['leavePolicyId'] == null ? undefined : json['leavePolicyId'],
        'locationIds': json['locationIds'] == null ? undefined : RelationshipsFromJSON(json['locationIds']),
        'jobRoleIds': json['jobRoleIds'] == null ? undefined : RelationshipsFromJSON(json['jobRoleIds']),
        'scheduleIds': json['scheduleIds'] == null ? undefined : RelationshipsFromJSON(json['scheduleIds']),
        'employeeGroupId': json['employeeGroupId'] == null ? undefined : json['employeeGroupId'],
        'workingHoursPerWeek': json['workingHoursPerWeek'] == null ? undefined : json['workingHoursPerWeek'],
        'getsPublicHolidays': json['getsPublicHolidays'] == null ? undefined : json['getsPublicHolidays'],
        'employmentType': json['employmentType'] == null ? undefined : json['employmentType'],
    };
}

export function BulkUpdateEmployeesDataToJSON(value?: BulkUpdateEmployeesData | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'lineManagerId': value['lineManagerId'],
        'leaveApproverId': value['leaveApproverId'],
        'leavePolicyId': value['leavePolicyId'],
        'locationIds': RelationshipsToJSON(value['locationIds']),
        'jobRoleIds': RelationshipsToJSON(value['jobRoleIds']),
        'scheduleIds': RelationshipsToJSON(value['scheduleIds']),
        'employeeGroupId': value['employeeGroupId'],
        'workingHoursPerWeek': value['workingHoursPerWeek'],
        'getsPublicHolidays': value['getsPublicHolidays'],
        'employmentType': value['employmentType'],
    };
}

