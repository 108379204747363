/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AcknowledgeShiftsData,
  BulkUpdateShiftsData,
  CopyShiftsData,
  CreateShiftData,
  CreateShiftResponse,
  ListShiftReplacementsResponse,
  ListShiftsResponse,
  PublishShiftsData,
  ShowShiftResponse,
  UpdateShiftData,
  UpdateShiftResponse,
} from '../models/index';
import {
    AcknowledgeShiftsDataFromJSON,
    AcknowledgeShiftsDataToJSON,
    BulkUpdateShiftsDataFromJSON,
    BulkUpdateShiftsDataToJSON,
    CopyShiftsDataFromJSON,
    CopyShiftsDataToJSON,
    CreateShiftDataFromJSON,
    CreateShiftDataToJSON,
    CreateShiftResponseFromJSON,
    CreateShiftResponseToJSON,
    ListShiftReplacementsResponseFromJSON,
    ListShiftReplacementsResponseToJSON,
    ListShiftsResponseFromJSON,
    ListShiftsResponseToJSON,
    PublishShiftsDataFromJSON,
    PublishShiftsDataToJSON,
    ShowShiftResponseFromJSON,
    ShowShiftResponseToJSON,
    UpdateShiftDataFromJSON,
    UpdateShiftDataToJSON,
    UpdateShiftResponseFromJSON,
    UpdateShiftResponseToJSON,
} from '../models/index';

export interface AcknowledgeShiftsRequest {
    acknowledgeShiftsData?: AcknowledgeShiftsData;
}

export interface BulkDeleteShiftsRequest {
    where?: { [key: string]: object; };
    limit?: number;
    orderBy?: Array<string>;
}

export interface BulkUpdateShiftsRequest {
    where?: { [key: string]: object; };
    bulkUpdateShiftsData?: BulkUpdateShiftsData;
}

export interface CopyShiftsRequest {
    copyShiftsData?: CopyShiftsData;
}

export interface CreateShiftRequest {
    _with?: Array<string>;
    createShiftData?: CreateShiftData;
}

export interface DeleteShiftRequest {
    id: number;
}

export interface ListShiftReplacementsRequest {
    id: number;
    _with?: Array<string>;
    page?: number;
    perPage?: number;
}

export interface ListShiftsRequest {
    limit?: number;
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
}

export interface PublishShiftsRequest {
    publishShiftsData?: PublishShiftsData;
}

export interface ShowShiftRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateShiftRequest {
    id: number;
    _with?: Array<string>;
    updateShiftData?: UpdateShiftData;
}

/**
 * 
 */
export class ShiftApi extends runtime.BaseAPI {

    /**
     * Acknowledge Shifts
     * Acknowledge Shifts
     */
    async acknowledgeShiftsRaw(requestParameters: AcknowledgeShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/acknowledge`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AcknowledgeShiftsDataToJSON(requestParameters['acknowledgeShiftsData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Acknowledge Shifts
     * Acknowledge Shifts
     */
    async acknowledgeShifts(requestParameters: AcknowledgeShiftsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.acknowledgeShiftsRaw(requestParameters, initOverrides);
    }

    /**
     * Delete multiple shifts in one go that match certain conditions
     * Bulk Delete Shifts
     */
    async bulkDeleteShiftsRaw(requestParameters: BulkDeleteShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete multiple shifts in one go that match certain conditions
     * Bulk Delete Shifts
     */
    async bulkDeleteShifts(requestParameters: BulkDeleteShiftsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkDeleteShiftsRaw(requestParameters, initOverrides);
    }

    /**
     * Update a group of shifts based on specificed criteria.
     * Bulk Update Shifts
     */
    async bulkUpdateShiftsRaw(requestParameters: BulkUpdateShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateShiftsDataToJSON(requestParameters['bulkUpdateShiftsData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update a group of shifts based on specificed criteria.
     * Bulk Update Shifts
     */
    async bulkUpdateShifts(requestParameters: BulkUpdateShiftsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdateShiftsRaw(requestParameters, initOverrides);
    }

    /**
     * Copy a selection of shifts to another point in time
     * Copy Shifts
     */
    async copyShiftsRaw(requestParameters: CopyShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/copy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CopyShiftsDataToJSON(requestParameters['copyShiftsData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Copy a selection of shifts to another point in time
     * Copy Shifts
     */
    async copyShifts(requestParameters: CopyShiftsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.copyShiftsRaw(requestParameters, initOverrides);
    }

    /**
     * Create one or more new shifts
     * Create Shift(s)
     */
    async createShiftRaw(requestParameters: CreateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateShiftResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateShiftDataToJSON(requestParameters['createShiftData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateShiftResponseFromJSON(jsonValue));
    }

    /**
     * Create one or more new shifts
     * Create Shift(s)
     */
    async createShift(requestParameters: CreateShiftRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateShiftResponse> {
        const response = await this.createShiftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete an existing shift
     * Delete Shift
     */
    async deleteShiftRaw(requestParameters: DeleteShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteShift().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete an existing shift
     * Delete Shift
     */
    async deleteShift(requestParameters: DeleteShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteShiftRaw(requestParameters, initOverrides);
    }

    /**
     * Show all shifts that an unwanted shift could potentially be swapped with
     * List Shift Replacements
     */
    async listShiftReplacementsRaw(requestParameters: ListShiftReplacementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftReplacementsResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling listShiftReplacements().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/{id}/replacements`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftReplacementsResponseFromJSON(jsonValue));
    }

    /**
     * Show all shifts that an unwanted shift could potentially be swapped with
     * List Shift Replacements
     */
    async listShiftReplacements(requestParameters: ListShiftReplacementsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftReplacementsResponse> {
        const response = await this.listShiftReplacementsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List or filter all shifts
     * List Shifts
     */
    async listShiftsRaw(requestParameters: ListShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListShiftsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['limit'] != null) {
            queryParameters['limit'] = requestParameters['limit'];
        }

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListShiftsResponseFromJSON(jsonValue));
    }

    /**
     * List or filter all shifts
     * List Shifts
     */
    async listShifts(requestParameters: ListShiftsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListShiftsResponse> {
        const response = await this.listShiftsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Publish or schedule a publish for a group of shifts.
     * Publish Shifts
     */
    async publishShiftsRaw(requestParameters: PublishShiftsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/publish`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PublishShiftsDataToJSON(requestParameters['publishShiftsData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Publish or schedule a publish for a group of shifts.
     * Publish Shifts
     */
    async publishShifts(requestParameters: PublishShiftsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.publishShiftsRaw(requestParameters, initOverrides);
    }

    /**
     * Show details of a specific shift
     * Show Shift
     */
    async showShiftRaw(requestParameters: ShowShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowShiftResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showShift().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowShiftResponseFromJSON(jsonValue));
    }

    /**
     * Show details of a specific shift
     * Show Shift
     */
    async showShift(requestParameters: ShowShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowShiftResponse> {
        const response = await this.showShiftRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the details of an existing shift
     * Update Shift
     */
    async updateShiftRaw(requestParameters: UpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateShiftResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateShift().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.shift.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/shifts/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateShiftDataToJSON(requestParameters['updateShiftData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateShiftResponseFromJSON(jsonValue));
    }

    /**
     * Update the details of an existing shift
     * Update Shift
     */
    async updateShift(requestParameters: UpdateShiftRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateShiftResponse> {
        const response = await this.updateShiftRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
