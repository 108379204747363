// noinspection JSIgnoredPromiseFromCall

import { employeeApi } from '@/api';
import i18n from '@/i18n';
import { showInfo } from '@/plugins/ToastNotifications';
import { SocketService, WebsocketChannel } from '@/plugins/Websockets';
import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import { Permission, userHasPermission } from '@/util/permissionFunctions';
import {
  BillingSubscription,
  Employee,
  EmployeeStatusEnum,
  ListEventEntitiesResponseDataEnum,
  ListEventNamesResponseDataEnum,
} from '../../../api/v1';

/**
 * Our legacy, manual-ish handling of websocket messages for certain entities.
 * In time, this should only contain any entities to which we give special treatment, that don't fit the usual
 * pattern of lazy-loading from the API on first access, followed by only updates via the websocket messages.
 */
export const hardCodedHandlers = {
  [WebsocketChannel.BillingDetail]: (service: SocketService) => {
    service.store.dispatch('billing/setBillingOverview');
  },
  [WebsocketChannel.BillingSubscription]: (
    service: SocketService,
    entityName: ListEventEntitiesResponseDataEnum,
    eventName: ListEventNamesResponseDataEnum,
    data: BillingSubscription,
  ) => {
    if (
      !userHasPermission(Permission.ManageBilling) &&
      data.billingPlanId !== service.store.state.billing.billingPlan.id
    ) {
      service.store.dispatch('setPlanUpdated');
    }
  },
  [WebsocketChannel.Employee]: (
    service: SocketService,
    entityName: ListEventEntitiesResponseDataEnum,
    eventName: ListEventNamesResponseDataEnum,
    data: Employee,
  ) => {
    // Update main store
    const { loggedInEmployee } = service.store.getters;

    if (!eventName.endsWith('Deleted') && loggedInEmployee?.id === data.id) {
      // If the event is specifically 'Updated' and the updated status is 'Archived' then automatically
      // log the employee out. This must happen first, as archived employees no longer have access to the
      // API, so future calls will always fail.
      if (
        eventName === ListEventNamesResponseDataEnum.EmployeeUpdated &&
        data.status === EmployeeStatusEnum.Archived
      ) {
        showInfo(i18n.t('warningMessage.removedFromCompany'));
        return setTimeout(() => redirect(routes.logout.route()), 1500);
      }

      // Logged in employee object shape is different from the returned websocket data so we need to re-fetch it
      // With params are the same as store/actions/bootstrapCurrentCompany
      // @todo if loggedInEmployee object shape changes similar to employees store this can be removed
      employeeApi
        .showCurrentEmployee({
          _with: ['accessRoles.permissions', 'photo'],
        })
        .then(({ data: employee }) => {
          service.store.commit('UPDATE_LOGGED_IN_EMPLOYEE', employee);

          if (employee.accessRoleIds[0] !== loggedInEmployee.accessRoleIds[0]) {
            service.store.dispatch('setPermissionsUpdated', {
              old: loggedInEmployee.accessRoleIds[0],
              new: employee.accessRoleIds[0],
            });
          }
        });
    }
  },
  [WebsocketChannel.BatchJobMessage]: (
    service: SocketService,
    entityName: string,
    eventName: string,
    data,
  ) => {
    service.store.dispatch('jobs/handleUpdate', {
      id: data.id,
      type: data.type,
      event: data.event,
      jobCount: data.jobCount,
      remainingJobs: data.remainingJobs,
      unix: data.unix,
      extra: data.extra,
    });
  },
  [WebsocketChannel.BillingPaymentMethod]: (service: SocketService) => {
    service.store.dispatch('billing/setBillingOverview');
  },
};
