/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './Pagination';

/**
 * 
 * @export
 * @interface ListEntityScopesResponse
 */
export interface ListEntityScopesResponse {
    /**
     * 
     * @type {Array<string>}
     * @memberof ListEntityScopesResponse
     */
    data: Array<ListEntityScopesResponseDataEnum>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListEntityScopesResponse
     */
    pagination: Pagination;
}

/**
* @export
* @enum {string}
*/
export enum ListEntityScopesResponseDataEnum {
    AbsenceCategoryRead = 'api.v1.absence-category.read',
    AbsenceRead = 'api.v1.absence.read',
    AbsenceWrite = 'api.v1.absence.write',
    AccessRoleRead = 'api.v1.access-role.read',
    BatchJobsRead = 'api.v1.batch-jobs.read',
    BillingCancellationRead = 'api.v1.billing-cancellation.read',
    BillingCancellationWrite = 'api.v1.billing-cancellation.write',
    BillingCreditsRead = 'api.v1.billing-credits.read',
    BillingCreditsWrite = 'api.v1.billing-credits.write',
    BillingDetailsRead = 'api.v1.billing-details.read',
    BillingDetailsWrite = 'api.v1.billing-details.write',
    BillingDowngradeRead = 'api.v1.billing-downgrade.read',
    BillingDowngradeWrite = 'api.v1.billing-downgrade.write',
    BillingInvoiceRead = 'api.v1.billing-invoice.read',
    BillingOfferRead = 'api.v1.billing-offer.read',
    BillingOfferWrite = 'api.v1.billing-offer.write',
    BillingOverviewRead = 'api.v1.billing-overview.read',
    BillingPaymentIntentWrite = 'api.v1.billing-payment-intent.write',
    BillingPaymentMethodRead = 'api.v1.billing-payment-method.read',
    BillingPaymentMethodWrite = 'api.v1.billing-payment-method.write',
    BillingQuoteWrite = 'api.v1.billing-quote.write',
    BillingReferralsRead = 'api.v1.billing-referrals.read',
    BillingSubscriptionRead = 'api.v1.billing-subscription.read',
    BillingSubscriptionWrite = 'api.v1.billing-subscription.write',
    BudgetSummaryRead = 'api.v1.budget-summary.read',
    BulkClockingsWrite = 'api.v1.bulk-clockings.write',
    ClockInPinWrite = 'api.v1.clock-in-pin.write',
    ClockInPortalRead = 'api.v1.clock-in-portal.read',
    ClockInPortalWrite = 'api.v1.clock-in-portal.write',
    ClockedInSummaryRead = 'api.v1.clocked-in-summary.read',
    ClockingSettingsRead = 'api.v1.clocking-settings.read',
    ClockingSettingsWrite = 'api.v1.clocking-settings.write',
    ClockingsWrite = 'api.v1.clockings.write',
    CompanyFeaturesRead = 'api.v1.company-features.read',
    CompanyFeaturesWrite = 'api.v1.company-features.write',
    CompanyNotificationChannelRead = 'api.v1.company-notification-channel.read',
    CompanyNotificationChannelWrite = 'api.v1.company-notification-channel.write',
    CompanyOnboardingRead = 'api.v1.company-onboarding.read',
    CompanySettingsRead = 'api.v1.company-settings.read',
    CompanySettingsWrite = 'api.v1.company-settings.write',
    CompanyRead = 'api.v1.company.read',
    CompanyWrite = 'api.v1.company.write',
    DigiticketsBranchRead = 'api.v1.digitickets-branch.read',
    DigiticketsConnectionRead = 'api.v1.digitickets-connection.read',
    DigiticketsConnectionWrite = 'api.v1.digitickets-connection.write',
    DigiticketsEventRead = 'api.v1.digitickets-event.read',
    DigiticketsPrebookedVisitorRead = 'api.v1.digitickets-prebooked-visitor.read',
    DigiticketsScheduleMapRead = 'api.v1.digitickets-schedule-map.read',
    DigiticketsScheduleMapWrite = 'api.v1.digitickets-schedule-map.write',
    DigiticketsSessionRead = 'api.v1.digitickets-session.read',
    DocumentReadRead = 'api.v1.document-read.read',
    DocumentReadWrite = 'api.v1.document-read.write',
    DocumentRead = 'api.v1.document.read',
    DocumentWrite = 'api.v1.document.write',
    EmergencyContactRead = 'api.v1.emergency-contact.read',
    EmergencyContactWrite = 'api.v1.emergency-contact.write',
    EmployeeAbsenceSummaryRead = 'api.v1.employee-absence-summary.read',
    EmployeeAttributeRead = 'api.v1.employee-attribute.read',
    EmployeeAttributeWrite = 'api.v1.employee-attribute.write',
    EmployeeClockingProfileRead = 'api.v1.employee-clocking-profile.read',
    EmployeeGroupRead = 'api.v1.employee-group.read',
    EmployeeGroupWrite = 'api.v1.employee-group.write',
    EmployeeImportWrite = 'api.v1.employee-import.write',
    EmployeeInviteRead = 'api.v1.employee-invite.read',
    EmployeeJobRoleRead = 'api.v1.employee-job-role.read',
    EmployeeJobRoleWrite = 'api.v1.employee-job-role.write',
    EmployeeLeaveSummaryRead = 'api.v1.employee-leave-summary.read',
    EmployeeMessageStatsRead = 'api.v1.employee-message-stats.read',
    EmployeeMessageRead = 'api.v1.employee-message.read',
    EmployeeMessageWrite = 'api.v1.employee-message.write',
    EmployeeNotificationChannelRead = 'api.v1.employee-notification-channel.read',
    EmployeeNotificationChannelWrite = 'api.v1.employee-notification-channel.write',
    EmployeeOnboardingRead = 'api.v1.employee-onboarding.read',
    EmployeeOnboardingWrite = 'api.v1.employee-onboarding.write',
    EmployeePayPeriodSummaryRead = 'api.v1.employee-pay-period-summary.read',
    EmployeePayRead = 'api.v1.employee-pay.read',
    EmployeePayWrite = 'api.v1.employee-pay.write',
    EmployeePersonalDetailsRead = 'api.v1.employee-personal-details.read',
    EmployeePersonalDetailsWrite = 'api.v1.employee-personal-details.write',
    EmployeeRead = 'api.v1.employee.read',
    EmployeeWrite = 'api.v1.employee.write',
    HistoryRead = 'api.v1.history.read',
    HolidayRegionRead = 'api.v1.holiday-region.read',
    IcalFeedRead = 'api.v1.ical-feed.read',
    IcalFeedWrite = 'api.v1.ical-feed.write',
    JobRoleRead = 'api.v1.job-role.read',
    JobRoleWrite = 'api.v1.job-role.write',
    LeaveAdjustmentRead = 'api.v1.leave-adjustment.read',
    LeaveAdjustmentWrite = 'api.v1.leave-adjustment.write',
    LeavePeriodRead = 'api.v1.leave-period.read',
    LeavePeriodWrite = 'api.v1.leave-period.write',
    LeavePolicyRead = 'api.v1.leave-policy.read',
    LeavePolicyWrite = 'api.v1.leave-policy.write',
    LeaveRequestRead = 'api.v1.leave-request.read',
    LeaveRequestWrite = 'api.v1.leave-request.write',
    LeaveSettingsRead = 'api.v1.leave-settings.read',
    LeaveSettingsWrite = 'api.v1.leave-settings.write',
    LeaveTypeRead = 'api.v1.leave-type.read',
    LeaveTypeWrite = 'api.v1.leave-type.write',
    LocationRead = 'api.v1.location.read',
    LocationWrite = 'api.v1.location.write',
    MissingShiftsSummaryRead = 'api.v1.missing-shifts-summary.read',
    NotificationSettingsRead = 'api.v1.notification-settings.read',
    NotificationSettingsWrite = 'api.v1.notification-settings.write',
    NotificationRead = 'api.v1.notification.read',
    NotificationWrite = 'api.v1.notification.write',
    OpenShiftResponseRead = 'api.v1.open-shift-response.read',
    OpenShiftResponseWrite = 'api.v1.open-shift-response.write',
    PayCycleRead = 'api.v1.pay-cycle.read',
    PayCycleWrite = 'api.v1.pay-cycle.write',
    PayPeriodRead = 'api.v1.pay-period.read',
    PayPeriodWrite = 'api.v1.pay-period.write',
    PayPeriodsSummaryRead = 'api.v1.pay-periods-summary.read',
    PublicHolidayRead = 'api.v1.public-holiday.read',
    PushSubscriptionRead = 'api.v1.push-subscription.read',
    PushSubscriptionWrite = 'api.v1.push-subscription.write',
    ScheduleEventRead = 'api.v1.schedule-event.read',
    ScheduleEventWrite = 'api.v1.schedule-event.write',
    ScheduleOverviewSummaryRead = 'api.v1.schedule-overview-summary.read',
    ScheduleSettingsRead = 'api.v1.schedule-settings.read',
    ScheduleSettingsWrite = 'api.v1.schedule-settings.write',
    ScheduleTemplateRead = 'api.v1.schedule-template.read',
    ScheduleTemplateWrite = 'api.v1.schedule-template.write',
    ScheduleRead = 'api.v1.schedule.read',
    ScheduleWrite = 'api.v1.schedule.write',
    ShiftAreaSessionRead = 'api.v1.shift-area-session.read',
    ShiftAreaSessionWrite = 'api.v1.shift-area-session.write',
    ShiftCostReportRead = 'api.v1.shift-cost-report.read',
    ShiftSwapRead = 'api.v1.shift-swap.read',
    ShiftSwapWrite = 'api.v1.shift-swap.write',
    ShiftTemplateRead = 'api.v1.shift-template.read',
    ShiftTemplateWrite = 'api.v1.shift-template.write',
    ShiftTotalsSummaryRead = 'api.v1.shift-totals-summary.read',
    ShiftRead = 'api.v1.shift.read',
    ShiftWrite = 'api.v1.shift.write',
    TagRead = 'api.v1.tag.read',
    TagWrite = 'api.v1.tag.write',
    TimesheetEntrySummaryRead = 'api.v1.timesheet-entry-summary.read',
    TimesheetEntryRead = 'api.v1.timesheet-entry.read',
    TimesheetEntryWrite = 'api.v1.timesheet-entry.write',
    TrekksoftCredentialsRead = 'api.v1.trekksoft-credentials.read',
    TrekksoftCredentialsWrite = 'api.v1.trekksoft-credentials.write',
    TrekksoftPrebookedVisitorsRead = 'api.v1.trekksoft-prebooked-visitors.read',
    UnavailabilityRead = 'api.v1.unavailability.read',
    UnavailabilityWrite = 'api.v1.unavailability.write',
    UploadRead = 'api.v1.upload.read',
    UploadWrite = 'api.v1.upload.write',
    UserRead = 'api.v1.user.read',
    WebhookSubscriptionRead = 'api.v1.webhook-subscription.read',
    WebhookSubscriptionWrite = 'api.v1.webhook-subscription.write',
    WorkPatternRead = 'api.v1.work-pattern.read',
    WorkPatternWrite = 'api.v1.work-pattern.write'
}


/**
 * Check if a given object implements the ListEntityScopesResponse interface.
 */
export function instanceOfListEntityScopesResponse(value: object): boolean {
    if (!('data' in value)) return false;
    if (!('pagination' in value)) return false;
    return true;
}

export function ListEntityScopesResponseFromJSON(json: any): ListEntityScopesResponse {
    return ListEntityScopesResponseFromJSONTyped(json, false);
}

export function ListEntityScopesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListEntityScopesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': json['data'],
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

export function ListEntityScopesResponseToJSON(value?: ListEntityScopesResponse | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'data': value['data'],
        'pagination': PaginationToJSON(value['pagination']),
    };
}

