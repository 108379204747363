/**
 * CLONE OF: src/lib/realtime/entityConfig.ts
 *
 * DIFF:
 *   * entityConfig now is merged with batchedEntityConfig
 */
import {
  absenceApi,
  accessRoleApi,
  billingApi,
  calendarApi,
  clockInPortalApi,
  companyApi,
  companySettingsApi,
  documentApi,
  emergencyContactApi,
  employeeApi,
  employeeAttributesApi,
  employeeGroupApi,
  employeeInviteApi,
  employeeJobRoleApi,
  employeeMessageApi,
  fetchAll,
  historyApi,
  integrationApi,
  jobRoleApi,
  leaveAdjustmentApi,
  leavePeriodApi,
  leavePolicyApi,
  leaveRequestApi,
  leaveTypeApi,
  locationApi,
  openShiftResponseApi,
  payCycleApi,
  payPeriodApi,
  publicHolidayApi,
  scheduleApi,
  scheduleEventApi,
  scheduleTemplateApi,
  settingsApi,
  shiftApi,
  shiftSwapApi,
  shiftTemplateApi,
  summariesApi,
  tagsApi,
  timesheetApi,
  unavailabilityApi,
  uploadApi,
  userApi,
  workPatternApi,
} from '@/api';
import { Where } from '@/lib/realtime/weak/realtimeFiltering';
import {
  BatchFetcher,
  BatchFetcherBuilder,
  CustomEntity,
  EntityConfig,
  EntityInstance,
  EntityName,
  Fetcher,
  FetcherBuilder,
  GetParameterType,
  ListFunction,
  OrderBy,
  ShowFunction,
  SingleEntityFetcherBuilder,
  WSEntityName,
} from '@/lib/realtime/weak/realtimeTypes';
import {
  AbsenceCategoryFromJSON,
  AbsenceFromJSON,
  AccessRoleFromJSON,
  BaseAPI,
  BillingPlanFromJSON,
  BudgetSummaryFromJSON,
  ClockInPortalFromJSON,
  CompanyFromJSON,
  CompanyNotificationChannelFromJSON,
  CompanySettingFromJSON,
  DaySummaryFromJSON,
  DigiticketsConnectionFromJSON,
  DigiticketsScheduleMapFromJSON,
  DocumentFromJSON,
  DocumentReadFromJSON,
  EmergencyContactFromJSON,
  EmployeeAbsenceSummaryFromJSON,
  EmployeeAttributeFromJSON,
  EmployeeClockingProfileFromJSON,
  EmployeeFromJSON,
  EmployeeGroupFromJSON,
  EmployeeInviteFromJSON,
  EmployeeJobRoleFromJSON,
  EmployeeLeaveSummaryFromJSON,
  EmployeeMessageFromJSON,
  EmployeeMessageRecipientFromJSON,
  EmployeeNotificationChannelFromJSON,
  EmployeePayFromJSON,
  EmployeePayPeriodSummaryFromJSON,
  EmployeePersonalDetailsFromJSON,
  FeaturesEnabledFromJSON,
  HistoryFromJSON,
  IcalFeedFromJSON,
  JobRoleFromJSON,
  LeaveAdjustmentFromJSON,
  LeavePeriodFromJSON,
  LeavePolicyFromJSON,
  LeaveRequestFromJSON,
  LeaveTypeFromJSON,
  ListEventEntitiesResponseDataEnum,
  LocationFromJSON,
  NotificationSettingsFromJSON,
  OfferFromJSON,
  OffersBehaviourFromJSON,
  OffersQuotaFromJSON,
  OpenShiftResponseFromJSON,
  PayCycleFromJSON,
  PayPeriodFromJSON,
  PayPeriodsSummaryFromJSON,
  PublicHolidayFromJSON,
  ScheduleEventFromJSON,
  ScheduleFromJSON,
  ScheduleOverviewSummaryFromJSON,
  ScheduleTemplateFromJSON,
  ShiftFromJSON,
  ShiftSwapFromJSON,
  ShiftTemplateFromJSON,
  TagFromJSON,
  TimesheetEntryFromJSON,
  TimesheetEntrySummaryFromJSON,
  TrekksoftClientCredentialFromJSON,
  UnavailabilityFromJSON,
  UploadFromJSON,
  UserFromJSON,
  WorkPatternFromJSON,
} from '../../../../api/v1';

const Entity = ListEventEntitiesResponseDataEnum;
const genericFetcher: FetcherBuilder =
  (
    api: BaseAPI,
    listFunction: ListFunction,
    options: {
      _with?: string[];
      perPage?: number;
    } = {},
  ): Fetcher =>
  async <T extends EntityName>(
    params: GetParameterType<T>,
    where: Where<T>,
    orderBy: OrderBy<EntityInstance<T>> = [],
  ) =>
    (
      await fetchAll(listFunction.bind(api), {
        where,
        ...(orderBy.length && { orderBy }),
        ...(options._with && { _with: options._with }),
        perPage: options.perPage ?? 250,
        ...params,
      })
    ).data as EntityInstance<T>[];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const singleEntityFetcher: SingleEntityFetcherBuilder =
  (api: BaseAPI, showFunction: ShowFunction): Fetcher =>
  async <T extends EntityName>(params: GetParameterType<T>) =>
    [(await showFunction.bind(api)({ ...params })).data] as EntityInstance<T>[];

const genericBatchedFetcher: BatchFetcherBuilder =
  (api: BaseAPI, listFunction: ListFunction): BatchFetcher =>
  async <T extends WSEntityName>(ids: EntityInstance<T>['id'][]) =>
    (
      await fetchAll(listFunction.bind(api), {
        where: {
          id: { in: ids },
        },
        perPage: 500,
      })
    ).data as EntityInstance<T>[];

/**
 * This is where we configure how to fetch each entity type from the API.
 *
 * IMPORTANT: When adding to this, you'll also need to update the type mapping in EntityInterfaceMap.
 */
export const entityConfig: EntityConfig = {
  [Entity.Absence]: {
    fetcher: genericFetcher(absenceApi, absenceApi.listAbsence),
    entityMaker: AbsenceFromJSON,
    orderBy: ['startedOn'],
  },
  [Entity.AbsenceCategory]: {
    fetcher: genericFetcher(absenceApi, absenceApi.listAbsenceCategories),
    entityMaker: AbsenceCategoryFromJSON,
    orderBy: ['name'],
  },
  [Entity.AccessRole]: {
    fetcher: genericFetcher(accessRoleApi, accessRoleApi.listAccessRoles, {
      _with: ['permissions'],
    }),
    entityMaker: AccessRoleFromJSON,
    orderBy: ['name'],
  },
  [Entity.BillingPlan]: {
    fetcher: genericFetcher(billingApi, billingApi.listBillingPlans),
    entityMaker: BillingPlanFromJSON,
    orderBy: ['name'],
  },
  [Entity.BudgetSummary]: {
    fetcher: singleEntityFetcher(summariesApi, summariesApi.showBudgetSummary),
    entityMaker: BudgetSummaryFromJSON,
  },
  [Entity.ClockInPortal]: {
    fetcher: genericFetcher(
      clockInPortalApi,
      clockInPortalApi.listClockInPortals,
    ),
    entityMaker: ClockInPortalFromJSON,
  },
  [Entity.Company]: {
    fetcher: genericFetcher(
      companyApi,
      companyApi.listCompaniesBelongingToUser,
    ),
    entityMaker: CompanyFromJSON,
    orderBy: ['name'],
  },
  [Entity.CompanyNotificationChannel]: {
    fetcher: genericFetcher(
      companyApi,
      companyApi.listCompanyNotificationChannels,
    ),
    entityMaker: CompanyNotificationChannelFromJSON,
  },
  [Entity.DaySummary]: {
    fetcher: singleEntityFetcher(summariesApi, summariesApi.showDaySummary),
    entityMaker: DaySummaryFromJSON,
  },
  [Entity.DigiticketsConnection]: {
    fetcher: genericFetcher(
      integrationApi(),
      integrationApi().listDigiticketsConnections,
    ),
    entityMaker: DigiticketsConnectionFromJSON,
  },
  [Entity.DigiticketsScheduleMap]: {
    fetcher: genericFetcher(
      integrationApi(),
      integrationApi().listDigiticketsScheduleMaps,
    ),
    entityMaker: DigiticketsScheduleMapFromJSON,
  },
  [Entity.Document]: {
    fetcher: genericFetcher(documentApi, documentApi.listDocuments),
    entityMaker: DocumentFromJSON,
  },
  [Entity.DocumentRead]: {
    fetcher: genericFetcher(documentApi, documentApi.listDocumentReads),
    entityMaker: DocumentReadFromJSON,
    orderBy: ['createdAt'],
  },
  [Entity.EmergencyContact]: {
    fetcher: genericFetcher(
      emergencyContactApi,
      emergencyContactApi.listEmergencyContacts,
    ),
    entityMaker: EmergencyContactFromJSON,
    orderBy: ['name'],
  },
  [Entity.Employee]: {
    fetcher: genericFetcher(employeeApi, employeeApi.listEmployees, {
      _with: ['photo'],
    }),
    entityMaker: EmployeeFromJSON,
    batchFetcher: genericBatchedFetcher(employeeApi, employeeApi.listEmployees),
    orderBy: ['firstName', 'lastName'],
  },
  [Entity.EmployeeAbsenceSummary]: {
    fetcher: genericFetcher(
      summariesApi,
      summariesApi.listEmployeeAbsenceSummaries,
    ),
    entityMaker: EmployeeAbsenceSummaryFromJSON,
  },
  [Entity.EmployeeClockingProfile]: {
    fetcher: genericFetcher(
      employeeApi,
      employeeApi.listEmployeeClockingProfiles,
    ),
    entityMaker: EmployeeClockingProfileFromJSON,
  },
  [Entity.EmployeeInvite]: {
    fetcher: genericFetcher(
      employeeInviteApi,
      employeeInviteApi.listEmployeeInvites,
    ),
    entityMaker: EmployeeInviteFromJSON,
    orderBy: ['-createdAt'],
  },
  [Entity.EmployeeJobRole]: {
    fetcher: genericFetcher(
      employeeJobRoleApi,
      employeeJobRoleApi.listEmployeeJobRoles,
      {
        _with: ['jobRole'],
      },
    ),
    entityMaker: EmployeeJobRoleFromJSON,
  },
  [Entity.EmployeeLeaveSummary]: {
    fetcher: genericFetcher(
      summariesApi,
      summariesApi.listEmployeeLeaveSummaries,
    ),
    entityMaker: EmployeeLeaveSummaryFromJSON,
    triggers: [
      Entity.LeaveRequest,
      Entity.Employee,
      Entity.LeaveAdjustment,
      Entity.LeavePeriod,
    ],
    useWebsocket: false,
  },
  [Entity.EmployeeMessage]: {
    fetcher: genericFetcher(
      employeeMessageApi,
      employeeMessageApi.listEmployeeMessages,
    ),
    entityMaker: EmployeeMessageFromJSON,
    orderBy: ['sentAt'],
  },
  [Entity.EmployeeMessageRecipient]: {
    fetcher: genericFetcher(
      employeeMessageApi,
      employeeMessageApi.listEmployeeMessageRecipients,
    ),
    entityMaker: EmployeeMessageRecipientFromJSON,
  },
  [Entity.EmployeePay]: {
    fetcher: genericFetcher(employeeApi, employeeApi.listEmployeePay),
    entityMaker: EmployeePayFromJSON,
  },
  [Entity.EmployeePersonalDetail]: {
    fetcher: genericFetcher(
      employeeApi,
      employeeApi.listEmployeePersonalDetails,
    ),
    entityMaker: EmployeePersonalDetailsFromJSON,
  },
  [Entity.EmployeeAttribute]: {
    fetcher: genericFetcher(
      employeeAttributesApi,
      employeeAttributesApi.listEmployeeAttributes,
    ),
    entityMaker: EmployeeAttributeFromJSON,
    orderBy: ['name'],
  },
  [Entity.EmployeeGroup]: {
    fetcher: genericFetcher(
      employeeGroupApi,
      employeeGroupApi.listEmployeeGroups,
    ),
    entityMaker: EmployeeGroupFromJSON,
  },
  [Entity.EmployeeNotificationChannel]: {
    fetcher: genericFetcher(
      employeeApi,
      employeeApi.listEmployeeNotificationChannels,
    ),
    entityMaker: EmployeeNotificationChannelFromJSON,
  },
  [Entity.IcalFeed]: {
    fetcher: genericFetcher(calendarApi, calendarApi.listCalendarFeeds),
    entityMaker: IcalFeedFromJSON,
  },
  [Entity.JobRole]: {
    fetcher: genericFetcher(jobRoleApi, jobRoleApi.listJobRoles),
    entityMaker: JobRoleFromJSON,
    orderBy: ['name'],
  },
  [Entity.OpenShiftResponse]: {
    fetcher: genericFetcher(
      openShiftResponseApi,
      openShiftResponseApi.listOpenShiftResponses,
    ),
    entityMaker: OpenShiftResponseFromJSON,
    orderBy: ['createdAt'],
  },
  [Entity.LeaveAdjustment]: {
    fetcher: genericFetcher(
      leaveAdjustmentApi,
      leaveAdjustmentApi.listLeaveAdjustments,
    ),
    entityMaker: LeaveAdjustmentFromJSON,
    orderBy: ['createdAt'],
  },
  [Entity.LeavePeriod]: {
    fetcher: genericFetcher(leavePeriodApi, leavePeriodApi.listLeavePeriods),
    entityMaker: LeavePeriodFromJSON,
    // Auto relations need to trigger a refetch when changed
    triggers: [Entity.LeavePeriodType],
  },
  [Entity.LeavePolicy]: {
    fetcher: genericFetcher(leavePolicyApi, leavePolicyApi.listLeavePolicies),
    entityMaker: LeavePolicyFromJSON,
    orderBy: ['name'],
  },
  [Entity.LeaveRequest]: {
    fetcher: genericFetcher(leaveRequestApi, leaveRequestApi.listLeaveRequests),
    batchFetcher: genericBatchedFetcher(
      leaveRequestApi,
      leaveRequestApi.listLeaveRequests,
    ),
    entityMaker: LeaveRequestFromJSON,
    orderBy: ['startDate'],
  },
  [Entity.LeaveType]: {
    fetcher: genericFetcher(leaveTypeApi, leaveTypeApi.listLeaveTypes),
    entityMaker: LeaveTypeFromJSON,
    orderBy: ['name'],
  },
  [Entity.Location]: {
    fetcher: genericFetcher(locationApi, locationApi.listLocations),
    entityMaker: LocationFromJSON,
    orderBy: ['name'],
  },
  [Entity.Offer]: {
    fetcher: genericFetcher(billingApi, billingApi.listOffers),
    entityMaker: OfferFromJSON,
  },
  [Entity.OffersBehaviour]: {
    fetcher: genericFetcher(billingApi, billingApi.listOffersBehaviours),
    entityMaker: OffersBehaviourFromJSON,
  },
  [Entity.OffersQuota]: {
    fetcher: genericFetcher(billingApi, billingApi.listOffersQuota),
    entityMaker: OffersQuotaFromJSON,
  },
  [Entity.PayCycle]: {
    fetcher: genericFetcher(payCycleApi, payCycleApi.listPayCycles),
    entityMaker: PayCycleFromJSON,
    orderBy: ['startsOn'],
  },
  [Entity.PayPeriod]: {
    fetcher: genericFetcher(payPeriodApi, payPeriodApi.listPayPeriods),
    entityMaker: PayPeriodFromJSON,
    orderBy: ['-startsOn'],
  },
  [Entity.PublicHoliday]: {
    fetcher: genericFetcher(
      publicHolidayApi,
      publicHolidayApi.listPublicHolidays,
    ),
    entityMaker: PublicHolidayFromJSON,
    orderBy: ['date'],
  },
  [Entity.Schedule]: {
    fetcher: genericFetcher(scheduleApi, scheduleApi.listSchedules),
    entityMaker: ScheduleFromJSON,
    orderBy: ['name'],
  },
  [Entity.ScheduleEvent]: {
    fetcher: genericFetcher(
      scheduleEventApi,
      scheduleEventApi.listScheduleEvents,
    ),
    entityMaker: ScheduleEventFromJSON,
    orderBy: ['dateStart'],
  },
  [Entity.ScheduleTemplate]: {
    fetcher: genericFetcher(
      scheduleTemplateApi,
      scheduleTemplateApi.listScheduleTemplates,
    ),
    entityMaker: ScheduleTemplateFromJSON,
  },
  [Entity.Shift]: {
    fetcher: genericFetcher(shiftApi, shiftApi.listShifts),
    entityMaker: ShiftFromJSON,
    batchFetcher: genericBatchedFetcher(shiftApi, shiftApi.listShifts),
    orderBy: ['startsAt'],
  },
  [Entity.ShiftSwap]: {
    fetcher: genericFetcher(shiftSwapApi, shiftSwapApi.listShiftSwaps),
    entityMaker: ShiftSwapFromJSON,
  },
  [Entity.ShiftTemplate]: {
    fetcher: genericFetcher(
      shiftTemplateApi,
      shiftTemplateApi.listShiftTemplates,
    ),
    entityMaker: ShiftTemplateFromJSON,
    orderBy: ['startHour', 'startMinute'],
  },
  [Entity.Tag]: {
    fetcher: genericFetcher(tagsApi, tagsApi.listTags),
    entityMaker: TagFromJSON,
    orderBy: ['name'],
  },
  [Entity.TimesheetEntry]: {
    fetcher: genericFetcher(timesheetApi, timesheetApi.listTimesheetEntries),
    entityMaker: TimesheetEntryFromJSON,
    batchFetcher: genericBatchedFetcher(
      timesheetApi,
      timesheetApi.listTimesheetEntries,
    ),
    orderBy: ['startedAt'],
  },
  [Entity.TimesheetEntrySummary]: {
    fetcher: genericFetcher(summariesApi, summariesApi.listTimesheetSummaries, {
      perPage: 50,
    }),
    entityMaker: TimesheetEntrySummaryFromJSON,
    triggers: [
      Entity.TimesheetEntry,
      Entity.Shift,
      Entity.PayPeriod,
      Entity.Employee,
    ],
    useWebsocket: false,
  },
  [Entity.TrekksoftClientCredential]: {
    fetcher: genericFetcher(
      integrationApi(),
      integrationApi().listTrekksoftClientCredentials,
    ),
    entityMaker: TrekksoftClientCredentialFromJSON,
  },
  [Entity.Unavailability]: {
    fetcher: genericFetcher(
      unavailabilityApi,
      unavailabilityApi.listUnavailability,
    ),
    entityMaker: UnavailabilityFromJSON,
    orderBy: ['startsAt'],
  },
  [Entity.Upload]: {
    fetcher: genericFetcher(uploadApi, uploadApi.listUploads),
    entityMaker: UploadFromJSON,
  },
  [Entity.User]: {
    fetcher: genericFetcher(userApi, userApi.listCompanyUsers),
    entityMaker: UserFromJSON,
  },
  [Entity.WorkPattern]: {
    fetcher: genericFetcher(workPatternApi, workPatternApi.listWorkPatterns),
    entityMaker: WorkPatternFromJSON,
    orderBy: ['name'],
  },
  [Entity.ScheduleOverviewSummary]: {
    fetcher: singleEntityFetcher(
      summariesApi,
      summariesApi.showScheduleOverview,
    ),
    entityMaker: ScheduleOverviewSummaryFromJSON,
  },
  [CustomEntity.FeaturesEnabled]: {
    fetcher: singleEntityFetcher(companyApi, companyApi.showEnabledFeatures),
    entityMaker: FeaturesEnabledFromJSON,
    useWebsocket: false,
  },
  [Entity.NotificationSetting]: {
    fetcher: singleEntityFetcher(
      settingsApi,
      settingsApi.showNotificationSettings,
    ),
    entityMaker: NotificationSettingsFromJSON,
  },
  [Entity.CompanySetting]: {
    fetcher: singleEntityFetcher(
      companySettingsApi,
      // TODO: remove assignment on API fix
      companySettingsApi.showCompanySettings as ShowFunction,
    ),
    entityMaker: CompanySettingFromJSON,
  },
  [Entity.History]: {
    fetcher: genericFetcher(historyApi, historyApi.listHistory),
    entityMaker: HistoryFromJSON,
    orderBy: ['changedAt', 'id'],
  },
  [CustomEntity.PayPeriodsSummary]: {
    fetcher: singleEntityFetcher(
      summariesApi,
      summariesApi.showPayPeriodsSummary,
    ),
    entityMaker: PayPeriodsSummaryFromJSON,
    triggers: [Entity.TimesheetEntry, Entity.PayPeriod],
    useWebsocket: false,
  },
  [CustomEntity.EmployeePayPeriodSummary]: {
    fetcher: genericFetcher(
      summariesApi,
      summariesApi.listEmployeePayPeriodSummaries,
    ),
    entityMaker: EmployeePayPeriodSummaryFromJSON,
    triggers: [Entity.TimesheetEntry, Entity.PayPeriod],
    useWebsocket: false,
  },
};
