import { employeeCurrent } from '@/lib/employee/employeeFunctions';
import {
  BillingPlan,
  BillingPlanFeaturesEnum,
  BillingPlanIntervalEnum,
  BillingSubscription,
  BillingSubscriptionTypeEnum,
  Employee,
} from '../../../api/v1';
import {
  PlanChangeIntervalEnum,
  PlanChangeLevelEnum,
  PlanNameEnum,
} from '../enum/billing/BillingEnum';

export type PlanChangeLevel =
  typeof PlanChangeLevelEnum[keyof typeof PlanChangeLevelEnum];
export type PlanChangeInterval =
  typeof PlanChangeIntervalEnum[keyof typeof PlanChangeIntervalEnum];

export const countEmployees = (employees: Employee[]) =>
  employees.filter(employeeCurrent).length;

export const employeeSoftLimitReached = (
  employees: Employee[],
  { employeeSoftLimit, type }: BillingSubscription,
): boolean =>
  countEmployees(employees) >= employeeSoftLimit &&
  type !== BillingSubscriptionTypeEnum.Onboarding;

export const employeeHardLimitReached = (
  employees: Employee[],
  { employeeHardLimit }: BillingSubscription,
): boolean => countEmployees(employees) >= employeeHardLimit;

export const allowedAdditionalEmployees = (
  employees: Employee[],
  billingSubscription: BillingSubscription,
): boolean =>
  billingSubscription.employeeSoftLimit <
    billingSubscription.employeeHardLimit &&
  !employeeHardLimitReached(employees, billingSubscription);

export const isAdditionalEmployee = (
  employees: Employee[],
  { employeeSoftLimit }: BillingSubscription,
): boolean => employees.filter(employeeCurrent).length >= employeeSoftLimit;

// Return the level of plan change from PlanChangeLevelEnum
export const checkPlanChangeLevel = (
  currentPlan: string,
  chosenPlan: string,
): PlanChangeLevel => {
  // Takes current and chosen plan names from BillingPlanNameEnum
  if (
    ![currentPlan, chosenPlan].every((p) =>
      Object.values<string>(PlanNameEnum).includes(p),
    )
  ) {
    throw new Error(
      'checkPlanChangeLevel parameters should be names from BillingPlanNameEnum',
    );
  }

  // Certain criteria for each level
  const checkCrit = {
    downgrade: currentPlan !== PlanNameEnum.StartupShiftie,
    upgrade: currentPlan !== PlanNameEnum.VeryShiftie,
    likeForLike: currentPlan === chosenPlan,
  };

  // If it's not a likeForLike and could be a downgrade, ensure it is
  // If it's not a downgrade it must be an upgrade
  if (!checkCrit.likeForLike && checkCrit.downgrade) {
    checkCrit.downgrade =
      (currentPlan === PlanNameEnum.VeryShiftie &&
        chosenPlan !== PlanNameEnum.VeryShiftie) ||
      (currentPlan === PlanNameEnum.ALittleBitShiftie &&
        chosenPlan === PlanNameEnum.StartupShiftie);
  }

  if (checkCrit.likeForLike) return PlanChangeLevelEnum.LikeForLike;
  if (checkCrit.downgrade) return PlanChangeLevelEnum.Downgrade;
  if (checkCrit.upgrade) return PlanChangeLevelEnum.Upgrade;
  throw new Error('Could not find plan change level');
};

// Return the interval of plan change from PlanChangeIntervalEnum
export const checkPlanChangeInterval = (
  currentPlan: string,
  chosenPlan: string,
): PlanChangeInterval => {
  // Takes current and chosen plan intervals from BillingPlanIntervalEnum
  if (
    ![currentPlan, chosenPlan].every((p) =>
      Object.values<string>(BillingPlanIntervalEnum).includes(p),
    )
  ) {
    throw new Error(
      'checkPlanChangeInterval parameters should be intervals from BillingPlanIntervalEnum',
    );
  }

  if (chosenPlan === currentPlan) {
    return chosenPlan === BillingPlanIntervalEnum.Annual
      ? PlanChangeIntervalEnum.AnnuallyToAnnually
      : PlanChangeIntervalEnum.MonthlyToMonthly;
  }
  return currentPlan === BillingPlanIntervalEnum.Annual
    ? PlanChangeIntervalEnum.AnnuallyToMonthly
    : PlanChangeIntervalEnum.MonthlyToAnnually;
};

export const planHasFeature = (
  plan: BillingPlan,
  feature: BillingPlanFeaturesEnum,
): boolean => plan.features.includes(feature);
